<template>
    <span
        v-if="status.toUpperCase() === 'PENDING' || status.toUpperCase() === 'IN REVIEW'"
        class="badge badge-primary"
        data-toggle="tooltip" data-placement="right"
        title='Claim is created and being assessed'
        >In Assessment</span
    >
    <span
        v-if="status.toUpperCase() === 'APPROVED'"
        class="badge badge-primary"
        data-toggle="tooltip" data-placement="right"
        title='Claim is approved and waiting payout'
        >Approved</span
    >
    <span
        v-if="status.toUpperCase() === 'DENIED'"
        class="badge badge-primary"
        data-toggle="tooltip" data-placement="right"
        title='Claim has been denied'
        >Denied</span
    >
    <span
        v-if="status.toUpperCase() === 'WITHDRAWN'"
        class="badge badge-primary"
        data-toggle="tooltip" data-placement="right"
        title='Claim has been withdrawn'
        >Withdrawn</span
    >
    <span
        v-if="status.toUpperCase() === 'ERROR'"
        class="badge badge-primary"
        data-toggle="tooltip" data-placement="right"
        title='Claim has an issue'
        >Issue with claim</span
    >
    <span
        v-if="status.toUpperCase() === 'PAID'"
        class="badge badge-success"
        data-toggle="tooltip" data-placement="right"
        title='Claim has been paid'
        >Paid</span
    >
</template>

<script>
export default {
  name: 'ClaimStatusBadges',
  props: {
    status: String
  }
}
</script>
