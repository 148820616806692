/* eslint-disable camelcase */
import { http, httpPartner } from '../http-common'

class AuthDataService {
  /*
    AuthDataService is in charge of doing the API request to ISS for authentication
  */

  login (email, password) {
    return http.post('/login/', { email, password })
  }

  logout () {
    return http.get('/logout/')
  }

  // TODO: REMOVE THIS WHEN MOVING TO AWS. SIGN IS NOT NEEDED
  getAuthStatus (sign) {
    return http.get('/auth_status/', { params: { sign } })
  }

  getAvailablePartners () {
    return http.get('/available_partners/')
  }

  validatePartnerCode (code) {
    return httpPartner.post('/validate_partner_code/', { code })
  }

  signup (email, password, partner) {
    return http.post('/signup/', { email, password, partner })
  }

  recoverPassword (email) {
    return http.post('/send_recover_password_email/', { email })
  }

  resetPassword (password, repeated_password, token) {
    return http.post('/reset_password/' + token + '/', { password, repeated_password })
  }
}

export default new AuthDataService()
