<script setup>
const faqsURL = process.env.VUE_APP_FAQS_URL
</script>

<template>
  <div>
    <a :href="faqsURL" class="text-center" target="_blank">
      <u>
        Do you have any question? Visit our FAQs
      </u>
    </a>
  </div>
</template>

<style scoped>
a.text-center {
  font-size: 12px;
}
</style>
