/* eslint-disable camelcase */
import { http } from '../http-common'

class InvoiceDetailDataService {
  /*
    InvoiceDetailDataService is in charge of doing the API request to ISS for the Invoice Detail view
  */

  // Returns invoice details
  getInvoiceDetail (id) {
    return http.get('/invoice_detail/' + id + '/', {
    })
  }
}

export default new InvoiceDetailDataService()
