<script setup>
import { usePolicyholderStore } from '@/stores/policyholder'

const policyholderStore = usePolicyholderStore()

</script>
<template>
  <p class="tw-text-xs tw-m-0">
    <span class="tw-font-bold">Please Note:</span> By clicking <span class="tw-font-bold">Complete</span> you agree that you have read and understood the terms of the <a :href="policyholderStore.contract.wording_url" target="_blank" class="tw-anansi-gold">Policy Terms and Conditions</a> and <a :href="policyholderStore.contract.ipid_url" target="_blank" class="tw-anansi-gold">the Insurance Product Information Document</a>
  </p>
</template>
