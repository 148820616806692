<template>
  <ErrorHandling><router-view/></ErrorHandling>
</template>

<script>
import ErrorHandling from './components/ErrorHandling.vue'
import { useMainStore } from '@/stores'

let isAuthenticated

export default {
  async beforeCreate () {
    const store = useMainStore()
    store.initializeStore()
    isAuthenticated = store.isAuthenticated
  },

  name: 'App',
  components: {
    ErrorHandling
    // Dashboard, LogIn
  },
  data () {
    return {
      isAuthenticated
    }
  }
}
</script>

<style lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
