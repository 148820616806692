<script setup>
import { ref } from 'vue'
import TheModal from '@/components/commons/TheModal.vue'
import DashboardDataService from '@/services/DashboardDataService.js'

const props = defineProps(
  {
    show: Boolean
  }
)

const { show } = props

const emailResendAllowed = ref(true)

async function resendActivationLink () {
  return DashboardDataService.resendActivationLink().then(() => {
    emailResendAllowed.value = false
  })
}
</script>

<template>
  <TheModal :show="show" size='email-verification-modal' header_position='center' data-test='email-verification-modal' class="modal-custom" body_position="email-verification-modal">
    <template #header></template>
    <template #body>
      <p class="modal-title">
        You need to confirm your account before you can insure parcels
      </p>
      <p class="modal-subtitle">
        <span v-if="emailResendAllowed">We’ve sent you a confirmation email. If you need us to resend it, please <span @click="resendActivationLink" class="click-here-link">click here</span></span>
        <span v-else > We've just send you an email, please check your inbox.</span>
      </p>
    </template>
    <template #footer>
      <span @click="$emit('close')">
        <img src="@/assets/img/icons/close-button.svg" alt="close" class="close-button">
      </span>
    </template>
  </TheModal>
</template>

<style scoped>
  .modal-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  .modal-subtitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .click-here-link {
    color: #B18D3F;
    cursor: pointer;
  }
  .close-button {
    cursor: pointer;
  }
</style>
