<script>
import { deletePersistentCookie, getCookie } from '@/utils/cookies.js'
import Auth from '@/services/auth.js'
import { useMainStore } from '@/stores'
import { usePolicyholderStore } from '@/stores/policyholder'
import { useOnboardingStore } from '@/stores/onboarding'

export default {
  name: 'LogOut',
  created () {
    const store = useMainStore()
    const policyholderStore = usePolicyholderStore()
    const onboardingStore = useOnboardingStore()

    Auth.logout().then(r => {
      store.resetLocalStorage()
      policyholderStore.setPolicyholderFromData()
      onboardingStore.resetOnboardingStoreData()
      deletePersistentCookie('csrftoken')
      // TODO: REMOVE THIS WHEN MOVING TO AWS
      deletePersistentCookie('access_token')
      let name = 'SignUp'
      if (store.refresh_jwt_error) {
        name = 'LogIn'
        store.setRefreshJwtError(false)
      }
      const source = getCookie('user_source')
      if (['ekm'].includes(source)) {
        this.$router.push({ path: `/ReferralAuth/${source}` })
      } else {
        this.$router.push({ name })
      }
    }).catch(e => {
      console.log(e)
      alert('Error on logout')
      this.$router.push({ path: '/' })
    })
  }
}
</script>
