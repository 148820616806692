<script setup>
import { ref } from 'vue'
import DashboardDataService from '@/services/DashboardDataService.js'

const newEmailSent = ref(false)

async function resendEmailVerification () {
  try {
    await DashboardDataService.resendActivationLink()
    newEmailSent.value = true
  } catch (e) {
    console.log('Error while resending Activation Link', e)
  }
}
</script>

<template>
  <div class="wrapper">
    <p>Just a few quick steps left before you can begin insuring parcels.</p>
    <p class="main-copy">
      Please verify your account by clicking the link in the confirmation email we've sent.
    </p>
    <span v-if="!newEmailSent">
      <p class="mb-0">
        Need it resent?
      </p>
      <a @click="resendEmailVerification" class="link-new-email">Click here</a>
      <span>, and in the meantime, feel free to explore Anansi while we finalise your account with your affiliate
        partner.</span>
    </span>
    <span v-else>
      We've sent you a new confirmation email.
    </span>
  </div>
</template>

<style scoped>
.wrapper {
  margin: 40px 0;
}
p.main-copy {
  text-decoration: underline;
  font-weight: bold;
}
a.link-new-email {
  text-decoration: underline;
  cursor: pointer;
  color: var(--gold-anansi);
}
</style>
