<template>
  <form :action="socialLoginEndpoint" method="post" ref="form" @submit.prevent="submit">
    <input type="hidden" name="social_app" value="ekm">
    <button id="login-with-shop-submit-btn" class="tw-w-full tw-text-sm tw-text-white tw-p-3 tw-rounded btn-ekm-signup-login tw-flex tw-items-center tw-justify-center tw-gap-2">
      <img src="@/assets/img/ekm/ekm-logo-SVG.svg" class="tw-w-4" />
      {{label}}
    </button>
  </form>
</template>
<script>
import settings from '@/settings/settings'
import { getCookie, setPersistentCookie } from '@/utils/cookies.js'

export default {
  name: 'AuthEKM',
  props: ['label'],
  data () {
    return {
      socialLoginEndpoint: settings.baseURL + 'social_login/'
    }
  },
  methods: {
    async submit (e) {
      setPersistentCookie('user_source', 'ekm')
      this.csrftoken = getCookie('csrftoken')
      try {
        this.$nextTick(() => e.target.submit())
      } catch (error) {
        this.$router.push({ name: 'SignUp' })
      }
    }
  }
}
</script>
