export default {
  data () {
    return {
      popupPollTimer: undefined
    }
  },
  methods: {
    openPaymentWindow (url, windowName, popupCloseHandler, w = 483, h = 589) {
      const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
      const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
      const stripePopup = window.open(url, windowName, `width=${w}, height=${h}, top=${y}, left=${x}`)
      if (this.popupPollTimer) {
        window.clearInterval(this.popupPollTimer)
      }
      this.popupPollTimer = window.setInterval(() => {
        if (stripePopup.closed === true) {
          window.clearInterval(this.popupPollTimer)
          popupCloseHandler()
        }
      }, 250)
      return stripePopup
    }
  }
}
