<script>
import Auth from '@/services/auth.js'
import { allowedToRedirect } from '@/utils/redirect.js'
import { useMainStore } from '@/stores'

export default {
  name: 'AuthView',
  beforeCreate () {
    /**
     * Callback view from Authentication process.
     * If it succeeds, redirects to onboarding
     * If does not, redirect back to LogIn view.
     */

    // TODO: REMOVE THIS WHEN MOVING TO AWS (SIGN IS NOT NEEDED)
    const sign = this.$route.query.sign
    const store = useMainStore()

    store.resetLocalStorage()
    return Auth.getAuthStatus(sign).then(response => {
      const redirect = sessionStorage.getItem('redirect_target')
      store.setAuthInfo(response.data)
      if (redirect && allowedToRedirect(redirect, this.$router)) {
        sessionStorage.removeItem('redirect_target')
        window.location.href = process.env.VUE_APP_SOYUZ_BASE_URL + '#' + redirect
      } else {
        this.$router.push({ path: '/' })
      }
    }).catch(e => {
      this.$router.push('Login')
    })
  }
}
</script>
