<template>
  <div class="container pt-7">
    <div class="card shadow-light p-5 col-12">
      <div class="row">
        <div class="col-md-6 col-sm-10">
          <h1 class="mb-8">Claim #{{claimRef}}</h1>
          <p class='ml-7'>Policy Reference: #{{policyRef}}</p>
          <p class='ml-7'>Order Number: #{{orderNumber}}</p>
          <p class='ml-7'>Claim date: {{dateClaim}}</p>
          <p class='ml-7'>Claim item(s):</p>
          <ul>
            <li v-for="item in items" v-bind:key="item.id" class="ml-4">{{item.quantity}} x {{item.name}};</li>
          </ul>
          <p class='ml-7'>Tracking number: {{ shippingTrackingNumber ? shippingTrackingNumber : "Not available"}}</p>
          <p class='ml-7'>Ship date: {{dateShipping ? dateShipping : "Not available"}}</p>
          <p class='ml-7'>
            Expected delivery date: {{expectedDeliveryDate ? expectedDeliveryDate : "Not available"}}
          </p>
          <p class='ml-7'>Delivered date: {{deliveredDate ? deliveredDate : "Not available"}}</p>
        </div>
        <div class="col-md-5 col-sm-12 pt-10">
          <div class="p-5" style="background-color: rgba(0, 0, 0, 0.05)">
            <p>Type of claim: {{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}}</p>
            <p>Status of claim:
              <ClaimStatusBadges
                  v-bind="{
                  status: status,
                  }"
              />
            </p>
            <p>Related Invoice:
              <router-link
                :to="{
                  name: 'DetailInvoices',
                  params: { invoice_id: invoiceId },
                }"
              >
                <a class="a-link" v-bind:href="invoiceId"
                  >Invoice#{{invoiceId}}</a
                >
              </router-link>
            </p>
          </div>
        </div>
      </div>

      <div v-if="changelog.length" class="row p-6">
        <h4 class="col-12 mb-6">Changelog</h4>
        <table
            data-toggle="table"
            class="
            table
            b-table
            table-striped
            b-table-stacked-md
            table-bordered table-hover
            col-8
            "
            data-pagination="true"
            data-filter-control="true"
            data-filter-data="var:selectTypes"
        >
            <thead class="thead-light">
            <tr>
                <th style="" data-field="0">
                <div class="th-inner sortable both">Timestamp</div>
                <div class="fht-cell">
                    <div class="no-filter-control"></div>
                </div>
                </th>
                <th style="" data-field="1">
                <div class="th-inner sortable both">Status</div>
                <div class="fht-cell">
                    <div class="no-filter-control"></div>
                </div>
                </th>
                <th style="" data-field="2">
                <div class="th-inner sortable both">Description</div>
                <div class="fht-cell">
                    <div class="no-filter-control"></div>
                </div>
                </th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="log in changelog" v-bind:key="log.id">
                <td style="">
                    {{ (new Date(log.timestamp)).toLocaleString() }}
                </td>
                <td style="">
                    {{ log.status }}
                </td>
                <td style="">{{ log.description }}</td>
                </tr>
            </tbody>
        </table>
      </div>
      <div v-if="media.length" class="row p-6">
        <h4 class="col-12 mb-6">Data submitted</h4>
        <ul>
          <li v-for="item in media" v-bind:key="item[0]">
            <a target="_blank" :href="item[1]">{{item[0]}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClaimStatusBadges from '@/components/claims/ClaimStatusBadges.vue'
export default {
  name: 'ClaimInfo',
  props: {
    claimId: Number,
    claimRef: String,
    policyRef: String,
    shippingTrackingNumber: String,
    items: Array,
    dateShipping: String,
    dateClaim: String,
    type: String,
    status: String,
    paymentStatus: String,
    media: Array,
    changelog: Array,
    expectedDeliveryDate: String,
    deliveredDate: String,
    invoiceId: String,
    orderNumber: String
  },
  components: {
    ClaimStatusBadges
  }
}
</script>
