/* eslint-disable camelcase */
import { http } from '../http-common'

class InvoicesDataService {
  /*
    InvoicesDataService is in charge of doing the API request to ISS for the Invoices view
  */

  // Returns all invoices for a shop
  getInvoices (page = 1, limit = 10) {
    return http.get('/invoices/', {
      params: {
        page,
        limit
      }
    })
  }

  getEstimate () {
    return http.get('/invoices/estimate/')
  }

  getInvoiceOverviewData () {
    return http.get('/invoices/overview/')
  }
}

export default new InvoicesDataService()
