<script setup>
import { ref } from 'vue'

const props = defineProps(
  {
    policyholder: {
      type: Object
    }
  }
)

const { policyholder } = props
const isCodeCopiedToClipboard = ref(false)

async function copyPolicyholderIDToClipboard () {
  try {
    await navigator.clipboard.writeText(policyholder.business_id)
    isCodeCopiedToClipboard.value = true
    setTimeout(() => {
      isCodeCopiedToClipboard.value = false
    }, 5000)
  } catch (error) {
    console.error('Failed to copy text: ', error)
  }
}
</script>

<template>
  <div class="wrapper">
    <h4>Policyholder ID</h4>
    <p>Copy the following code to be able to interact with Anansi through your partner. You can find your Policyholder ID at any time in <u>My Account</u>.</p>
    <div class="card pb-4 policyholder-id-container col-md-8">
      <div class="policyholder-id-wrapper disabled">
        <p>{{ policyholder.business_id }}</p>
      </div>
      <div class="copy-id-buttons-wrapper">
        <button :class="['policyholder-id-copy-button', isCodeCopiedToClipboard ? 'hidden' : 'visible']" @click="copyPolicyholderIDToClipboard">Copy ID</button>
        <div :class="['copy-to-clipboard-copied', isCodeCopiedToClipboard ? 'visible' : 'hidden']">Copied to clipboard!</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  margin: 40px 0;
}
</style>
