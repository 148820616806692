<script setup>
import InvoiceGraph from './InvoiceGraph.vue'
import InvoiceProgress from './InvoiceProgress.vue'
import InvoiceTooltip from './InvoiceTooltip.vue'
const props = defineProps([
  'actualTotal',
  'estimateTotal',
  'actualInvoiceDayData',
  'estimateInvoiceDayData',
  'daysRemainingToPay',
  'requiredPaymentDay',
  'ddActive',
  'ddPaymentDay',
  'hasInvoiceToPay',
  'invoiceFirstDayPayable',
  'invoiceToPayMonth',
  'invoiceToPayDueByMonth',
  'invoiceToPayStatus',
  'currentInvoiceMonth',
  'currentInvoiceDueByMonth'])

const nth = (d) => {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1: return 'st'
    case 2: return 'nd'
    case 3: return 'rd'
    default: return 'th'
  }
}

const summaryText = () => {
  if (props.ddActive) {
    const dateString = props.ddPaymentDay + nth(props.ddPaymentDay)
    return `You have Direct Debit setup so your invoice will be paid automatically on the ${dateString} of the month.`
  } else {
    const dateString = props.requiredPaymentDay + nth(props.requiredPaymentDay)
    return `You do not have Direct Debit setup so must manually pay each invoice by the ${dateString} of the month.
           You can setup Direct Debit when you pay your next invoice.`
  }
}

</script>

<template>
  <div class="tw-drop-shadow-md tw-rounded tw-bg-white
  tw-w-8/12 tw-max-h-96
  tw-grid tw-grid-rows-[max-content_1fr]
  tw-divide-y tw-content-start">
    <h4 class="tw-py-4 tw-pl-4 tw-mb-0">Your current situation</h4>
    <div class="tw-grid tw-grid-cols-2 tw-divide-x">
      <div class="tw-grid tw-cols-1 tw-rows-2 tw-divide-y tw-grid-rows-[max-content_1fr]">
        <div class="tw-p-4">
          <p class="tw-m-0">{{ summaryText() }}</p>
        </div>
        <div v-if="hasInvoiceToPay === true" class="tw-grid tw-rows-3 tw-cols-1 tw-p-4">
          <div class="tw-flex tw-justify-between">
            <p class="tw-m-0">Payment due for {{ invoiceToPayMonth }} invoice by</p>
            <p class="tw-m-0">{{ props.requiredPaymentDay + nth(props.requiredPaymentDay) }} {{ invoiceToPayDueByMonth }}</p>
          </div>
          <div class="tw-py-4">
            <InvoiceProgress :maxDaysToPay="requiredPaymentDay" :daysRemainingToPay="daysRemainingToPay"/>
          </div>
          <div>
            <p class="tw-m-0">{{ daysRemainingToPay }} day{{ daysRemainingToPay == 1 ? "" : "s" }} remaining</p>
            <p v-if="invoiceToPayStatus === 'PAYMENT_STARTED' || invoiceToPayStatus === 'PAYMENT_PENDING'"
              class="tw-m-0 tw-text-anansi-gray tw-text-sm">
              Your payment is being processed</p>
          </div>
        </div>
        <div v-if="hasInvoiceToPay === false" class="tw-grid tw-rows-3 tw-cols-1 tw-p-4">
          <p class="tw-m-0">You've paid all your invoices</p>
          <div class="tw-flex tw-justify-between">
            <p class="tw-m-0">Your invoice for {{ currentInvoiceMonth }} can be paid from</p>
            <p class="tw-m-0">{{ invoiceFirstDayPayable + nth(invoiceFirstDayPayable) }} {{ currentInvoiceDueByMonth }}</p>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-1 tw-grid-rows-[max-content_1fr]">
        <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
          <div class="tw-flex tw-items-center">
            <p class="tw-m-0 tw-text-anansi-gray">{{ currentInvoiceMonth }} Total</p>
            <InvoiceTooltip
            content="The current total of your invoice. This will update as your parcels are insured."/>
          </div>
          <p class="tw-m-0">£{{ actualTotal }}</p>
        </div>
        <div class="tw-flex tw-justify-between tw-items-center tw-px-4">
          <div class="tw-flex tw-items-center">
            <p class="tw-m-0 tw-text-anansi-gray">Estimate for {{ currentInvoiceMonth }} Invoice</p>
            <InvoiceTooltip
            content="An estimate of what your invoice total will be at the end of the month.
                    This is based on your recent parcel volume and current insurance settings."/>
          </div>
          <p class="tw-m-0">{{ estimateTotal ? '£' + estimateTotal : '-'}}</p>
        </div>
        <div class="tw-px-4 tw-pb-4">
          <InvoiceGraph :actualInvoiceDayData="actualInvoiceDayData" :estimateInvoiceDayData="estimateInvoiceDayData"/>
        </div>
      </div>
    </div>
  </div>
</template>
