/* eslint-disable camelcase */
import { http } from '../http-common'

class GenerateUploadUrlService {
  /*
    GenerateUploadUrlService reuqests a presigned link to ISS
  */

  // Returns claimable fulfillments
  generateUploadUrl (fulfillment_id, filenames) {
    return http.post('/generate_upload_url_for_claim_request/', {
      fulfillment_id,
      filenames
    })
  }
}

export default new GenerateUploadUrlService()
