<template>
  <NavigationBar v-bind="{ invoices: true }" />
  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col" v-if="invoice">
      <div class="py-5">
        <h1 class="mb-7">Invoice Detail</h1>

        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Invoice Information</h4>
          </div>
          <div class="col-md-8">
            <div class="card shadow-light p-5 mr-5">
              <h4 class="mb-2">Invoice: {{ invoice.invoice_id }}</h4>

              <div class="row">
                <div class="col">
                  <label class="font-weight-bold mb-0 mt-3" for="#amount_paid"
                    >Status</label
                  >
                  <p>
                    <InvoiceStatusBadges
                      v-bind="{
                      status: invoice.status,
                      }"
                    />
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label class="font-weight-bold mb-0 mt-3" for="#amount_paid"
                    >Amount Paid</label
                  >
                  <p id="#amount_paid">£{{ invoice.amount_paid }}</p>
                </div>
                <div class="col">
                  <label class="font-weight-bold mb-0 mt-3" for="#period_start"
                    >Period Start</label
                  >
                  <p id="#period_start">{{ invoice.period_start }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label class="font-weight-bold mb-0" for="#amount_due"
                    >Amount Due</label
                  >
                  <p id="#amount_due">£{{ invoice.amount_due }}</p>
                </div>
                <div class="col">
                  <label class="font-weight-bold mb-0" for="#period_end"
                    >Period End</label
                  >
                  <p id="#period_end">{{ invoice.period_end }}</p>
                </div>
              </div>

              <div class="row justify-content-end p-0 mt-3 mr-2">
                <div class="col">
                  <a
                    v-if="invoice.invoice_pdf"
                    v-bind:href="invoice.invoice_pdf"
                    target="_blank"
                    class="btn btn-primary btn-sm mr-5 my-1"
                  >
                    Download Invoice
                  </a>
                  <button v-if="invoice.status === 'READY_FOR_PAYMENT'" @click="openPaymentWindow(invoice.payment_url, 'invoiceDetailPaymentPopup', this.loadInvoiceDetail)" class="btn btn-primary btn-sm mr-5 my-1"> Pay Invoice </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="w-80 m-5" />

        <!-- TABLE -->

        <div
          class="bg-white card shadow-light col-12 p-5 table-style font-size-sm"
        >
          <h2 class="mb-5">Invoice Items</h2>
          <div class="bootstrap-table bootstrap4">
            <div class="fixed-table-toolbar"></div>

            <div class="fixed-table-container" style="padding-bottom: 0px">
              <div class="fixed-table-header" style="display: none">
                <table></table>
              </div>
              <div class="fixed-table-body">
                <div
                  class="fixed-table-loading table table-bordered table-hover"
                  style="top: 50px"
                ></div>
                <table
                  data-toggle="table"
                  class="
                    table
                    b-table
                    table-striped
                    b-table-stacked-md
                    table-bordered table-hover
                  "
                  data-pagination="true"
                  data-filter-control="true"
                  data-filter-data="var:selectTypes"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="" data-field="0">
                        <div class="th-inner sortable both">Order Number</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="1">
                        <div class="th-inner sortable both">Policy Ref.</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="2">
                        <div class="th-inner sortable both">
                          Insurance Premium
                        </div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="3">
                        <div class="th-inner sortable both">
                          Policy Issue At
                        </div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="item in items" v-bind:key="item.contract_number">
                      <td style="">
                        <a
                          v-bind:href="item.order_external_admin_url"
                          target="_blank"
                        >
                          #{{ item.order_number }}
                        </a>
                      </td>
                      <td style="">
                        {{ item.contract_number }}
                      </td>
                      <td style="">£{{ item.price }}</td>
                      <td style="">
                        {{ item.date }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot style="display: none">
                    <tr></tr>
                  </tfoot>
                </table>
              </div>
              <div class="fixed-table-footer">
                <table>
                  <thead>
                    <tr></tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- TABLE -->
        </div>
      </div>
    </div>
  </div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import InvoiceDetailDataService from '@/services/InvoiceDetailDataService.js'
import paymentPopupMixin from '@/mixins/paymentPopupMixin'
import InvoiceStatusBadges from '@/components/invoices/InvoiceStatusBadges.vue'

export default {
  name: 'DetailInvoices',
  title: 'Anansi - Invoice Details',
  mixins: [paymentPopupMixin],
  components: {
    NavigationBar,
    MainFooter,
    InvoiceStatusBadges
  },
  data () {
    return {
      invoiceId: this.invoice_id,
      invoice: undefined,
      items: undefined
    }
  },
  props: ['invoice_id'],
  beforeMount () {
    return this.loadInvoiceDetail()
  },
  methods: {
    loadInvoiceDetail () {
      return InvoiceDetailDataService.getInvoiceDetail(this.invoiceId).then((response) => {
        this.data = response.data
        this.invoice = this.data.invoice
        this.items = this.data.items
      })
    }
  }
}
</script>
