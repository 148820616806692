<template>
<div v-if="showModal" class="modal-backdrop fade show"></div>
<div v-if="showModal" class="modal fade onBoarding show" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" style="padding-right: 15px; display: block;" aria-modal="true">
    <div id="modal-body" class="modal-dialog w-90 modal-dialog-centered" role="document">
        <div class="modal-content h-100" data-test="claims-bank-details-modal">
            <div class="modal-body px-0 py-0 h-100">
                <div class="container" style="max-width: none !important">

    <div class="row justify-center">
        <div class="text-center py-5 col-12 col-md-5 order-md-1 bg-gray-200">
                <div class="row justify-content-center align-self-center px-5">
                   <img class="d-block mx-auto pr-5 mb-1 mt-5" style="padding-bottom: 8px; height: 60px;" src="@/assets/img/anansi/logo_anansi.png" alt="off course"/>
      <form class="w-100 d-block mx-auto" method="post">
        <div class="form-group">
          <div class="mt-5 billing-text">
          <label class="w-100">Your data is being looked after by:</label>
          <label class="w-200 font-weight-bold">Anansi Technology Ltd</label>
          </div>
          <hr style="border-top: 1px solid #D9E2EF">
          <div class="mt-3 billing-text">
            <h1>
          <i class="fa fa-envelope" style="text-size: 20px"></i>
            </h1>
          <label class="w-100">For all questions, concerns and queries please contact:</label>
          <label class="w-100 font-weight-bold">hello@withanansi.com</label>
          <hr style="border-top: 1px solid #D9E2EF">
          <div class="mt-3 billing-text">
            <h1>
          <i class="fas fa-lock" style="text-size: 20px"></i>
            </h1>
          </div>
          <div class="mt-3 billing-text">
          <label class="w-100">SECURE CONNECTION</label>
          <label class="w-100">FCA AUTHORISED</label>
          </div>
          </div>
        </div>
      </form>
            </div>
        </div>
        <div class="text-center col-12 col-md-7 order-md-2">
          <button @click="closeShowModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            <AddPaymentMethod v-if="bankAccount == null || addBankAccount === true" @paymentMethodAdded="handlePaymentMethodAdded" />
            <template v-else>
                <div class="container d-flex h-100 w-100 py-0">

                  <div class="row justify-content-center align-self-center w-100 py-8">
                    <div>
                      <h3 style="margin-bottom: 35px;" class="text-center mr-8">
                      My bank account details
                      </h3>
                      <div class="container edit-bank-box">
                        <div style="margin-bottom: 20px;"  class="row">
                          <div class="col">
                            <p class="font-weight-bold">Account name: <span class="font-weight-normal"> {{bankAccount.beneficiary_name}}</span></p>
                            <p class="font-weight-bold">IBAN: <span class="font-weight-normal"> {{bankAccount.iban}}</span></p>
                            <p class="font-weight-bold">SWIFT/BIC: <span class="font-weight-normal"> {{bankAccount.swift}}</span></p>
                          </div>
                          <div class="col-md-auto text-right">
                            <i style="font-size: 130px;" class="fa fa-bank"  aria-hidden="true"></i>
                          </div>
                        </div>
                        <div class="col text-center">
                          <button @click="addBankAccountModal" class="navbar-btn btn btn-sm btn-secondary-purple w-50" data-test="edit-bank-details-button">  Edit Information</button>
                        </div>
                      </div>
                    </div>
                </div>

                </div>
            </template>
        </div>
    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import '@/assets/css/stripe.css'
import ClaimsPaymentDataService from '@/services/ClaimsPaymentDataService.js'
import AddPaymentMethod from '@/components/claims/AddPaymentMethod.vue'

export default {
  name: 'ClaimsPaymentModal',
  components: {
    AddPaymentMethod
  },

  beforeMount () {
    return this.getBankAccount()
  },

  methods: {
    getBankAccount () {
      return ClaimsPaymentDataService.getBankAccounts().then(response => {
        if (response.data != null && response.data.items.length > 0) {
          this.bankAccount = response.data.items[0]
        } else {
          this.bankAccount = null
        }
      }).catch(e => {
        console.log(e)
      })
    },

    closeShowModal () {
      this.showModal = false
    },

    addBankAccountModal () {
      this.addBankAccount = true
    },

    handlePaymentMethodAdded (event) {
      this.addBankAccount = false
      return this.getBankAccount()
    }
  },

  data () {
    return {
      bankAccount: null,
      addBankAccount: false,
      bankAccountChange: false,
      editBankAccounts: false,
      showModal: true
    }
  }
}
</script>
