/* eslint-disable camelcase */
import { http } from '../http-common'

class ManualClaimFormService {
  /*
    ManualClaimFormService is in charge of doing the API request to ISS for the damage claim form view
  */

  // Returns claimable fulfillments
  getFulfillmentsData (
    order_number_search_text = undefined,
    claim_type = undefined) {
    const payload = {
      order_number_search_text,
      claim_type
    }

    return http.post('/data_for_manual_claim/', payload)
  }

  // Posts form data
  sendData (data) {
    const claimData = {
      fulfillment_id: data.fulfillment_id,
      fulfillment_origin_region: data.fulfillment_origin_region,
      fulfillment_origin_city: data.fulfillment_origin_city,
      fulfillment_origin_country: data.fulfillment_origin_country,
      description: data.description,
      attempt_items: data.attempt_items,
      media: data.media,
      claim_type: data.claim_type,
      reporter: data.reporter,
      damage_notes: data.damage_notes,
      damage_reparable: data.damage_reparable
    }

    return http.post('/claims/', claimData)
  }
}

export default new ManualClaimFormService()
