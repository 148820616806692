<script setup>
import { ref } from 'vue'
import Auth from '@/services/auth.js'

const submitting = ref(false)
const emailSent = ref(false)
const emailDoesNotExist = ref(false)
const email = ref('')

async function submit () {
  submitting.value = true
  emailDoesNotExist.value = false

  try {
    await Auth.recoverPassword(email.value)
    submitting.value = false
    emailSent.value = true
  } catch (e) {
    if (e.response.status === 403 || e.response.status === 401) {
      submitting.value = false
      emailDoesNotExist.value = true
    } else if (e.response.status === 410) {
      submitting.value = false
      this.$router.go()
    } else if (e.response.status === 500) {
      submitting.value = false
      alert('Something went wrong, please try again later')
    } else if (e.response.status === 429) {
      submitting.value = false
      emailSent.value = true
    }
  }
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
      <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
    </div>
    <form
      v-if="!emailSent"
      class="tw-mx-auto tw-flex tw-flex-col tw-gap-2"
      id="login-with-shop-form"
      @submit.prevent="submit"
    >
      <span class="tw-text-xs tw-text-center">
        Don't worry, just enter your email address and we'll send you a
        link to reset your password.
      </span>
      <input
        v-model="email"
        class="tw-border tw-p-3 tw-rounded"
        required
        type="email"
        id="email"
        name="email"
        placeholder="Email"
      />
      <p
        v-if="emailDoesNotExist"
        class="tw-text-center tw-text-red-500"
      >
        We couldn't find an account with this email address.
      </p>
      <router-link
        v-if="emailDoesNotExist"
        to="signup"
        class="tw-text-center protection-status-link"
        ><p>Do you want to sign up?</p></router-link
      >
      <button
        id="login-with-shop-submit-btn"
        class="btn btn-primary btn-email-signup-login tw-w-full tw-mt-2"
        type="submit"
        :disabled="submitting"
      >
        <span
          v-if="submitting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-if="submitting" class="sr-only">Loading...</span>
        <span v-else>Reset my password</span>
      </button>
    </form>
    <div v-else>
      <h5 class="tw-text-center tw-mt-6 tw-mb-3">Email sent!</h5>
      <p class="tw-text-center tw-mb-3">
        Check your email and follow its instructions to continue.
      </p>
      <router-link to="loginemail"
        ><button
          class="btn btn-primary btn-email-signup-login w-100 mt-1"
        >
          Back to login
        </button></router-link
      >
    </div>
  </div>
</template>
