<template>
  <form
    action="javascript:;"
    id="CompanyInfoForm"
    class="form-group needs-validation"
  >
    <div id="welcomeModalStep3UserInputDetails">
      <div
        v-if="SourcePartner === 'MINTSOFT' || SourcePartner === 'API'"
        class="
          form-group
          text-left
          col-12
          p-0
          mt-4
          d-flex
          justify-content-between
        "
      >
        <div class="form-group text-left col-5 p-0">
          <label for="welcomeModalStep3inputName">First Name</label>
          <input
            type="text"
            class="form-control-onboarding  required"
            id="welcomeModalStep3inputName"
            required
            placeholder="First name"
            v-model="selectedCompanyInformation.firstName"
            data-test="onboarding-first-name-input"
            />
          </div>
          <div class="form-group text-left col-6 p-0">
            <label for="welcomeModalStep3inputName">Last Name</label>
            <input
            type="text"
            class="form-control-onboarding  required"
            id="welcomeModalStep3inputName"
            required
            placeholder="Last name"
            v-model="selectedCompanyInformation.lastName"
            data-test="onboarding-last-name-input"
          />
        </div>
      </div>

      <div v-if="!(SourcePartner === 'SHOPIFY' || SourcePartner === 'MINTSOFT' || SourcePartner === 'API')" class="form-group text-left">
        <label for="welcomeModalStep3Business"
          >Your company's public name:</label
        >
        <input
          type="text"
          class="form-control-onboarding  required"
          id="welcomeModalStep3Business"
          required
          placeholder="Enter company's public name"
          v-model="selectedCompanyInformation.publicName"
        />
      </div>

      <section class="row">
        <div class="col-12 col-md-offset-3 text-left">
          <label for="select-company-selectized"
            >Search the register for your UK business:</label
          >
          <!--TODO: REMOVE THIS IN FAVOR OF THE AUTOCOMPLETE COMPONENT-->
          <div class="dropdown">
            <input
              v-model="searchUkCompany"
              :disabled="isNotUkCompany"
              placeholder="Enter business name"
              ref="dropdownMenuInput"
              class="
                form-control-onboarding
                dropdown-toggle
                padding-btn-options
              "
              autocomplete="false"
              type="text"
              id="dropdownMenuButton"

              @focus="showDropdownMenu()"
              @blur="hideDropdownMenu()"

              data-test="onboarding-uk-business-register"
            />
            <div class="dropdown-menu" ref="dropdownMenu" aria-labelledby="dropdownMenuButton">
              <a
                v-for="ukCompanyInformation in ukCompaniesInformation"
                :key="ukCompanyInformation.company_number"
                @click="
                  selectCompany(
                    ukCompanyInformation.company_name,
                    ukCompanyInformation.address,
                    ukCompanyInformation.company_number,
                  )
                "
                class="dropdown-item"
                :class="
                  selectedCompanyInformation.company_number === ukCompanyInformation.company_number
                    ? 'active'
                    : ''
                "
                >{{ ukCompanyInformation.company_name }}</a
              >
            </div>
          </div>
        </div>
      </section>

      <p class="my-5 text-left">Your business information:</p>

      <div class="onboarding-business-information-box mx-auto">
        <div class="form-group text-left">
          <label for="welcomeModalStep3Business">Legal Business name</label>
          <input
            type="text"
            class="form-control-onboarding  required"
            id="welcomeModalStep3Business"
            required
            :disabled="
              !isNotUkCompany
            "
            placeholder="Legal Business name"
            v-model="selectedCompanyInformation.company_name"
          />
        </div>

        <div
          class="form-group text-left col-12 p-0 d-flex justify-content-between"
        >
          <div class="form-group text-left col-5 p-0">
            <label for="welcomeModalStep3Address1">Address line 1</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3Address1"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="Business address 1"
              v-model="selectedCompanyInformation.address_1"
            />
          </div>
          <div class="form-group text-left col-6 p-0">
            <label for="welcomeModalStep3Address2">Address line 2</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3Address2"
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="Business address 2"
              v-model="selectedCompanyInformation.address_2"
            />
          </div>
        </div>

        <div
          class="form-group text-left col-12 p-0 d-flex justify-content-between"
        >
          <div class="form-group text-left col-4 p-0">
            <label for="welcomeModalStep3PostalCode">Post Code</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3PostalCode"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="Post Code"
              v-model="selectedCompanyInformation.postal_code"
            />
          </div>
          <div class="form-group text-left col-4 p-0">
            <label for="welcomeModalStep3Region">County</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3Region"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="County"
              v-model="selectedCompanyInformation.region"
            />
          </div>
          <div class="form-group text-left col-3 p-0">
            <label for="welcomeModalStep3Premises">Building Number(s)</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3Premises"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="Building Number(s)"
              v-model="selectedCompanyInformation.premises"
            />
          </div>
        </div>

        <div
          class="form-group text-left col-12 p-0 d-flex justify-content-between"
        >
          <div class="form-group text-left col-5 p-0">
            <label for="welcomeModalStep3Locality">City</label>
            <input
              type="text"
              class="form-control-onboarding  required"
              id="welcomeModalStep3Locality"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === !emptyCompanyAddress)
              "
              placeholder="City"
              v-model="selectedCompanyInformation.locality"
            />
          </div>
          <div class="form-group text-left col-6 p-0">
            <label for="welcomeModalStep3Country">Country</label>
            <select id="welcomeModalStep3Country"
              v-model="selectedCompanyInformation.country"
              class="form-control required"
              required
              :disabled="
                !isNotUkCompany &&
                ((Object.keys(selectedCompanyInformationAPIdata).length !== 0) === (selectedCompanyInformationAPIdata.country !== undefined))
              "
              aria-label="country dropdown"
              :style="[!isNotUkCompany &&
                selectedCompanyInformationAPIdata.country != undefined ? {' -moz-appearance;' : 'none', '-webkit-appearance': 'none'} : {}]">
                <option :value=undefined disabled selected>Select a country</option>
                <option
                      v-for="country in availableCountries"
                      :key="country"
                      :value=country
                      style="cursor: pointer; font-size: 17px"
                      @click="selectCountry(country)"
                      class="dropdown-item pt-1"
                      >{{ country }}
                </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="form-check mt-3 text-left">
      <input
        type="checkbox"
        class="form-check-input"
        id="welcomeModalStep3UKRegistered"
        v-model="this.selectedCompanyInformation.policyholderUkCompany"
      />
      <label class="form-check-label" for="welcomeModalStep3UKRegistered"
        >The policyholder is a UK registered company</label
      >
    </div>
    <div class="form-check text-left">
      <input
        type="checkbox"
        class="form-check-input"
        id="welcomeModalStep3AboveCheck"
        v-model="this.selectedCompanyInformation.validInformation"
        :required="this.selectedCompanyInformation.policyholderUkCompany"
        :class="this.selectedCompanyInformation.policyholderUkCompany ? 'required' : ''"
        data-test="onboarding-valid-company-information-check"
      />
      <label class="form-check-label" for="welcomeModalStep3AboveCheck"
        >I confirm that our business has provided valid information
      </label>
    </div>
  </form>
  <button
    id="welcomeModalStep0Next"
    type="submit"
    class="btn btn-secondary float-right"
    v-show="SaveButton"
    @click="setCompanyInformation()"
  >
    {{ isNotUkCompany ? "Confirm" : "Confirm Contract" }}
    <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
  </button>
</template>
<script>
import OnBoardingDataService from '@/services/OnBoardingDataService.js'
export default {
  name: 'CompanyInfo',
  emits: ['savedCompanyInfo'],
  props: {
    SaveButton: {
      type: Boolean,
      default: true
    },
    SourcePartner: {
      type: String,
      default: ''
    },
    companyInfoData: {
      type: Object,
      default: () => ({})
    }
  },
  beforeMount () {
    this.selectedCompanyInformation = this.companyInfoData
    const helperDate = new Date()
    const year = helperDate.getFullYear()
    const month = helperDate.getMonth() + 1
    const day = helperDate.getDate()
    this.currentDate = day + '/' + month + '/' + year
    this.endDate = day + '/' + month + '/' + (year + 1)
  },
  watch: {
    isNotUkCompany: function (val) {
      if (!val) {
        this.resetCompanyData()
      } else {
        this.selectedCompanyInformation.validInformation = false
      }
      this.isNotUkCompany = val
      this.selectedCompanyInformation.policyholderUkCompany = !val
    },
    policyholderUkCompany: function (val) {
      this.isNotUkCompany = !val
      this.selectedCompanyInformation.policyholderUkCompany = val
    },
    searchUkCompany: function (val) {
      this.searchUkCompany = val
      this.searchForUkCompany(val)
    }
  },
  methods: {
    searchForUkCompany (str) {
      return OnBoardingDataService.searchUKCompany(str).then((response) => {
        this.ukCompaniesInformation = response.data.items
        this.selectedCompanyInformation.ownerName = response.data.owner
        this.selectedCompanyInformation.currency = response.data.currency
      })
    },
    selectCompany (companyName, address, companyNumber) {
      this.selectedCompanyInformation.company_name = companyName
      this.selectedCompanyInformation.address_1 = address?.address_line_1
      this.selectedCompanyInformation.address_2 = address?.address_line_2
      this.selectedCompanyInformation.postal_code = address?.postal_code
      this.selectedCompanyInformation.country = address?.country
      this.selectedCompanyInformation.locality = address?.locality
      this.selectedCompanyInformation.premises = address?.premises
      this.selectedCompanyInformation.region = address?.region
      this.selectedCompanyInformation.company_number = companyNumber
      this.emptyCompanyAddress = address === null
      this.selectedCompanyInformationAPIdata = Object.assign(
        {},
        this.selectedCompanyInformation
      )
      this.searchUkCompany = name
      this.selectedCompanyInformation.policyholderUkCompany = true
      this.hideDropdownMenu()
    },
    resetCompanyData () {
      this.selectedCompanyInformation = {
        publicName: undefined,
        company_name: undefined,
        ownerName: undefined,
        firstName: undefined,
        lastName: undefined,
        currency: undefined,
        address_1: undefined,
        address_2: undefined,
        postal_code: undefined,
        country: undefined,
        locality: undefined,
        premises: undefined,
        region: undefined,
        company_number: undefined
      }
      this.selectedCompanyInformationAPIdata = {}
      this.emptyCompanyAddress = true
    },
    setCompanyInformation () {
      if (document.getElementById('CompanyInfoForm').reportValidity()) {
        return Promise.all([
          OnBoardingDataService.setCompanyInformation(
            this.selectedCompanyInformation.publicName,
            this.selectedCompanyInformation.company_name,
            this.selectedCompanyInformation.ownerName,
            this.selectedCompanyInformation.firstName,
            this.selectedCompanyInformation.lastName,
            this.selectedCompanyInformation.currency,
            this.selectedCompanyInformation.address_1,
            this.selectedCompanyInformation.address_2,
            this.selectedCompanyInformation.postal_code,
            this.selectedCompanyInformation.country,
            this.selectedCompanyInformation.locality,
            this.selectedCompanyInformation.premises,
            this.selectedCompanyInformation.region,
            this.selectedCompanyInformation.company_number,
            this.selectedCompanyInformation.policyholderUkCompany
          )
        ]).then((response) => {
          this.$emit('savedCompanyInfo', this.selectedCompanyInformation)
        })
      }
    },
    selectCountry (country) {
      this.selectedCompanyInformation.country = country
    },
    isCompanyValid () {
      if (this.selectedCompanyInformation.company_name) {
        return true
      } else {
        return false
      }
    },
    showDropdownMenu () {
      this.$refs.dropdownMenu.className = 'dropdown-menu show'
    },
    hideDropdownMenu () {
      const element = this.$refs.dropdownMenu
      setTimeout(function () {
        element.className = 'dropdown-menu'
      }, 500)
    }
  },
  data () {
    return {
      searchUkCompany: undefined,
      ukCompaniesInformation: [
        {
          company_name: undefined,
          address: undefined,
          company_number: undefined,
          status: undefined
        }
      ],
      selectedCompanyInformation: {},
      selectedCompanyInformationAPIdata: {},
      emptyCompanyAddress: true,
      isNotUkCompany: false,
      currentDate: undefined,
      endDate: undefined,
      availableCountries: [
        'Wales',
        'England',
        'Scotland',
        'Great Britain',
        'United Kingdom',
        'Northern Ireland'
      ]
    }
  }
}
</script>
