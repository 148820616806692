<script setup>
import { useOnboardingStore } from '@/stores/onboarding'

const onboardingStore = useOnboardingStore()

</script>

<template>
  <div class="tw-bg-gray-100 tw-p-8 tw-flex tw-flex-col tw-justify-between tw-shadow-md tw-rounded tw-w-80">
    <div>
      <img src="@/assets/img/logo/anansi-logo.svg" class="tw-w-40"/>
    </div>
    <div v-if="!onboardingStore.isOnboardingCompleted" class="tw-flex tw-flex-col steps-wrapper">
      <span class="tw-text-sm tw-font-bold tw-text-anansi-purple">Step {{ onboardingStore.currentStepIndex }} of {{ onboardingStore.stepsInfo.length }}</span>
      <div v-for="(stepInfo, index) in onboardingStore.stepsInfo" :key="index">
        <span :class="[onboardingStore.currentStepIndex == index + 1 ? 'active-step tw-font-bold tw-relative' : '', 'tw-text-sm tw-select-none']">{{ stepInfo.title }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.steps-wrapper .active-step::before {
  position: absolute;
  content: '→';
  left: -12px;
  top: -2px;
}
</style>
