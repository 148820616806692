import { defineStore } from 'pinia'
import { http } from '@/http-common'
import { roundWithDecimalPlaces, capitalizeString } from '@/utils/format'
import PolicyholderDataService from '@/services/PolicyholderDataService.js'

export const usePolicyholderStore = defineStore('usePolicyholderStore', {
  state: () => ({
    first_name: '',
    last_name: '',
    source_partner: '',
    business_id: '',
    email: '',
    company_has_default_country: true,
    is_partner_merchant: false,
    company: {
      company_name: '',
      company_number: '',
      address: {
        address_line_1: '',
        address_line_2: '',
        country: '',
        county: '',
        locality: '',
        postal_code: '',
        premises: '',
        region: ''
      }
    },
    contract: {
      domestic_premium: 0.0,
      international_premium: 0.0,
      coverage_types: [''],
      ipid_url: '',
      wording_url: '',
      sanitized_domestic_premium: 0.0,
      sanitized_international_premium: 0.0,
      sanitized_coverage_types: ''
    },
    min_insurance_value: 0,
    max_insurance_value: 50,
    uk_registered_company: false,
    is_valid_information: false,
    can_configure_insurance: true,
    completed_onboarding: false
  }),
  actions: {
    setCountry (country) {
      this.company.address.country = country
    },
    setCompany (company) {
      this.company = company
      this.company_has_default_country = !!company.address.country
    },
    searchCompany (companyName = undefined) {
      return http.post('/search_uk_company/', { company_name: companyName })
    },
    changeInsuranceLimits (minValue, maxValue) {
      this.min_insurance_value = minValue
      this.max_insurance_value = maxValue
    },
    updateField (fieldName, value) {
      this[fieldName] = value
    },
    async fetchPolicyholder () {
      try {
        const data = await PolicyholderDataService.getPolicyholder()
        this.setPolicyholderFromData(data)
      } catch (e) {
        console.log(e)
      }
    },
    setPolicyholderFromData (data = {}) {
      this.first_name = data.first_name || ''
      this.last_name = data.last_name || ''
      this.source_partner = data.source_partner || ''
      this.business_id = data.business_id || ''
      this.email = data.email || ''
      this.company_has_default_country = true
      this.is_partner_merchant = data.is_partner_merchant || false
      this.min_insurance_value = data.min_insurance_value || 0
      this.max_insurance_value = data.max_insurance_value || 2500
      this.uk_registered_company = false
      this.is_valid_information = false
      this.can_configure_insurance = data.can_configure_insurance !== false
      this.completed_onboarding = data.completed_onboarding || false

      this.company = data.company || {
        company_name: '',
        address: {
          address_line_1: '',
          address_line_2: '',
          country: '',
          county: '',
          locality: '',
          postal_code: '',
          premises: '',
          region: ''
        }
      }

      this.contract = data.contract || {
        domestic_premium: 0.0,
        international_premium: 0.0,
        coverage_types: [''],
        ipid_url: '',
        wording_url: ''
      }

      this.contract.sanitized_domestic_premium = roundWithDecimalPlaces(this.contract.domestic_premium * 100, 2)
      this.contract.sanitized_international_premium = roundWithDecimalPlaces(this.contract.international_premium * 100, 2)
      this.contract.sanitized_coverage_types = this.contract.coverage_types.map(capitalizeString).join(' and ')
    }
  }
})
