<template>
    <div v-if="!requested">
        <br>
        <h5 class="text-center">This link has expired.</h5>
        <p class="text-center">Click the button below to receive a new email to set your password and follow the instructions. </p>
        <br>
        <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login w-100 mt-4" @click="requestNewLinkEmail">
        Send New Email
        </button>
    </div>

    <div v-else>
        <div v-if="statusCode === 200">
            <br>
            <h5 class="text-center">Sent! 📨</h5>
            <p class="text-center">Check your email inbox, if you have any more questions please contact us at
                <a href="mailto:hello@withanansi.com" class="text-gold-anansi .a-link" target="_blank">
                    <u>hello@withanansi.com</u>
                </a>
            </p>
            <br>
        </div>

        <div v-else-if="statusCode === 401">
            <LinkNotValid/>
        </div>

        <div v-else-if="statusCode === 403">
            <LinkNotValid/>
        </div>

        <div v-else-if="statusCode === 500">
            <br>
            <h5 class="text-center">Something went wrong on our side!</h5>
            <p class="text-center">Apologies, we're working on solving this.</p>
            <br>
        </div>

        <div v-else>
            <br>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-purple-anansi" role="loading status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import ApiDataService from '@/services/ApiDataService.js'
import LinkNotValid from '@/components/api-ui/LinkNotValid.vue'
import { useMainStore } from '@/stores'

export default {
  name: 'ResendPasswordEmail',
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  components: {
    LinkNotValid
  },
  data () {
    return {
      statusCode: undefined,
      requested: false
    }
  },
  methods: {
    requestNewLinkEmail () {
      ApiDataService.resendPasswordTokenEmail(this.$route.params.token).then(response => {
        this.statusCode = response.status
      }).catch(e => {
        this.statusCode = e.response.status
      })
      this.requested = true
    },
    login () {
      const store = useMainStore()
      store.resetLocalStorage()
      this.$router.push({ path: '/loginemail/' })
    }
  }
}
</script>
