/* eslint-disable camelcase */
import { http } from '../http-common'

class ApiDataService {
  /*
    ApiDataService is in charge of doing the API request to ISS for the user API related views
  */

  // Gets token status code
  async getTokenStatus (token) {
    return await http.get('/check_set_password/' + token + '/', {
    })
  }

  // Sets a password using the provided token
  setPasswordToken (token, password) {
    return http.post('/set_password/' + token + '/', { password })
  }

  // Asks for a new set password email
  resendPasswordTokenEmail (token) {
    return http.post('/resend_set_password/' + token + '/')
  }
}

export default new ApiDataService()
