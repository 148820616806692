/* eslint-disable camelcase */
import { http } from '../http-common'

class DashboardDataService {
  /*
    DashboardDataService is in charge of doing the API request to ISS for the Dashboard view
  */

  // Get Protection Status
  getProtectionStatus () {
    return http.get('/protection_status/')
  }

  // Get Protection Status
  resendActivationLink () {
    return http.get('/activation_email/')
  }

  // Get OnBoarding Status
  getOnBoardingStatus () {
    return http.get('/onboarding_status/')
  }

  // Get elements for the fulfilments table
  getFulfilments (
    page = 1,
    limit = 10,
    order = 'asc',
    order_by = 'order_number',
    search_text = '',
    inside_insurance_range = undefined,
    in_eligible_country = undefined,
    created_at_before = undefined,
    created_at_after = undefined,
    total_price_below_inclusive = undefined,
    total_price_above_inclusive = undefined,
    covered_value_below_inclusive = undefined,
    covered_value_above_inclusive = undefined,
    tracking_company = undefined,
    status = undefined,
    expected_delivery_before = undefined,
    expected_delivery_after = undefined,
    policy_issued = undefined) {
    return http.get('/fulfillments', {
      params: {
        page,
        limit,
        order,
        order_by,
        search_text,
        filter: {
          inside_insurance_range: inside_insurance_range != null ? inside_insurance_range : undefined,
          in_eligible_country,
          created_at_before: created_at_before != null ? created_at_before : undefined,
          created_at_after: created_at_after != null ? created_at_after : undefined,
          total_price_below_inclusive: total_price_below_inclusive !== '' ? total_price_below_inclusive : undefined,
          total_price_above_inclusive: total_price_above_inclusive !== '' ? total_price_above_inclusive : undefined,
          covered_value_below_inclusive: covered_value_below_inclusive !== '' ? covered_value_below_inclusive : undefined,
          covered_value_above_inclusive: covered_value_above_inclusive !== '' ? covered_value_above_inclusive : undefined,
          tracking_company: tracking_company !== '' ? tracking_company : undefined,
          status: status !== '' ? status : undefined,
          expected_delivery_before: expected_delivery_before != null ? expected_delivery_before : undefined,
          expected_delivery_after: expected_delivery_after != null ? expected_delivery_after : undefined,
          policy_issued
        }
      }
    })
  }
}

export default new DashboardDataService()
