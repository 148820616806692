<template>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
      <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
    </div>
    <div class="tw-flex-col tw-flex tw-gap-2">
      <router-link to="signupshopify">
        <button id="login-with-shop-submit-btn" class="tw-w-full tw-text-sm tw-text-white tw-p-3 tw-rounded btn-shopify-signup-login">
          <i class="fab fa-shopify"></i>
          Signup with Shopify
        </button>
      </router-link>
      <AuthEKM label="Signup with EKM" />
      <router-link to="signupemail">
        <button id="login-with-shop-submit-btn" class="tw-w-full tw-text-sm tw-text-white tw-p-3 tw-rounded btn-email-signup-login" data-test='signup-with-email-button'>
          Signup with Email
        </button>
      </router-link>
      <span class="tw-text-center tw-text-xs tw-mt-6">If you already have an account, <router-link to="/login" class="tw-font-semibold tw-text-black" data-test="login-on-signup-page">login here.</router-link></span>
    </div>
  </div>
</template>

<script>
import AuthEKM from '@/components/auth/AuthEKM'
import { getCookie } from '@/utils/cookies.js'

export default {
  name: 'SignupCard',
  components: {
    AuthEKM
  },
  beforeMount () {
    const source = getCookie('user_source')
    if (['ekm'].includes(source)) {
      this.$router.push({ path: `/ReferralAuth/${source}` })
    }
  }
}
</script>
