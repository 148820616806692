<template>
  <NavigationBar v-bind="{ invoices: true }" />
  <div class="p-7">
    <section class="container">
      <h1>Claim Analytics</h1>
      <div v-if="claim_graphs !== undefined && claim_cards !== undefined">
        <div v-if="claim_graphs !== undefined" class="row justify-content-md-center">
          <ReportGraphs
            class="col-8"
            v-for="(graph, index) in claim_graphs"
            :key="`metrics-card-${index}`"
            :graph-data="graph"
          />
        </div>
        <div class="row justify-content-md-center">
          <MetricsCard
            v-for="(card, index) in claim_cards"
            class="col-4 m-2"
            :key="`metrics-card-${index}`"
            :figure="`${card.value}`"
            :description="card.description"
            :unit="card.unit ?? null"
            :unit-before="card.unit_before ?? null"
            :loading="true"
          />
        </div>
      </div>
      <div v-else>
        <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="mt-10">
        <h1>Shipment Analytics</h1>
        <div v-if="shipment_cards !== undefined" class="row justify-content-md-center">
          <MetricsCard
            v-for="(card, index) in shipment_cards"
            class="col-4 m-2"
            :key="`metrics-card-${index}`"
            :figure="`${card.value}`"
            :description="card.description"
            :unit="card.unit ?? null"
            :unit-before="card.unit_before ?? null"
            :loading="true"
          />
        </div>
        <div v-else>
          <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </section>
  </div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import AnalyticsService from '@/services/AnalyticsService.js'
import MetricsCard from '@/components/analytics/MetricsCard.vue'
import ReportGraphs from '@/components/analytics/ReportGraphs.vue'

export default {
  name: 'AnalyticsView',
  title: 'Anansi - Analytics',
  mixins: [],
  components: {
    NavigationBar,
    MainFooter,
    MetricsCard,
    ReportGraphs
  },
  data () {
    return {
      claim_cards: undefined,
      claim_graphs: undefined,
      shipment_cards: undefined
    }
  },

  async beforeMount () {
    const data = await AnalyticsService.fetchAnalytics()
    this.claim_cards = data.claimCards
    this.claim_graphs = data.claimGraphs
    this.shipment_cards = data.shipmentCards
  },

  methods: {}
}
</script>
