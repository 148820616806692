/* eslint-disable camelcase */
import { http } from '../http-common'

class PolicyScheduleService {
  getPolicySchedule () {
    return http.get('/policy_schedule/').then((response) => {
      return response.data.rendered_html
    }).catch(() => {
      // add log here
      return undefined
    })
  }
}

export default new PolicyScheduleService()
