<template>
  <div class="tw-h-screen tw-flex-col tw-flex tw-items-center tw-justify-end">
    <Signup/>
    <FooterAuth/>
  </div>
</template>

<script>
import Signup from '@/components/auth/SignupShopifyInput.vue'
import FooterAuth from '@/components/auth/Footer.vue'

export default {
  name: 'SignUpShopify',
  components: {
    Signup, FooterAuth
  }
}
</script>
