<script setup>
import { onMounted, onBeforeMount } from 'vue'
import FooterAuth from '@/components/auth/Footer.vue'
import AuthEKM from '@/components/auth/AuthEKM'
import { setPersistentCookie, deletePersistentCookie } from '@/utils/cookies.js'

const props = defineProps({ source: { type: String } })
onMounted(() => { setPersistentCookie('user_source', props.source.toLowerCase()) })
onBeforeMount(() => { if (!['ekm'].includes(props.source.toLowerCase())) { this.$router.push({ path: '/login' }) } })
function deleteCookie () { deletePersistentCookie('user_source') }
</script>

<template>
  <section class="tw-h-screen tw-flex-col tw-flex tw-items-center tw-justify-end">
    <div class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
        <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
      </div>
      <AuthEKM v-if="source == 'ekm'" label="Sign in with EKM" />
        <h5 class="tw-text-center tw-mt-4 tw-text-xs tw-underline"><router-link to="/login" @click="deleteCookie" class="text-mute-gray">Other options</router-link></h5>
      </div>
    <FooterAuth/>
  </section>
</template>
