<template>
    <Reset :token="token" />
    <FooterAuth/>
</template>

<script>
// @ is an alias to /src
import Reset from '@/components/auth/ResetPassword.vue'
import FooterAuth from '@/components/auth/Footer.vue'

export default {
  name: 'ResetPassword',
  components: {
    Reset, FooterAuth
  },
  props: ['token']
}
</script>
