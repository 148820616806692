<script setup>
import { useOnboardingStore } from '@/stores/onboarding'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
const onboardingStore = useOnboardingStore()
const router = useRouter()

const isLastStep = () => {
  return onboardingStore.stepsInfo.length === onboardingStore.currentStepIndex
}

const handleNextStep = async () => {
  if (isLastStep()) handleCompleteOnboarding()
  else {
    onboardingStore.checkValidations()
    if (onboardingStore.currentStep.showError) return
    if (onboardingStore.currentStep.nextStepFunction) await onboardingStore.currentStep.nextStepFunction()
    onboardingStore.nextStep()
  }
}

const handleCompleteOnboarding = () => {
  onboardingStore.completeOnboarding()
}

const handleBackStep = () => {
  onboardingStore.previousStep()
}

const handleCloseOnboarding = async () => {
  router.push({ name: 'Dashboard' })
}

const buttonClass = computed(() => {
  const basicButtonClass = 'tw-px-3 tw-py-2 tw-flex tw-rounded tw-shadow-md '
  if (onboardingStore.isLoading) return basicButtonClass + 'tw-bg-gray-300 tw-text-black'

  return basicButtonClass + 'tw-bg-anansi-purple tw-text-white'
})
</script>
<template>
  <div class="tw-flex tw-justify-end tw-col-span-1">
    <div v-if="!onboardingStore.isOnboardingCompleted" class="tw-flex tw-items-center">
      <button v-if="onboardingStore.currentStepIndex !== 1" @click="handleBackStep" class="tw-mr-2 tw-font-bold">← Back</button>
      <button @click="handleNextStep"
        :disabled="onboardingStore.isLoading"
        :class="buttonClass"
      >{{ onboardingStore.isLoading ? 'Loading...' : isLastStep() ? 'Complete' : 'Continue' }}</button>
    </div>
    <div v-else>
      <button @click="handleCloseOnboarding" :class="buttonClass">Discover your dashboard</button>
    </div>
  </div>
</template>
