<template>
    <h5 class="text-center">This link is not valid.</h5>
    <p class="text-center mt-6">
        Need support? Contact us at
        <a href="mailto:hello@withanansi.com" class="text-gold-anansi .a-link" target="_blank">
            <u>hello@withanansi.com</u>
        </a>
    </p>
</template>

<script>
export default {
  name: 'LinkNotValid',
  props: {}
}
</script>
