<template>
  <div>
      <span v-html="policyScheduleHTML"></span>
  </div>
</template>
<script>
import PolicyScheduleService from '@/services/PolicyScheduleService.js'

export default {
  name: 'PolicySchedule',

  data () {
    return {
      policyScheduleHTML: ''
    }
  },

  beforeMount () {
    this.getPolicyScheduleHTML()
  },

  methods: {
    getPolicyScheduleHTML () {
      return PolicyScheduleService.getPolicySchedule().then((renderedHTML) => {
        this.policyScheduleHTML = renderedHTML
      })
    }
  }
}

</script>
