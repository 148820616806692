<script setup>
import InvoiceCard from '@/components/invoices/InvoiceOverviewCard.vue'
import InvoicesDataService from '../../services/InvoicesDataService'
import { onBeforeMount, ref } from 'vue'

const actualData = ref(new Map())
const estimateData = ref(new Map())
const actualTotal = ref(0)
const estimateTotal = ref(undefined)
const requiredPaymentDay = ref(undefined)
const directDebitPaymentDay = ref(undefined)
const directDebitActive = ref(undefined)
const invoiceFirstDayPayable = ref(undefined)
const daysRemainingToPay = ref(undefined)
const hasInvoiceToPay = ref(undefined)
const invoiceToPayMonth = ref(undefined)
const invoiceToPayDueByMonth = ref(undefined)
const invoiceToPayStatus = ref(undefined)
const currentInvoiceMonth = ref(undefined)
const currentInvoiceDueByMonth = ref(undefined)

onBeforeMount(() => {
  InvoicesDataService.getEstimate().then((response) => {
    actualData.value = new Map(Object.entries(response.data.actual))
    estimateData.value = new Map(Object.entries(response.data.estimate))
    if (actualData.value.size > 0) {
      actualTotal.value = Array.from(actualData.value.values()).pop()
    }
    if (estimateData.value.size > 0) {
      estimateTotal.value = Array.from(estimateData.value.values()).pop()
    }
  })

  InvoicesDataService.getInvoiceOverviewData().then((response) => {
    requiredPaymentDay.value = response.data.required_payment_day
    directDebitPaymentDay.value = response.data.direct_debit_payment_day
    directDebitActive.value = response.data.direct_debit_active
    invoiceFirstDayPayable.value = response.data.invoice_first_day_payable
    daysRemainingToPay.value = response.data.days_remaining_to_pay
    hasInvoiceToPay.value = response.data.has_invoice_to_pay
    invoiceToPayMonth.value = response.data.latest_invoice_to_pay_month
    invoiceToPayDueByMonth.value = response.data.latest_invoice_to_pay_due_by_month
    invoiceToPayStatus.value = response.data.latest_invoice_to_pay_status
    currentInvoiceMonth.value = response.data.current_invoice_month
    currentInvoiceDueByMonth.value = response.data.current_invoice_due_by_month
  })
})
</script>

<template>
  <div class="tw-flex tw-justify-center tw-mb-4">
    <InvoiceCard
    :actualTotal="actualTotal"
    :estimateTotal="estimateTotal"
    :actualInvoiceDayData="actualData"
    :estimateInvoiceDayData="estimateData"
    :daysRemainingToPay="daysRemainingToPay"
    :requiredPaymentDay="requiredPaymentDay"
    :ddActive="directDebitActive"
    :ddPaymentDay="directDebitPaymentDay"
    :hasInvoiceToPay="hasInvoiceToPay"
    :invoiceFirstDayPayable="invoiceFirstDayPayable"
    :invoiceToPayMonth="invoiceToPayMonth"
    :invoiceToPayDueByMonth="invoiceToPayDueByMonth"
    :invoiceToPayStatus="invoiceToPayStatus"
    :currentInvoiceMonth="currentInvoiceMonth"
    :currentInvoiceDueByMonth="currentInvoiceDueByMonth"/>
  </div>
</template>
