import { http } from '../http-common'

class ContractDataService {
  /*
    ContractDataService is in charge of doing the API request to ISS for the Contract details
  */

  // Get OnBoarding Status
  getContractDetails () {
    return http.get('/contract/')
  }
}

export default new ContractDataService()
