/* eslint-disable camelcase */
import { http } from '../http-common'

class AccountDataService {
  /*
    AccountDataService is in charge of doing the API request to ISS for the My Account view
  */

  // Return the data necessary to the My Account view.
  getAccount () {
    return http.get('/account/')
  }

  // Pause the insurance of orders.
  pauseInsurance () {
    return http.post('/pause_insurance/')
  }

  // Delete account
  deleteAccount () {
    return http.post('/delete_account/', {
      delete: true
    })
  }

  // Change password
  changePassword (old_password, new_password) {
    return http.post('/change_password/', {
      old_password,
      new_password
    })
  }
}

export default new AccountDataService()
