<script setup>
import ManualShipmentService from '@/services/ManualShipmentService.js'
import TheModal from '@/components/commons/TheModal.vue'
import FileSelector from './FileSelector.vue'
import { ref } from 'vue'
const props = defineProps({
  fileSizeLimit: {
    type: Number,
    default: 20
  },
  fileSizeUnit: {
    type: String,
    default: 'MB'
  },
  supportedFileTypes: {
    type: Array,
    default: () => ['csv']
  },
  fileTypeNames: {
    type: Array,
    default: () => ['Shipments', 'Items']
  }
})

// File data
const selectedFiles = ref({
  file1: undefined,
  file2: undefined
})
const clearFiles = ref(false)
const fileUploaded = ref(false)
const uploading = ref(false)

// Validation
const validation = ref({
  file1: {
    sizeOk: undefined,
    typeOk: undefined,
    mistakes: []
  },
  file2: {
    sizeOk: undefined,
    typeOk: undefined,
    mistakes: []
  }
})

const showModal = ref(false)

const resetValidationData = (theFileToReset) => {
  validation.value[theFileToReset].sizeOk = undefined
  validation.value[theFileToReset].typeOk = undefined
  validation.value[theFileToReset].mistakes = []
}

const validateFile = (theFileToValidate) => {
  resetValidationData(theFileToValidate)
  // Convert from bytes to the unit of FileSizeLimit
  const file = selectedFiles.value[theFileToValidate]
  const fileExtension = file.name.split('.').pop().toLowerCase()
  if (!props.supportedFileTypes.includes(fileExtension)) {
    validation.value[theFileToValidate].typeOk = false
    validation.value[theFileToValidate].mistakes.push(`File type is not supported. Supported file types are ${props.supportedFileTypes.join(', ')}`)
  } else {
    validation.value[theFileToValidate].typeOk = true
  }
  let fileSize = file.size
  if (props.fileSizeUnit === 'MB') {
    fileSize = fileSize / (1024 * 1024)
  } else if (props.fileSizeUnit === 'KB') {
    fileSize = fileSize / 1024
  }
  if (fileSize > props.fileSizeLimit) {
    validation.value[theFileToValidate].sizeOk = false
    validation.value[theFileToValidate].mistakes.push(`File too large. Max file size is ${props.fileSizeLimit} ${props.fileSizeUnit}`)
  } else {
    validation.value[theFileToValidate].sizeOk = true
  }
}

const clearSelectedFiles = () => {
  selectedFiles.value.file1 = undefined
  selectedFiles.value.file2 = undefined
  clearFiles.value = true
}

const uploadFiles = async () => {
  uploading.value = true
  fileUploaded.value = await ManualShipmentService.uploadShipmentFiles([selectedFiles.value.file1, selectedFiles.value.file2])
  uploading.value = false
  showModal.value = true
}

const handleModalClose = () => {
  showModal.value = false
  clearSelectedFiles()
  fileUploaded.value = false
  uploading.value = false
}

const handleFileSelected = (file, fileSelected) => {
  clearFiles.value = false
  selectedFiles.value[fileSelected] = file
  validateFile(fileSelected)
}

const validationOk = () => {
  return validation.value.file1.sizeOk && validation.value.file1.typeOk && validation.value.file2.sizeOk && validation.value.file2.typeOk
}

const uploadButtonEnabled = () => {
  return selectedFiles.value.file1 && selectedFiles.value.file2 && !uploading.value && validationOk()
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-1/2
              tw-border-2 tw-rounded tw-p-8">
      <FileSelector :fileTypeName="fileTypeNames[0]" :uploading="uploading" @file-selected="handleFileSelected($event, 'file1')"
                    :clearFile="clearFiles"/>
      <p
        v-for="mistake in validation.file1.mistakes" :key="mistake"
        class="tw-text-red-600"
      >
          {{ mistake }}
      </p>
      <FileSelector :fileTypeName="fileTypeNames[1]" :uploading="uploading" @file-selected="handleFileSelected($event, 'file2')"
                    :clearFile="clearFiles"/>
      <p
        v-for="mistake in validation.file2.mistakes" :key="mistake"
        class="tw-text-red-600"
      >
        {{ mistake }}
      </p>
      <p class="tw-font-light tw-text-sm tw-my-0">Supported file types: {{ supportedFileTypes.join(', ') }}</p>
      <p class="tw-font-light tw-text-sm tw-my-0">Max file size: {{ fileSizeLimit }} {{ fileSizeUnit }}</p>
    <button
      @click="uploadFiles()"
      :disabled="!uploadButtonEnabled()"
      :class="[uploadButtonEnabled() ? 'tw-bg-anansi-purple' : 'tw-bg-anansi-gray',
             'tw-w-48 tw-h-8 tw-mt-4 tw-text-white tw-rounded']"
      >
        Upload
      </button>
  </div>
  <TheModal :show="showModal" size='l' header_position='upload-center' body_position='upload-center' footer_position='center' @close="handleModalClose()" :custom_button_style="true">
    <template #header v-if="fileUploaded">
      <h3>📦   Your files were uploaded successfully!   🎉</h3>
    </template>
    <template #header v-else>
      <h3>❌  There was an error uploading the files  ❌</h3>
    </template>
    <template #body v-if="fileUploaded">
      <p style="font-size: 12px;">We're now processing "{{ selectedFiles.file1.name }} and {{ selectedFiles.file2.name }}"</p>
      <div class="row my-5">
        <p class="text-center">Please, remember that file processing can take a few hours. After that, you'll be able to see the shipments in your dashboard.</p>
      </div>
    </template>
    <template #body v-else>
      <div class="tw-flex tw-flex-col tw-items-center">
        <p>There was an error during your file upload. Please, try again.</p>
      </div>
    </template>
  </TheModal>
</template>
