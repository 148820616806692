<script setup>
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.js'
const props = defineProps(['actualInvoiceDayData', 'estimateInvoiceDayData'])
const fullConfig = resolveConfig(tailwindConfig)

function getDaysInCurrentMonth () {
  const today = new Date()
  const month = today.getMonth()
  const year = today.getFullYear()
  const date = new Date(year, month, 1)
  const days = []
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return days
}

function isoDateTimeStringToDateString (isoDateTimeString) {
  return isoDateTimeString.split('T')[0]
}

function convertMapKeysToDateStrings (map) {
  const newMap = new Map()
  map.forEach((value, key) => {
    newMap.set(isoDateTimeStringToDateString(key), value)
  })
  return newMap
}

function padData (map) {
  // Pad the data with nulls so the tooltip works correctly
  const dataWithDateStringKeys = convertMapKeysToDateStrings(map)
  const days = getDaysInCurrentMonth()
  const paddedData = new Map()
  days.forEach((day) => {
    const dayString = isoDateTimeStringToDateString(day.toISOString())
    if (dataWithDateStringKeys.has(dayString)) {
      paddedData.set(dayString, dataWithDateStringKeys.get(dayString))
    } else {
      paddedData.set(dayString, null)
    }
  })
  return paddedData
}

const formatSeries = () => {
  if (props.actualInvoiceDayData.size === 0 || props.estimateInvoiceDayData.size === 0) {
    return []
  }
  const actualPadded = padData(props.actualInvoiceDayData)
  const estimatePadded = padData(props.estimateInvoiceDayData)
  return [
    {
      name: 'Actual',
      data: Array.from(actualPadded.entries())
    },
    {
      name: 'Estimate',
      data: Array.from(estimatePadded.entries())
    }
  ]
}

const chartOptions = {
  chart: {
    type: 'area',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'dd',
      style: {
        colors: fullConfig.theme.colors['anansi-gray']
      }
    },
    axisBorder: {
      show: !(props.actualInvoiceDayData.size === 0 || props.estimateInvoiceDayData.size === 0)
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    show: false
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  colors: [
    fullConfig.theme.colors['anansi-purple'],
    fullConfig.theme.colors['anansi-light-gray']
  ],
  grid: {
    show: false
  },
  noData: {
    text: 'No estimate available'
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value === null ? value : '£' + value
      }
    }
  }
}
</script>

<template>
  <apexchart height="150" :options="chartOptions" :series="formatSeries()"></apexchart>
</template>
