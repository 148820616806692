<template>
  <div class="col-12 px-0">
    <select
      class="col-12 px-0 btn btn-outline btn-options2 dropdown-toggle border-btn-options padding-btn-options pl-4"
      style="border-radius: .25rem;"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
      data-test="select-claim-type"
    >
      <option value="" default disabled>Select the reporter</option>
      <option v-for="(label, val) in REPORTER" :key="val" :value="val" >{{label}}</option>
    </select>
  </div>
</template>

<script>

const REPORTER = {
  CONSUMER: 'Consumer',
  COURIER: 'Courier',
  OTHER: 'Other'
}

export default {
  props: {
    modelValue: {
      type: String
    }
  },
  data () {
    return {
      REPORTER
    }
  }
}

</script>

<style>
select {
  /* Hide the select's arrow for Firefox and Chrome */
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}
</style>
