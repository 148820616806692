<script setup>
import { SelectRoot, SelectContent, SelectViewport, SelectItem, SelectTrigger, SelectItemText, SelectValue } from 'radix-vue'
import { computed } from 'vue'

const props = defineProps({
  country: {
    type: String
  },
  showError: {
    type: Boolean
  },
  disabled: {
    type: Boolean
  }
})

const emit = defineEmits(['on-select'])

const selectCountry = (country) => {
  return emit('on-select', country)
}

const options = [
  'Wales',
  'England',
  'Scotland',
  'Great Britain',
  'United Kingdom',
  'Northern Ireland'
]

const defaultCountry = computed(() => props.country)

const showErrorMessage = computed(() => props.showError)

const isDisabled = computed(() => props.disabled)

const labelClass = computed(() => {
  let labelClassText = 'tw-block tw-text-sm tw-font-bold tw-mb-2 '
  if (showErrorMessage.value) labelClassText += 'tw-text-red-500 '

  return labelClassText
})

const inputClass = computed(() => {
  let inputClassText = 'tw-w-full tw-py-2 tw-px-3 tw-h-12 tw-border tw-rounded  tw-drop-shadow-lg tw-text-left tw-bg-white '
  if (showErrorMessage.value) inputClassText += ' tw-border-red-500 tw-border-2'
  if (isDisabled.value) inputClassText += ' tw-bg-gray-200'

  return inputClassText
})

</script>
<template>
  <div>
    <label
      :class="labelClass"
      for="country_name"
    >
      Country*
    </label>
    <SelectRoot @update:modelValue="selectCountry" :modelValue="defaultCountry">
      <SelectTrigger
        :class="inputClass"
        :disabled="isDisabled"
      >
        <SelectValue class="tw-text-gray-400" placeholder="Select Country"/>
      </SelectTrigger>
      <SelectContent
        class="tw-bg-white tw-rounded"
        position="popper"
        side="bottom"
      >
        <SelectViewport class="tw-p-1 tw-max-h-[min(var(--radix-select-content-available-height),240px)] tw-w-[--radix-select-trigger-width] tw-border">
            <SelectItem
              v-for="(option, index) in options"
              :key="index"
              class="tw-py-4 tw-flex tw-items-center tw-h-[25px] tw-pr-[35px] tw-pl-[25px] hover:tw-bg-gray-200 data-[highlighted]:tw-bg-gray-200 tw-duration-300 tw-ease-in-out tw-transition"
              :value="option"
            >
              <SelectItemText>
                {{ option }}
              </SelectItemText>
            </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectRoot>
  </div>
</template>
