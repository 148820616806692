<template>
    <span
        v-if="status === 'OPEN'"
        class="badge badge-primary"
        data-toggle="tooltip"
        data-placement="right"
        title='Invoice is "open" to include new parcel policies premium until the end of the month'
        data-test="invoices-status-badge"
        >Open</span
    >
    <span
        v-if="status === 'READY_FOR_PAYMENT'"
        class="badge badge-primary"
        data-toggle="tooltip"
        data-placement="right"
        title="Invoice is awaiting payment"
        data-test="invoices-status-badge"
        >Unpaid</span
    >
    <span
        v-if="status === 'PAYMENT_STARTED'"
        class="badge badge-primary"
        data-test="invoices-status-badge"
        >Unpaid</span
    >
    <span
        v-if="status === 'PAYMENT_PENDING'"
        class="badge badge-primary"
        data-toggle="tooltip"
        data-placement="right"
        title="Payment is being processed by the payment processor"
        data-test="invoices-status-badge"
        >Payment Processing</span
    >
    <span
        v-if="status === 'CLOSED_UNPAID'"
        class="badge badge-primary"
        data-toggle="tooltip"
        data-placement="right"
        title="Payment period has been exceeded and invoice has not been paid"
        data-test="invoices-status-badge"
        >Unpaid/ in debt</span
    >
    <span
        v-if="status === 'CLOSED_PAID'"
        class="badge badge-success"
        data-toggle="tooltip"
        data-placement="right"
        title="Invoice has been paid"
        data-test="invoices-status-badge"
        >Paid</span
    >
</template>

<script>
export default {
  name: 'InvoiceStatusBadges',
  props: {
    status: String
  }
}
</script>
