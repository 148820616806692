const addressAsSingleString = function (addressData) {
  let orderedAddressFields = [
    addressData.address_line_1,
    addressData.address_line_2,
    addressData.locality,
    addressData.region,
    addressData.postal_code,
    addressData.country
  ]
  orderedAddressFields = orderedAddressFields.filter(x => x)
  return orderedAddressFields.join(', ')
}

export default addressAsSingleString
