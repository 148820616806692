<template>
  <div colspan="9" style="padding:0rem 0rem 0rem 0rem">
    <div :class="type === 'warning' ? 'message-box-danger' : 'message-box-golden'"  class="box-center">
      <div class="flex-item">
        <slot name="text" />
      </div>
      <div class="flex-item">
      <button v-if="editable" @click="buttonClick()" data-test='table-message-button' type="button" class="btn btn-secondary-purple btn-large mr-auto size-m ml-auto">Edit</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TableMessage',
  props: {
    type: String,
    editable: Boolean
  },
  methods: {
    buttonClick () {
      this.$emit('buttonClick')
    }
  }
}
</script>
<style scoped>
  .box-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
  }
  .flex-item:nth-child(1) {
    flex: 1 1 auto;
    align-self: auto;
    text-align: center;
    }

.flex-item:nth-child(2) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    }
</style>
