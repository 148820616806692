<template>
  <div class="col-12 col-lg-10 mx-auto card shadow-lg mt-7 p-0">
    <div class="container mx-auto my-12">
    <div class="col-10 mx-auto">
        <div class="mx-9" id="shopify-login">
          <img
            class="d-block mx-auto mb-1 mt-3 mb-6"
            style="padding-bottom: 8px; height: 130px; margin-bottom: 20px"
            src="@/assets/img/anansi/insurance_by_anansi.png"
            alt="off course"
          />
          <form
            v-if="!passwordReset"
            class="w-100 d-block mx-auto"
            id="login-with-shop-form"
            @submit.prevent="submit"
          >
            <h5 class="text-left mt-6 mb-3">Enter your new password:</h5>
            <p class="text-gold-anansi">
              Remember, a good password should have at least 8 digits, numbers
              and symbols.
            </p>
            <label for="password">New Password</label>
            <input
              v-model="password"
              class="form-control required mb-6"
              required
              type="password"
              id="password"
              name="password"
              placeholder="Password"
            />

            <label for="repeatedPassword">Confirm new Password</label>
            <input
              v-model="repeatedPassword"
              class="form-control required mb-6"
              required
              type="password"
              id="repeatedPassword"
              name="repeatedPassword"
              placeholder="Password"
            />
            <p v-if="showpasswordMismatchMessage" class="text-center" style="color: red">
              Your passwords do not match
            </p>

            <button
              id="login-with-shop-submit-btn"
              class="btn btn-primary btn-email-signup-login w-100 mt-1"
              type="submit"
              :disabled="submitting"
            >
              <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="submitting" class="sr-only">Loading...</span>
              <span v-else>Create new password</span>
            </button>
          </form>
          <div v-else>
            <h5 class="text-center mt-6 mb-3">New password set! 🎉</h5>
            <p class="text-center mb-3">
              You're all set to start using Anansi.
            </p>
              <button
                class="btn btn-primary btn-email-signup-login w-100 mt-1"
                @click="login"
              >
                Go to dashboard
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from '@/services/auth.js'
import { useMainStore } from '@/stores'

export default {
  name: 'ResetPassword',
  data () {
    return {
      submitting: false,
      passwordReset: false,
      tokenId: this.token,
      password: '',
      repeatedPassword: '',
      showpasswordMismatchMessage: false
    }
  },
  computed: {
    passwordMismatch () {
      return this.password !== this.repeatedPassword
    }
  },
  props: ['token'],
  methods: {
    async submit () {
      const store = useMainStore()
      this.submitting = true

      if (this.passwordMismatch) {
        this.showpasswordMismatchMessage = true
        this.submitting = false
        return
      } else {
        this.showpasswordMismatchMessage = false
      }

      return Auth.resetPassword(
        this.password,
        this.repeatedPassword,
        this.tokenId
      )
        .then((response) => {
          this.submitting = false
          this.passwordReset = true
          store.setAuthInfo(response.data)
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.submitting = false
          } else if (e.response.status === 410) {
            this.submitting = false
            this.$router.go()
          } else if (e.response.status === 500) {
            this.submitting = false
            alert('Something went wrong, please try again later')
          }
        })
    },
    login () {
      this.$router.push({ path: '/auth/' })
    }
  }
}
</script>
