<script setup>
import EmailVerification from './EmailVerification.vue'

const props = defineProps(
  {
    policyholder: {
      type: Object
    }
  }
)

const { policyholder } = props
</script>

<template>
  <EmailVerification />
  <span v-if="!policyholder.is_partner_merchant">
    <p class="mb-0 my-5">
      As soon as your account has been verified, we'll send you an email confirming activation.
    </p>
  </span>
</template>
