<template>
  <NavigationBar v-bind="{account: true}"/>
  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col">
      <div class="">
        <h1 class="mb-7" data-test="my-account-page-title">My Account</h1>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Store Details</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4">
            <div class="card" v-if="account.company_information" data-test="business-information-card">
              <h4 class="m-0"> Business Information </h4>
              <span><i class="far fa-building" aria-hidden="true"></i> {{account.company_information.business_name}}</span>
              <span v-if="account.company_information.owner_name"><i class="far fa-user" aria-hidden="true"></i> {{account.company_information.owner_name}}</span>
              <span><i class="far fa-address-card" aria-hidden="true"></i> {{account.company_information.address}}</span>
            </div>
            <div v-if="'store_info' in account" class="card">
              <hr class="w-100">
              <h4 class="m-0"> Store Information </h4>
              <a v-if="account.store_info.url" target="_blank" v-bind:href="'https://' + account.store_info.url" class="btn btn-link text-left p-0">
                <i class="far fa-building" aria-hidden="true"></i> {{account.store_info.url}}
              </a>

              <a class="text-left p-0 btn btn-link" v-bind:href="'mailto:' + account.store_info.email">
                <i class="far fa-envelope" aria-hidden="true"></i>
                {{account.store_info.email}}
              </a>

              <span v-if="account.store_info.phone">
                  <i class="fas fa-phone" aria-hidden="true"></i> {{account.store_info.phone}}
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Coverage Values</h4>
            <p>
                Select the retail values of the orders you would like to insure.
            </p>
          </div>
          <div class="col-md-8 card shadow-light p-4">
            <div class="col-md-12 px-0">
              <input type="hidden" name="csrfmiddlewaretoken" value="86IdD0HTx6IMoSXl8moZpch5ViYmSIgMQRIAXQ2DLz4H98qCLnlOIE7MqoTOISPs">
              <div class="card" >
                <h4 class="m-0" :data-test="`status-${account_status.toLowerCase()}`"> Status </h4>
                <p>{{ account_status }}</p>
              </div>
              <div class="card" data-test="coverage-values-card">
                <h4 class="m-0"> Coverage Values </h4>
                <p>£{{account.min_order_value}} - £{{account.max_order_value}}</p>
              </div>
              <div class="card" data-test="excluded-courier-card">
                <h4 class="m-0"> Excluded Couriers </h4>
                <div v-if="excluded_courier_list.length > 0" data-test="excluded-courier-list"><p>{{excluded_courier_list_string}}</p></div>
                <div v-else data-test="no-excluded-couriers">
                  <p>There are currently not excluded couriers</p>
                </div>
              </div>
              <button v-if="policyholder?.can_configure_insurance" @click="() => showOpenModal = true" type="button" data-test="edit-button" class="btn btn-secondary-purple btn-large mr-auto ml-auto">Edit</button>
              <button v-if="policyholder?.can_configure_insurance" @click="() => showPauseModal = true" type="button" data-test="pause-insurance-button" class="btn-primary-pause ml-6" style="font-size: 0.875rem;">
                  Pause insurance
              </button>
              <CoverageRangeModal
                :min_order_value="account.min_order_value"
                :max_order_value="account.max_order_value"
                :excluded_courier_list="excluded_courier_list"
                :show="showOpenModal"
                @close="() => showOpenModal = false"
                @getCoverageInfo="getAccountInfo"
                @getExcludedCouriers="getExcludedCouriers"
              />
              <TheModal
                data-test="pause-insurance-modal"
                :show="showPauseModal"
                header_position='center'
                @close="() => showPauseModal = false">
                <template #header>
                    <h5>Are you sure you want to pause the insurance?</h5>
                </template>
                <template #body>
                    By pausing the insurance, all shipments from now on will not be insured. To unpause you will need to define new coverage ranges.
                </template>
                <template #footer>
                    <button @click="() => showPauseModal = false" data-test="modal-cancel-button" type="button" class="btn btn-secondary-purple mr-auto" style="width:10rem; border-radius: .25rem">Cancel</button>
                    <button @click="pauseInsurance()"  type="button" data-test="modal-pause-button" class="btn-primary-pause" style="width:10rem;">Yes</button>
                </template>
              </TheModal>
            </div>
          </div>
        </div>
        <hr class="w-80 m-5">
        <!-- PAYMENT SECTION -->
        <!-- <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Payment Information</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4">
            <form id="connectStripe" action="/billing/stripe/connect/" method="post">
              <input type="hidden" name="" value="">
              <button class="stripe-connect" type="submit">
                  <span>Connect with Stripe</span>
              </button>
            </form>
          </div>
        </div> -->
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
              <h4>Policy Schedule</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4" data-test="your-data-card">
            <div class="text-right">
              <p class="text-left p-0">
                You can view your Policy Schedule
                <a href="/#/policy" target="_blank">
                  <strong>here</strong>
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
              <h4>Your Data</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4" data-test="your-data-card">
              <div class="text-right">
                  <p class="text-left p-0">
                      We will treat your information with respect. For information about our privacy practices, visit
                      our <a target="_blank" data-test="privacy-policy-link" href="https://withanansi.com/privacy-policy/">Privacy Policy</a>.
                      To delete your account, click the Delete Account button or contact us at <a href="mailto:hello@withanansi.com">hello@withanansi.com</a>.
                  </p>
              </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Policyholder ID</h4>
          </div>
          <div class="col-md-8 card p-4 policyholder-id-container">
            <div class="tw-flex tw-items-center policyholder-id-wrapper disabled">
              <p class="tw-m-0">{{ account.policyholder_id }}</p>
            </div>
            <div class="copy-id-buttons-wrapper">
              <button :class="['policyholder-id-copy-button', isCodeCopiedToClipboard ? 'hidden' : 'visible']" @click="copyPolicyholderIDToClipboard">Copy ID</button>
              <div :class="['copy-to-clipboard-copied', isCodeCopiedToClipboard ? 'visible' : 'hidden']">Copied to clipboard!</div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Your Policy Documents</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4">
            <div class="text-right">
              <p class="text-left p-0">
                <a style="color:black; text-decoration: underline; margin-right: 50px" target="_blank" :href=wording_link>Terms and Conditions</a>
                <a style="color:black; text-decoration: underline;" target="_blank" :href=ipid_link>Insurance Product Information Document</a>
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <h4>Preferences</h4>
          </div>
          <div class="col-md-8 card shadow-light p-4">
            <div class="text-left">
              <div v-if="emailAccount">
                <button data-test="change-password-button" type="button" class="btn btn-secondary" @click="toggleChangePasswordModal()">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 mt-4">
            <span @click="toggleConfirmDeleteModal()" class="myprofile-delete-account-button">
              I want to delete my account
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TheModal :show="confirmDeleteModal" @close="() => confirmDeleteModal = false">
    <template #header>
      Are you sure?
    </template>
    <template #body>
      This action is not reversible. If you decide to go forward with this action all of your
      information
      will be deleted.
    </template>
    <template #footer>
      <button @click="deleteAccount()"  type="button" class="btn btn-primary-pause btn-large">Delete Account</button>
      <button @click="() =>confirmDeleteModal = false" type="button" class="btn btn-secondary-white btn-large">Cancel</button>
    </template>
  </TheModal>
  <TheModal data-test="change-password-modal" :show="changePasswordModal" @close="() => changePasswordModal = false">
    <template #body>
      <form @submit.prevent="submit" class="p-4 form-group needs-validation" action="javascript:;">
        <h2 class="text-center">Change your password</h2>

        <div class="form-group">
          <div v-if="!passwordRepeatCorrect">
            <div data-test="password-mismatch-message" class="alert alert-danger" role="alert">
              Password mismatch
            </div>
          </div>
          <div v-if="!correctCurrentPassword">
            <div data-test="wrong-password-message" class="alert alert-danger" role="alert">
              Your current password is wrong
            </div>
          </div>

          <label for="oldPassword">Current password:</label>
          <input v-model="oldPassword" data-test="current-password-input" class="form-control required" required type="password" id="oldPassword" name="oldPassword" placeholder=""/>

          <label for="newPassword">New password:</label>
          <input v-model="newPassword" data-test="new-password-input" class="form-control required" required type="password" id="newPassword" name="newPassword" placeholder=""/>

          <label for="newPasswordRepeat">Repeat new password:</label>
          <input v-model="newPasswordRepeat" data-test="repeat-new-password-input" class="form-control required" required type="password" id="newPasswordRepeat" name="newPasswordRepeat" placeholder=""/>
        </div>
      </form>
    </template>
    <template #footer>
      <button @click="changePassword" data-test="modal-change-password-button" type="button" class="btn-secondary-purple btn-large ml-auto" style="width:16rem;">Change password</button>
      <button @click="() =>changePasswordModal = false" type="button" class="btn-secondary-white btn-large">Close</button>
    </template>
  </TheModal>
    <div v-if="changePasswordModal" class="visible-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
        </div>
      </div>
    </div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import CoverageRangeModal from '@/components/commons/CoverageRangeModal.vue'
import PolicyholderDataService from '@/services/PolicyholderDataService.js'
import TheModal from '@/components/commons/TheModal.vue'
import MainFooter from '@/components/MainFooter.vue'
import FilterByCourierService from '@/services/FilterByCourierService.js'
import AccountDataService from '@/services/AccountDataService.js'
import ContractDataService from '@/services/ContractDataService.js'

export default {
  name: 'MyAccount',
  title: 'Anansi - My Account',
  components: {
    CoverageRangeModal,
    NavigationBar,
    MainFooter,
    TheModal
  },
  data () {
    return {
      excluded_courier_list: [],
      account: {
        min_order_value: undefined,
        max_order_value: undefined,
        has_paused_insurance: undefined,
        company_information: {},
        policyholder_id: undefined,
        store_info: {
          url: undefined,
          owner_name: undefined,
          email: undefined,
          phone: undefined,
          address: undefined,
          name: undefined
        }
      },
      showOpenModal: false,
      showPauseModal: false,
      oldPassword: undefined,
      newPassword: undefined,
      newPasswordRepeat: undefined,
      passwordRepeatCorrect: true,
      correctCurrentPassword: true,
      changePasswordModal: false,
      confirmDeleteModal: false,
      emailAccount: false,
      isCodeCopiedToClipboard: false,
      policyholder: null,
      wording_link: undefined,
      ipid_link: undefined
    }
  },
  async beforeMount () {
    await Promise.all([this.getAccountInfo(), this.getPolicyholderData(), this.getContractDocumentUrls()])
    if (!this.policyholder.can_configure_insurance) {
      this.excluded_courier_list = []
    } else {
      await this.getExcludedCouriers()
    }
  },
  computed: {
    account_status () {
      return this.account.has_paused_insurance ? 'Paused' : 'Active'
    },
    excluded_courier_list_string () {
      if (this.excluded_courier_list && this.excluded_courier_list.length > 0) {
        const names = this.excluded_courier_list.map((c) => c.name)

        return names.slice(0, -1).join(', ') + ' and ' + names.slice(-1)
      }
      return ''
    }
  },
  methods: {
    getExcludedCouriers () {
      return FilterByCourierService.getExcludedCouriers().then(response => {
        this.excluded_courier_list = response.data
      })
    },

    getAccountInfo () {
      return AccountDataService.getAccount().then(response => {
        this.account = response.data
        this.emailAccount = this.account.email_account
      })
    },

    toggleChangePasswordModal () {
      this.changePasswordModal = !this.changePasswordModal
    },

    toggleConfirmDeleteModal () {
      this.confirmDeleteModal = !this.confirmDeleteModal
    },

    pauseInsurance () {
      return AccountDataService.pauseInsurance().then(response => {
        this.$router.push({ path: '/' })
      }).catch(e => {
        console.log(e)
        throw e
      }).finally(() => {
        this.showPauseModal = false
      })
    },

    deleteAccount () {
      return AccountDataService.deleteAccount().then(response => {
        this.toggleConfirmDeleteModal()
        alert(response.data.message)
      }).catch(e => {
        alert('Something went wrong!')
        throw e
      })
    },

    changePassword () {
      this.passwordRepeatCorrect = true
      this.correctCurrentPassword = true
      if (this.newPassword && this.oldPassword && this.newPasswordRepeat) {
        if (this.newPassword !== this.newPasswordRepeat) {
          this.passwordRepeatCorrect = false
          this.newPassword = undefined
          this.newPasswordRepeat = undefined
          return
        }
        return AccountDataService.changePassword(this.oldPassword, this.newPassword).then(response => {
          this.newPassword = undefined
          this.newPasswordRepeat = undefined
          this.oldPassword = undefined
          alert('Your password was updated!')
          this.changePasswordModal = false
        }).catch(e => {
          this.correctCurrentPassword = false
          this.newPassword = undefined
          this.newPasswordRepeat = undefined
        })
      }
    },

    copyPolicyholderIDToClipboard () {
      navigator.clipboard.writeText(this.account.policyholder_id)
        .then(() => {
          this.isCodeCopiedToClipboard = true
          setTimeout(() => {
            this.isCodeCopiedToClipboard = false
          }, 5000)
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error)
        })
    },

    async getPolicyholderData () {
      const phData = await PolicyholderDataService.getPolicyholder()
      this.policyholder = phData
    },

    async getContractDocumentUrls () {
      const contract = await ContractDataService.getContractDetails()
      this.wording_link = contract.data.wording_url
      this.ipid_link = contract.data.ipid_url
    }
  }
}
</script>
