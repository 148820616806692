import axios from 'axios'
import settings from './settings/settings'
import { useMainStore } from './stores'
import { getCookie } from '@/utils/cookies.js'

const http = axios.create({
  baseURL: settings.baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

const httpPartner = axios.create({
  baseURL: settings.partnerBaseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use(
  function (config) {
    if (config.params) {
      for (const [key, value] of Object.entries(config.params)) {
        if (typeof (value) === 'object') config.params[key] = JSON.stringify(value)
      }
    }
    config.headers['X-CSRFToken'] = getCookie('csrftoken')
    // TODO: REMOVE THIS WHEN MOVING TO AWS. SIGN IS NOT NEEDED
    config.headers.Authentication = getCookie('access_token')
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// May be needed to make it work the first time you set ngrok locally
if (process.env.VUE_APP_NGROK_WARNING_HEADER === 'true') {
  http.defaults.headers['ngrok-skip-browser-warning'] = 'True'
  httpPartner.defaults.headers['ngrok-skip-browser-warning'] = 'True'
}

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403 && error.response.data === 'user_not_logged_in') {
      const store = useMainStore()
      store.setRefreshJwtError(true)
      window.location.href = process.env.VUE_APP_SOYUZ_BASE_URL + '#/logout'
    }
    return Promise.reject(error)
  })

export { http, httpPartner }
