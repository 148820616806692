<template>
<div class="container d-flex h-100 w-100 py-0">
  <div class="col justify-content-center align-self-top w-100">
    <div align="center">
      <h4 class="mt-7 col-12  text-center mb-3" id="welcomeModalStep3Title">Bank Account Information</h4>
    </div>

    <form @submit.prevent="saveBankAccount" id="welcomeModalStep3Form" class="form-group needs-validation">
        <div>
          <div class="text-left" v-if="errors.length">
                <ul class="list-group">
                  <li class="list-group-item list-group-item-danger" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
          </div>

          <h5 class="col-12 text-left" style="padding-left: 0" id="welcomeModalStep3SubTitle">Account Details</h5>
          <div class="bank-notice-warning">
            Notice:  To avoid payments not reaching your bank account due to name mismatch, please make sure that the Beneficiary Name matches the name associated with your bank account.
          </div>
          <div class="text-left" style="margin-left: 20px; margin-bottom: 40px">
            <div class="form-group mt-4">
                <input type="text" class="form-control" required="" placeholder="Beneficiary Name" v-model="beneficiary_name" :maxlength="128" data-test="bank-details-beneficiary-name">
            </div>

            <div class="form-group mt-4">
                <input title="IBAN is not valid for the United Kingdom" type="text" class="form-control" required="" placeholder="IBAN. (e.g. GB24BKEN100000315XXXXX)" v-model="iban" pattern="^GB\d{2}[A-Z]{4}\d{14}$" data-test="bank-details-iban">
            </div>

            <div class="form-group mt-4">
                <input title="The SWIFT/BIC should have 8 to 11 digits" type="text" class="form-control" required="" placeholder="SWIFT/BIC. (e.g. BKENGB2LCON)" v-model="swift" pattern="^.{8,11}" data-test="bank-details-swift">
            </div>
          </div>

          <button type="submit" class="navbar-btn btn btn-sm btn-secondary-purple w-100" data-test="add-bank-account-button">  Add bank account</button>
        </div>
    </form>
  </div>
</div>
</template>

<script>
import '@/assets/css/stripe.css'
import ClaimsPaymentDataService from '@/services/ClaimsPaymentDataService.js'
// import iban from 'iban'

export default {
  name: 'AddPaymentMethod',
  components: {
  },
  beforeMount () {
    return this.getBankAccount()
  },
  methods: {
    getBankAccount () {
      return ClaimsPaymentDataService.getBankAccounts().then(response => {
        if (response.data != null && response.data.items.length > 0) {
          this.beneficiary_name = response.data.items[0].beneficiary_name
          this.iban = response.data.items[0].iban
          this.swift = response.data.items[0].swift
          this.direct_debit = response.data.items[0].direct_debit ? 'Active' : 'Inactive'
        }
      }).catch(e => {
        console.log(e)
      })
    },
    saveBankAccount () {
      this.errors = []
      // Alternative way to validate IBAN by country Code | Leaved as reference
      // var isValidIban = iban.isValidBBAN('GB', iban.toBBAN(this.iban, ' '))
      this.direct_debit = this.direct_debit === 'Active'
      return ClaimsPaymentDataService.createBankAccounts(this.beneficiary_name, this.iban, this.swift, this.direct_debit).then(response => {
        this.$emit('paymentMethodAdded')
      })
        .catch(e => {
          console.log(e)
          let message = e.response.data.message
          if (message === undefined) {
            message = 'Something went wrong!'
          }
          this.errors.push(e.response.data.message)
        })
    }
  },
  data () {
    return {
      beneficiary_name: '',
      iban: '',
      swift: '',
      direct_debit: '',
      errors: []
    }
  },
  watch: {
    // Delete whitespace symbols from IBAN
    iban: function (val) {
      this.iban = val.replace(/\s+/g, '')
    }
  }
}
</script>
