<template>
  <div class="dropdown">
    <input
      v-model="search"
      data-test="autocomplete-input"
      :disabled="disabled"
      :placeholder="placeholder"
      ref="dropdownMenuInput"
      class="form-control-onboarding dropdown-toggle padding-btn-options"
      :style="width"
      autocomplete="false"
      type="text"
      id="dropdownMenuButton"
      @input="onFocus()"
      @focus="onFocus()"
      @blur="onBlur()"

    />
    <div class="dropdown-menu" ref="dropdownMenu" aria-labelledby="dropdownMenuButton">
      <div v-if="data && data.length" >
      <a
      v-for="element in data"
      :key="element.id"
      @click="onClickElement(element)"
      class="dropdown-item"
      :class="
        Object.keys(elementSelected).length > 0 && elementSelected.id === element.id
        ? 'active'
        : ''
      "
      >
      {{ element.name }}
      </a>
      </div>
      <div v-else >
        <div class="dropdown-item" data-test='empty-search'>
          There are no results for '{{ search }}'
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AutocompleteInput',
  props: {
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    size: { type: String, default: 'xl' },
    data: { type: Array, required: true },
    elementSelected: { type: Object, default: () => {} }
  },
  watch: {
    search: function (val) {
      this.search = val
      this.$emit('onSearchElement', val)
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    width () {
      switch (this.size) {
        case 's':
          return 'width: 25%'
        case 'm':
          return 'width: 50%'
        case 'l':
          return 'width: 75%'
        case 'xl':
          return 'width: 100%'
        default:
          return 'width: 100%'
      }
    }
  },
  methods: {
    onFocus () {
      this.$refs.dropdownMenu.className = 'dropdown-menu show'
    },
    onBlur () {
      const element = this.$refs.dropdownMenu
      setTimeout(function () {
        element.className = 'dropdown-menu'
      }, 500)
    },
    onClickElement (element) {
      this.$emit('onClickElement', element)
      this.onBlur()
      this.search = ''
      this.$refs.dropdownMenuInput.focus()
    }
  }
}
</script>
