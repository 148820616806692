<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
  fileTypeName: {
    type: String,
    default: 'shipments'
  },
  supportedFileTypes: {
    type: Array,
    default: () => ['text/csv']
  },
  uploading: {
    type: Boolean,
    default: false
  },
  clearFile: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['file-selected'])
const selectedFile = ref(undefined)

const handleFileChange = (files) => {
  selectedFile.value = files[0]
  emit('file-selected', selectedFile.value)
}

watch(() => props.clearFile, (newClearFile) => {
  if (newClearFile === true) {
    selectedFile.value = undefined
  }
})

const capitalise = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
</script>
<template>
  <div class="tw-flex tw-flex-row tw-justify-start">
    <p class="tw-pr-4 tw-pt-1 tw-w-36 tw-h-8 tw-text-left">{{ capitalise(fileTypeName) }} file</p>
    <div class="tw-w-96 tw-h-8 tw-border-solid tw-border-2 tw-border-black tw-rounded-l-md tw-flex tw-items-center">
      <span :class="[selectedFile ? 'tw-black' : 'tw-text-anansi-gray',
                'tw-w-96 tw-m-0 tw-text-nowrap tw-truncate tw-text-left tw-px-2']">
        {{ selectedFile ? selectedFile.name : "" }}
    </span>
    </div>
    <div :class="['tw-w-20 tw-h-8 tw-border-solid tw-border-y-2 tw-border-r-2 tw-border-l-0 tw-border-black tw-rounded-r-md',
                 'tw-relative',
                 uploading ? 'tw-bg-anansi-gray': 'tw-bg-anansi-purple hover:tw-bg-anansi-purple/75']">
      <input
        type="file"
        :disabled="uploading"
        class="tw-absolute tw-top-0 tw-right-0 tw-w-full tw-left-0 tw-opacity-0"
        v-on:change="handleFileChange($event.target.files)"
        :accept="supportedFileTypes"
        data-test="file-upload"
      />
      <label class="tw-text-white tw-my-1 tw-cursor-pointer">
        Browse
      </label>
    </div>
  </div>
</template>
