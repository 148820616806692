<script setup>
import { ref } from 'vue'
import Auth from '@/services/auth.js'
import { setPersistentCookie } from '@/utils/cookies.js'
import { useRouter } from 'vue-router'

const submitting = ref(false)
const email = ref('')
const password = ref('')
const router = useRouter()

async function submit () {
  submitting.value = true

  return Auth.login(email.value, password.value).then(response => {
    setPersistentCookie('user_source', 'email')
    submitting.value = false
    router.push({ path: '/auth/' })
  }).catch(error => {
    submitting.value = false
    let reason = ''
    if (error.response.status === 403) {
      reason = 'Invalid credentials'
    } else if (error.response.status === 500) {
      reason = 'Something went wrong, please try again later'
    } else if (error.response.status === 401) {
      reason = 'Unauthorized'
      router.go()
    }
    alert(reason)
  })
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
      <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
    </div>
    <form class="tw-flex tw-flex-col tw-gap-2" id="login-with-shop-form" @submit.prevent="submit">
      <input v-model="email"
        data-test='login-email-input'
        class="form-control required"
        required
        type="email" id="email" name="email" placeholder="Email"
      />
      <input v-model="password"
        data-test='login-password-input'
        class="form-control required"
        required
        type="password" id="email" name="password" placeholder="Password"
      />
      <button
        id="login-with-shop-submit-btn"
        data-test='login-submit-button'
        class="tw-w-full tw-text-sm tw-text-white tw-p-3 tw-rounded btn-email-signup-login"
        type="submit"
        :disabled="submitting"
      >
        <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="submitting" class="sr-only">Loading...</span>
        <span v-else>Log In</span>
      </button>
      <div class="tw-flex tw-justify-between tw-items-center-tw-gap-2">
        <router-link to="recoverpassword" class="tw-underline tw-text-black tw-text-xs">Forgot your password?</router-link>
        <router-link to="signup" class="tw-text-black tw-text-xs" data-test="signup-on-login-page">Sign up here.</router-link>
      </div>
    </form>
  </div>
</template>
