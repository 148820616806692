<script setup>
import { computed, onMounted, ref } from 'vue'

const emit = defineEmits(['textChanged'])
const props = defineProps({
  labelText: {
    type: String
  },
  showError: {
    type: Boolean
  },
  inputId: {
    type: String
  },
  required: {
    type: Boolean
  },
  value: {
    type: String
  }
})

const showErrorMessage = computed(() => props.required && props.showError)

const inputClass = computed(() => {
  let inputClassText = 'tw-w-full tw-py-2 tw-px-3 tw-h-12 tw-border tw-rounded tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline tw-drop-shadow-lg tw-rounded '
  if (showErrorMessage.value) inputClassText += ' tw-border-red-500 tw-border-2'

  return inputClassText
})

const labelClass = computed(() => {
  let labelClassText = 'tw-block tw-text-sm tw-font-bold tw-mb-2 '
  if (showErrorMessage.value) labelClassText += 'tw-text-red-500 '

  return labelClassText
})

const inputValue = ref('')

onMounted(() => {
  inputValue.value = props.value
})

const onInput = () => {
  emit('textChanged', inputValue.value)
}

</script>
<template>
  <div>
    <label
      :class="labelClass"
      :for="inputId"
    >
      {{ labelText }}{{ required ? '*' : '' }}
    </label>
    <input
      :class="inputClass"
      :id="inputId"
      type="text"
      v-model="inputValue"
      @input="onInput"
      :placeholder="labelText">
      <label v-if="showErrorMessage" class="tw-text-xs tw-text-red-500">This field is required</label>
  </div>
</template>
