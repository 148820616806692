<template>
  <div>
    <slot
      v-if="err || refresh_warn"
      name='error'
      v-bind:err="err"
      v-bind:vm="vm"
      v-bind:info="info"
    >
    <div data-test='error-msg' class="position-absolute w-100">
      <div class="alert w-60 text-center m-auto" :class=type_of_alert role="alert" style="z-index: 99999 !important;">
          {{ error_text }}
          <p class="mb-0">
            {{ subtitle_text }}
            <a href="mailto:hello@withanansi.com" class="h4 text-white .a-link" target="_blank">
                <u>hello@withanansi.com</u>
            </a>
          </p>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="resetError()">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
    </div>
    </slot>
    <slot></slot>
  </div>
</template>

<script>
import { useMainStore } from '../stores'

export default {
  name: 'ErrorHandling',
  props: {
    stopPropagation: Boolean
  },
  data () {
    return {
      store: useMainStore(),
      err: false,
      refresh_warn: false,
      vm: null,
      info: null
    }
  },
  errorCaptured (err, vm, info) {
    this.refresh_warn = this.store.refresh_jwt_error
    this.err = this.refresh_warn ? false : err
    this.vm = vm
    this.info = info
    return !this.stopPropagation
  },
  computed: {
    error_text () {
      if (this.err) return 'Something went wrong, please try again.'
      if (this.refresh_warn) return 'Your session has expired. Please, log in again.'

      return ''
    },
    subtitle_text () {
      if (this.err) return 'If the problem persists, contact'
      if (this.refresh_warn) return 'If you have any question, contact'

      return ''
    },
    type_of_alert () {
      if (this.err) return 'alert-danger'
      if (this.refresh_warn) return 'alert-warning'

      return ''
    }
  },
  methods: {
    resetError () {
      this.err = null
      this.refresh_warn = null
      this.store.setRefreshJwtError(false)
      this.vm = null
      this.info = null
    }
  }
}
</script>
