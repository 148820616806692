const COVERAGE_TYPES = {
  LOSS: 'LOSS',
  DAMAGE: 'DAMAGE'
}

const SOURCE_PARTNERS = {
  MINTSOFT: 'MINTSOFT',
  SHOPIFY: 'SHOPIFY',
  EKM: 'EKM',
  API: 'API'
}

const MIN_MAX_VALUE_INSURANCE_RANGE = 50
const INSURANCE_RANGE_STEP = 25
const MAX_VALUE_INSURANCE_RANGE = 25000

const SHIPMENT_STATUS_TO_LABEL = {
  PENDING: 'Parcel is not yet in transit',
  IN_TRANSIT: 'Parcel is in transit',
  DELIVERED: 'Parcel was delivered',
  LOST: 'Parcel was deemed lost',
  UNABLE_TO_TRACK: 'The tracking number is either missing or invalid. ' +
                 'Please add a valid tracking number to the order in your OMS or e-commerce platform.' +
                 ' This shipment will still be insured.',
  RETURNING_TO_SENDER: 'Parcel is returning to the sender',
  RETURNED_TO_SENDER: 'Parcel was returned to the sender',
  NOT_TRACKED: 'Parcel will not be tracked',
  CANCELLED: 'Parcel was cancelled in source',
  ERROR: "Something went wrong and we're woring to fix it"
}

const SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS = {
  PENDING: 'Awaiting Update',
  IN_TRANSIT: 'In Transit',
  DELIVERED: 'Delivered',
  LOST: 'Lost',
  UNABLE_TO_TRACK: 'Tracking Unavailable',
  RETURNING_TO_SENDER: 'Returning to sender',
  RETURNED_TO_SENDER: 'Returned to sender',
  NOT_TRACKED: 'Not tracked',
  CANCELLED_IN_SOURCE: 'Cancelled',
  CANCELLED_IN_COURIER: 'Cancelled',
  ERROR: 'Unable to process',
  NO_EVENTS_RECEIVED: 'Tracking Unavailable'
}

export {
  COVERAGE_TYPES,
  SOURCE_PARTNERS,
  MIN_MAX_VALUE_INSURANCE_RANGE,
  MAX_VALUE_INSURANCE_RANGE,
  INSURANCE_RANGE_STEP,
  SHIPMENT_STATUS_TO_LABEL,
  SHIPMENT_STATUS_TO_CUSTOMER_FACING_STATUS
}
