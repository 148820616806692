/* eslint-disable camelcase */
import { http } from '../http-common'

class OnBoardingDataService {
  /*
    OnBoardingDataService is in charge of doing the API request to ISS for the onBoarding component
  */

  // Save the selected minimum and maximum value to track
  setOrderValueRange (
    min_order_value = undefined,
    max_order_value = undefined) {
    return http.post('/order_value_range/', {
      min_order_value,
      max_order_value
    })
  }

  // Search UK company
  searchUKCompany (
    companyName = undefined) {
    return http.post('/search_uk_company/', {
      company_name: companyName
    })
  }

  // Save company information
  // TODO revisit on ANANSI-1090
  setCompanyInformation (
    publicName = undefined,
    name = undefined,
    ownerName = undefined,
    firstName = undefined,
    lastName = undefined,
    currency = undefined,
    address_1 = undefined,
    address_2 = undefined,
    postal_code = undefined,
    country = undefined,
    locality = undefined,
    premises = undefined,
    region = undefined,
    companyNumber = undefined,
    is_uk_company = undefined) {
    return http.post('/save_company_info/', {
      public_name: publicName,
      company_name: name,
      owner_name: ownerName,
      first_name: firstName,
      last_name: lastName,
      currency,
      address_1,
      address_2,
      postal_code,
      country,
      locality,
      premises,
      region,
      company_number: companyNumber,
      is_uk_company
    })
  }

  // Save the parameter that indicates that the user completed the onBoarding process.
  setConfirmedTermsAndCompleteOnboarding () {
    return http.post('/complete_onboarding/')
  }

  updatePolicyholderName (param_first_name, param_last_name) {
    const first_name = param_first_name || undefined
    const last_name = param_last_name || undefined
    return http.patch('/policyholder/', { first_name, last_name })
  }
}

export default new OnBoardingDataService()
