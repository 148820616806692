<script setup>
import settings from '@/settings/settings'
const baseURL = settings.baseURL + 'social_login/'
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
      <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
    </div>
    <div class="tw-flex-col tw-flex tw-gap-2 tw-w-full">
      <form class="" method="post" id="login-with-shop-form" v-bind:action="baseURL">
        <label for="shopid">Shopify store name</label>
        <input class="tw-border tw-p-3 tw-rounded" required type="text" id="shopid" name="shop" value=""
        pattern="^[a-z0-9-]+(\.myshopify\.com)?$" placeholder="your-shopify-store-name"/>
        <p class="text-mini tw-mt-4">*You can find your store name in your Shopify general settings.
          <span class="tw-font-semibold"><u>Please note: In order to use the Anansi app, you will need to have an active Shopify store</u></span>
        </p>
        <input type="hidden" name="social_app" value="shopify">
        <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login tw-w-full tw-mt-2" type="submit">Login</button>
      </form>
    </div>
    <div class="">
      <span class="tw-text-center tw-text-xs tw-mt-6">If you don't have an account, <router-link to="/signup" class="tw-font-semibold tw-text-black" data-test="login-on-signup-page">signup here.</router-link></span>
    </div>
  </div>
</template>

<style scoped>
.text-mini {
  font-size: 9px;
}
</style>
