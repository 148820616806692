<template>
  <Transition name="modal">
    <div v-if="show" ref="modal_mask" class="modal-mask">
      <div ref="modal_container" id="modal_container" class="modal-container" @click.stop="onModalMouseDown" :style="container_style">
        <div class="modal-header" :style="header_style">
          <slot name="header"/>
        </div>

        <div class="modal-body" :style="body_style">
          <slot name="body"/>
        </div>

        <div class="modal-footer" :style="footer_style">
          <slot name="footer">
            <button
              :class="['modal-default-button', custom_button_style ? 'btn btn-secondary-white btn-large' : '' ]"
              @click="$emit('close')"
            >Close</button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>

export default {
  name: 'TheModal',
  props: {
    show: Boolean,
    size: { type: String, default: 's-m' },
    header_position: { type: String, default: 'start' },
    body_position: { type: String, default: 'start' },
    footer_position: { type: String, default: 'start' },
    custom_button_style: { type: Boolean, default: false }
  },
  beforeMount () {
    document.body.addEventListener('mousedown', this.onModalMouseDown, false)
    document.body.addEventListener('mouseup', this.onModalMouseUp, false)
  },
  beforeUnmount () {
    document.body.removeEventListener('mousedown', this.onModalMouseDown)
    document.body.removeEventListener('mouseup', this.onModalMouseUp)
  },
  data () {
    return {
      clickTarget: null
    }
  },
  computed: {
    container_style () {
      switch (this.size) {
        case 's':
          return 'width:25%'
        case 's-m':
          return 'width:35%'
        case 'm':
          return 'width:50%'
        case 'l':
          return 'width:75%'
        case 'email-verification-modal':
          return 'width:90%; height: 90vh; background: rgba(255,255,255,0.85); display: flex; flex-direction: column; justify-content: center; align-items: center;'
        default:
          return 'width:auto'
      }
    },
    header_style () {
      switch (this.header_position) {
        case 'center':
          return 'justify-content: center'
        case 'upload-center':
          return 'justify-content: center; align-items: center; flex-direction: column;'
        case 'end':
          return 'justify-content: flex-end'
        default:
          return ''
      }
    },
    body_style () {
      switch (this.body_position) {
        case 'center':
          return 'justify-content: center'
        case 'upload-center':
          return 'justify-content: center; display: flex; flex-direction: column; align-items: center; margin: 0;'
        case 'end':
          return 'justify-content: flex-end'
        case 'email-verification-modal':
          return 'justify-content: center; display: flex; flex-direction: column;'
        default:
          return ''
      }
    },
    footer_style () {
      switch (this.footer_position) {
        case 'center':
          return 'justify-content: center; display: flex;'
        case 'end':
          return 'justify-content: flex-end'
        default:
          return ''
      }
    }

  },
  methods: {
    onModalMouseDown (event) {
      this.clickTarget = event.target
    },

    onModalMouseUp () {
      if (this.clickTarget === this.$refs.modal_container || this.clickTarget === this.$refs.modal_mask) this.$emit('close')
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: auto;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  border-bottom: 0px
}

.modal-footer {
  border-bottom: 0px
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  padding: 10px
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
