function capitalizeString (s) {
  const lower = s.toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.slice(1)
}

function roundWithDecimalPlaces (value, decimalPlaces) {
  return Number.parseFloat(value).toFixed(decimalPlaces)
}

export { capitalizeString, roundWithDecimalPlaces }
