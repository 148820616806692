<script setup>
import { MIN_MAX_VALUE_INSURANCE_RANGE, MAX_VALUE_INSURANCE_RANGE } from '@/utils/variables'
import { nextTick } from 'vue'

const props = defineProps(['minValue', 'maxValue'])
const emit = defineEmits(['update:minValue', 'update:maxValue'])

const updateMin = async e => {
  const newValue = Number(e.target.value)

  if (newValue > MIN_MAX_VALUE_INSURANCE_RANGE) {
    emit('update:minValue', 0) // for unknown reasons, emit is ignored if multiple same value updates are emitted.
    await nextTick()
    emit('update:minValue', MIN_MAX_VALUE_INSURANCE_RANGE)
  } else if (newValue > props.maxValue) {
    emit('update:minValue', newValue)
    emit('update:maxValue', newValue)
  } else {
    emit('update:minValue', newValue)
  }
}

const updateMax = async e => {
  const newValue = Number(e.target.value)

  if (newValue > MAX_VALUE_INSURANCE_RANGE) {
    emit('update:maxValue', 0) // for unknown reasons, emit is ignored if multiple same value updates are emitted.
    await nextTick()
    emit('update:maxValue', MAX_VALUE_INSURANCE_RANGE)
  } else {
    emit('update:maxValue', newValue)
  }
}

const checkRange = () => {
  if (props.maxValue < props.minValue) {
    emit('update:minValue', props.maxValue)
  }
}
</script>
<template>
  <div class="mt-7 tw-grid tw-grid-cols-2 tw-gap-4">
    <div>
      <h4 class="tw-font-bold mb-0">Choose your minimum</h4>
      <h4 class="tw-font-bold mt-1">From £0 to £50</h4>
      <span class="tw-mr-1 tw-font-bold">£</span>
      <input
        class="tw-mr-3 tw-w-1/2 tw-border tw-rounded tw-px-1.5 tw-py-1 tw-drop-shadow-lg"
        type="number"
        min="0"
        :value="minValue"
        @input="updateMin"
      />
    </div>
    <div>
      <h4 class="tw-font-bold mb-0">Choose your maximum</h4>
      <h4 class="tw-font-bold mt-1">From £0 to £25,000</h4>
      <span class="tw-mr-1 tw-font-bold">£</span>
      <input
        class="tw-border tw-rounded tw-w-1/2 tw-px-1.5 tw-py-1 tw-drop-shadow-lg remove-arrow"
        type="number"
        :value="maxValue"
        @input="updateMax"
        @change="checkRange">
    </div>
  </div>
</template>
