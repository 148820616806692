<script setup>
import { computed } from 'vue'
import InputField from '@/components/commons/InputField.vue'
import CompanySelector from '@/components/commons/CompanySelector.vue'
import CountrySelector from '@/components/commons/CountrySelector.vue'
import { usePolicyholderStore } from '@/stores/policyholder'
import { useOnboardingStore } from '@/stores/onboarding'

const policyholderStore = usePolicyholderStore()
const onboardingStore = useOnboardingStore()

const updateField = (field, text) => {
  if (typeof (onboardingStore.currentStep.validations[field]) === 'boolean') onboardingStore.switchValidation(field, (!!text))
  policyholderStore.updateField(field, text)
}

onboardingStore.switchValidation('first_name', (!!policyholderStore.first_name))
onboardingStore.switchValidation('last_name', (!!policyholderStore.last_name))
onboardingStore.switchValidation('company_info', (!!policyholderStore.company.company_name))
onboardingStore.switchValidation('company_country', (!!policyholderStore.company.address.country))

const setCompany = (company) => {
  if (typeof (onboardingStore.currentStep.validations.company_info) === 'boolean') onboardingStore.switchValidation('company_info', (!!company.company_name))
  if (typeof (onboardingStore.currentStep.validations.company_country) === 'boolean') onboardingStore.switchValidation('company_country', (!!company.address.country))

  policyholderStore.setCompany(company)
}

const setCountry = (country) => {
  if (typeof (onboardingStore.currentStep.validations.company_country) === 'boolean') onboardingStore.switchValidation('company_country', (!!country))

  policyholderStore.setCountry(country)
}

const company = computed(() => {
  return policyholderStore.company
})

</script>

<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-w-full">
    <div>
      <InputField
        :show-error="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['first_name']"
        label-text="First Name"
        input-id="first_name"
        :value="policyholderStore.first_name"
        @textChanged="(text) => updateField('first_name', text)"
        required/>
    </div>
    <div>
      <InputField
        :show-error="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['last_name']"
        label-text="Last Name"
        input-id="last_name"
        :value="policyholderStore.last_name"
        @textChanged="(text) => updateField('last_name', text)"
        required/>
    </div>
    <div class="tw-col-span-2">
      <CompanySelector
        :show-error="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['company_info']"
        @select-company="(value) => setCompany(value)"/>
    </div>
    <div class="tw-col-span-2">
      <CountrySelector v-if="company.company_name"
        :disabled="policyholderStore.company_has_default_country"
        :country="company.address.country"
        :show-error="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['company_country']"
        @on-select="(country) => setCountry(country)"/>
    </div>
    <hr class="tw-col-span-2 mt-2">
    <div class="tw-col-span-2">
      <h3 v-if="company.company_name">Your business details</h3>
    </div>
    <div class="tw-col-span-2 tw-text-sm">
      <p v-if="company.company_name" class="mb-4"><span class="tw-font-bold">Legal Business Name:</span> {{ company.company_name }}</p>
      <p v-if="company.company_name" class="my-1"><span class="tw-font-bold">Country:</span> {{ company.address.country ? company.address.country : '-' }}</p>
      <p v-if="company.address.address_line_1" class="my-1"><span class="tw-font-bold">Address 1:</span> {{ company.address.address_line_1 }}</p>
      <p v-if="company.address.address_line_2" class="my-1"><span class="tw-font-bold">Address 2:</span> {{ company.address.address_line_2 }}</p>
    </div>
    <div v-if="company.address.premises" class=" tw-text-sm"><span class="tw-font-bold">Building Number(s):</span> {{  company.address.premises }}</div>
    <div v-if="company.address.postal_code" class=" tw-text-sm"><span class="tw-font-bold">Post Code:</span> {{ company.address.postal_code }}</div>
    <div v-if="company.address.county" class=" tw-text-sm"><span class="tw-font-bold">County:</span> {{ company.address.county }}</div>
    <div v-if="company.address.locality" class=" tw-text-sm"><span class="tw-font-bold">City:</span> {{ company.address.locality }}</div>
  </div>
</template>
