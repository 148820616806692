<template>
  <TheModal :show="show" size='l' header_position='center' data-test='coverage-range-modal'>
    <template #header>
      <h3>Insurance Settings</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </template>
    <template #body>
      <label><h4 class="mt-3">Coverage Range</h4> </label>
      <p>Select the retail values of the parcels you would like to insure:</p>
      <div class="row mx-5 mb-7">
        <CoverageRangeInput
          v-model:min-value="new_min_insurance_value"
          v-model:max-value="new_max_insurance_value"
        />
      </div>
      <div>
        <div>
          <label><h4 class="mt-3">Excluded Couriers</h4> </label>
          <p>Select any courier(s) you wish to exclude. Once selected, shipments from these couriers <strong>will not be insured</strong>.</p>
          <autocomplete-input
            data-test="exclude-courier-input"
            size="m"
            :data="excludedCourierSearch"
            :elementSelected="selectedCourier"
            placeholder="Type courier name..."
            @onClickElement="selectCourierName"
            @onSearchElement="searchCourier"
          />
        </div>
        <div v-if="excluded_courier_list_copy.length === 0" data-test="courier-list-empty" class="resource-tables-empty-box mt-4 text-center text-gray">
          <p>There are currently not excluded couriers</p>
          <p>To exclude a courier and prevent shipments from that courier from being insured, please use the search box above</p>
        </div>
        <div class="mt-5 mb-5">
          <chip v-for="courier in excluded_courier_list_copy"
            :key="courier.id"
            :data-test="`courier-list-item-name-${courier.name}`"
            :text="courier.name"
            :element="courier"
            @onClose="removeExcludedCourier"/>
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="saveChanges" :disabled="is_submitting" data-test="range-modal-save-button" type="button" class="btn btn-secondary-purple btn-large ml-auto">Save Changes</button>
      <button @click="() =>$emit('close')" type="button" class="btn btn-secondary-white btn-large">Cancel</button>
    </template>
  </TheModal>
</template>

<script>
import TheModal from '@/components/commons/TheModal.vue'
import OnBoardingDataService from '@/services/OnBoardingDataService.js'
import FilterByCourierService from '@/services/FilterByCourierService.js'
import AutocompleteInput from './AutocompleteInput.vue'
import Chip from './Chip.vue'
import CoverageRangeInput from '../CoverageRangeInput.vue'

export default {
  name: 'CoverageRangeModal',
  components: {
    TheModal,
    AutocompleteInput,
    Chip,
    CoverageRangeInput
  },
  props: {
    show: Boolean,
    min_order_value: Number,
    max_order_value: Number,
    excluded_courier_list: { type: Array, default: () => [] }
  },
  watch: {
    excluded_courier_list: {
      handler (val) {
        if (val && val !== this.excluded_courier_list_copy) {
          this.excluded_courier_list_copy = JSON.parse(JSON.stringify(this.excluded_courier_list))
        }
      },
      immediate: true
    },
    min_order_value: {
      handler (val) {
        if (val !== this.new_min_insurance_value) this.new_min_insurance_value = val
      },
      immediate: true
    },
    max_order_value: {
      handler (val) {
        if (val !== this.new_max_insurance_value) this.new_max_insurance_value = val
      },
      immediate: true
    }
  },
  data () {
    return {
      is_submitting: false,
      selectedCourier: {},
      excludedCourierSearch: [],
      excluded_courier_list_copy: [],
      new_min_insurance_value: this.min_order_value,
      new_max_insurance_value: this.max_order_value
    }
  },
  computed: {
    courier_list_ids () {
      return this.excluded_courier_list.map((c) => c.id)
    },
    courier_list_copy_ids () {
      return this.excluded_courier_list_copy.map((c) => c.id)
    },
    couriers_to_exclude_ids () {
      return this.excluded_courier_list_copy.filter((c) => !this.courier_list_ids.includes(c.id)).map((c) => c.id)
    },
    couriers_to_include_ids () {
      return this.excluded_courier_list.filter((c) => !this.courier_list_copy_ids.includes(c.id)).map((c) => c.id)
    }
  },
  methods: {
    searchCourier (search) {
      return FilterByCourierService.searchCourier(search).then((response) => {
        this.excludedCourierSearch = response.data
      })
    },
    selectCourierName (element) {
      if (this.excluded_courier_list_copy.filter((c) => c.id === element.id).length > 0) return
      this.excluded_courier_list_copy.push(element)
    },
    removeExcludedCourier (element) {
      if (this.excluded_courier_list_copy.filter((c) => c.id === element.id).length === 0) return
      this.excluded_courier_list_copy = this.excluded_courier_list_copy.filter((c) => c.id !== element.id)
    },
    addExcludedCouriers () {
      return FilterByCourierService.addExcludedCouriers(this.couriers_to_exclude_ids, this.couriers_to_include_ids)
    },
    async saveChanges () {
      if (this.is_submitting) return
      try {
        this.is_submitting = true
        await Promise.all([
          OnBoardingDataService.setOrderValueRange(this.new_min_insurance_value, this.new_max_insurance_value),
          this.addExcludedCouriers()
        ])
        this.$emit('getCoverageInfo')
        this.$emit('getExcludedCouriers')
      } catch (e) {
        console.log(e)
        alert('Something went wrong')
      } finally {
        this.is_submitting = false
        this.$emit('close')
      }
    }
  }
}
</script>
