<script setup>
import { ref, onMounted } from 'vue'
import Chart from 'primevue/chart'

const props = defineProps({
  graphData: {
    type: Object,
    default: () => {}
  }
})

const ready = ref(false)
onMounted(() => {
  chartData.value = setChartData()
  chartOptions.value = setChartOptions()
  ready.value = true
})

const chartData = ref()
const chartOptions = ref()

const setChartData = () => {
  return {
    labels: props.graphData.labels,
    datasets: [
      {
        label: props.graphData.title_lable,
        data: props.graphData.data,
        backgroundColor: props.graphData.backgroundColor,
        borderColor: props.graphData.borderColor,
        borderWidth: 1
      }
    ]
  }
}

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue('--p-text-color')
  const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color')
  const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color')

  return {
    plugins: {
      legend: {
        labels: {
          color: textColor
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  }
}

</script>

<template>
  <div class="rounded shadow-sm h-48 mb-2 border bg-white border-gray-300 p-2 flex items-center justify-center">
    <div
      v-if="ready"
      class="card"
    >
      <Chart
        type="bar"
        class="h-46"
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>
