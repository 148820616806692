/* eslint-disable camelcase */
import { http } from '../http-common'

class ClaimsPaymentDataService {
  /*
    ClaimsPaymentDataService is in charge of doing the API request to ISS for Claim Payments configuration modal
  */

  // Returns payment bank account for a shop.
  getBankAccounts () {
    return http.get('/bank_accounts/')
  }

  // Create a claim payment bank accounts for a shop.
  createBankAccounts (
    beneficiary_name = undefined,
    iban = undefined,
    swift = undefined,
    direct_debit = undefined) {
    return http.post('/bank_accounts/create/', {
      beneficiary_name,
      iban,
      swift,
      direct_debit
    })
  }
}

export default new ClaimsPaymentDataService()
