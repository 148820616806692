function getCookie (cookieName) {
  const cookie = {}
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName]
}

function setPersistentCookie (cookieName, cookieValue) {
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 1)
  document.cookie = cookieName + '=' + cookieValue + ';path=/; expires=' + expires.toUTCString()
}

function deletePersistentCookie (cookieName) {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export { getCookie, setPersistentCookie, deletePersistentCookie }
