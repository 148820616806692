/* Functions to help with redirecting to different paths after login */

/**
 * Return True if the user can be redirected to path after logging in
 * @param {string} path The path to redirect to
 * @param {Router} router A Router instance
 * @returns True if allowed to redirect to path
 */
function allowedToRedirect (path, router) {
  const link = router.resolve(path)
  return link && Object.hasOwn(link.meta, 'canRedirectAfterLogin') && link.meta.canRedirectAfterLogin
}

/**
 * Check if a route has the redirect query parameter
 * @param {*} route A Route instance
 * @returns True if the route has redirect
 */
function hasRedirectQueryParam (route) {
  return Object.hasOwn(route, 'query') && Object.hasOwn(route.query, 'redirect')
}

export {
  allowedToRedirect,
  hasRedirectQueryParam
}
