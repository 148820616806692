<template>
<div class="col-12 col-lg-10 mx-auto card shadow-lg mt-7 p-0">
  <div class="container mx-auto my-12">
    <div class="col-10 mx-auto">
      <div class="mx-9" id="shopify-login">
        <img class="d-block mx-auto mb-1 mt-3 mb-6" style="padding-bottom: 8px; height: 130px; margin-bottom: 20px;" src="@/assets/img/anansi/insurance_by_anansi.png" alt="off course">
        <form class="w-100 d-block mx-auto" id="login-with-shop-form" @submit.prevent="submit">
          <div class="container">
            <div v-if="isInitialized">
              <div v-if="!success">
                <h5 >Enter your new password:</h5>
                <p class="text-gold-anansi">Remember, a good password should have at least 8 digits, numbers and symbols.</p>
                <label class="mt-2" for="password">Password</label>
                <input v-model="password" class="form-control required" required type="password" id="password" name="password" placeholder="Password"/>

                <br>
                <label for="confirmPassword">Confirm Password</label>
                <input v-model="confirmPassword" class="form-control required" required type="password" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password"/>

                <p v-if="statusCode == 400 ||errorText != ''" class="text-center mt-6 text-danger">{{errorText}}</p>
                <br>

                <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login w-100 mt-4" type="submit" :disabled="submitting">
                  <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-if="submitting" class="sr-only">Loading...</span>
                  <span v-else>Create New Password</span>
                </button>
                <h5 class="text-center mt-6">Don't have an account, <router-link to="/signup" class="login-signup-link" >Sign up here.</router-link></h5>
              </div>
              <div v-else>
                <br>
                <h5 class="text-center">New password set! 🎉</h5>
                <p class="text-center">You're all set to start using Anansi.</p>
                <br>
                <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login w-100 mt-4" @click="login">
                  Go to Log In
                </button>
              </div>
            </div>

            <div v-if="statusCode === 401">
              <ResendPasswordEmail
              :token="this.$route.params.token"/>
            </div>

            <div v-else-if="statusCode === 403">
              <LinkNotValid/>
            </div>

            <div v-if="loading">
              <br>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-purple-anansi" role="loading status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br>
              <p class="text-center mt-6">
                Need support? Contact us at
                <a href="mailto:hello@withanansi.com" class="text-gold-anansi .a-link" target="_blank">
                    <u>hello@withanansi.com</u>
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ApiDataService from '@/services/ApiDataService.js'
import ResendPasswordEmail from '@/components/api-ui/ResendPasswordEmail.vue'
import LinkNotValid from '@/components/api-ui/LinkNotValid.vue'
import { useMainStore } from '@/stores'

export default {
  name: 'SetPasswordAPI',
  props: {},
  components: {
    ResendPasswordEmail,
    LinkNotValid
  },
  data () {
    return {
      statusCode: undefined,
      password: '',
      confirmPassword: '',
      errorText: '',
      submitting: false,
      success: false,
      isInitialized: false,
      loading: true
    }
  },
  methods: {
    async submit () {
      this.submitting = true
      this.errorText = ''
      if (this.password !== this.confirmPassword) {
        this.errorText = 'Your passwords do not match.'
      } else {
        ApiDataService.setPasswordToken(this.$route.params.token, this.password).then(response => {
          this.success = true
        }).catch(e => {
          this.statusCode = e.response.status
          this.errorText = e.response.data.message
          if (this.statusCode !== 400) this.isInitialized = false
        })
      }
      this.submitting = false
    },
    login () {
      const store = useMainStore()
      store.resetLocalStorage()
      this.$router.push({ path: '/loginemail/' })
    },
    signup () {
      this.$router.push({ path: '/signup/' })
    },
    checkTokenStatus () {
      ApiDataService.getTokenStatus(this.$route.params.token).then(response => {
        this.statusCode = response.status
        if (this.statusCode === 200) this.isInitialized = true
      }).catch(e => {
        this.statusCode = e.response.status
      })
      this.loading = false
    }
  },
  beforeMount () {
    this.checkTokenStatus()
  }
}
</script>
