<template>
  <NavigationBar v-bind="{ claims: true }" />

  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col">
      <div>
        <div class="col-12 card shadow-light p-5 table-style">
          <div class="title-and-select-wrapper">
            <h1>New {{claimType.toLowerCase()}} claim</h1>
            <ClaimTypeSelect
              v-model="claimType"
              :has_loss_claims="is_loss_covered"
              :has_damage_claims="is_damage_covered"
              :class="[claimType ? '' : 'animated-before-selection']"
            />
          </div>
          <form
            class="form-group needs-validation row mt-8"
            v-if="claimType"
          >
            <div class="col-md-8 col-sm-10">
              <div v-if="claimType === 'LOSS' || claimType == 'DAMAGE'">
                <label class="col-12 px-0">
                  <span class="label">
                    Who reported the {{claimType == 'DAMAGE' ? 'damaged' : 'loss'}} item(s)?
                  </span>
                  <ClaimReporterSelect v-model="claimReporter"/>
                </label>
              </div>
              <div id="parcelDropdown" class="form-group text-left mb-8">
                <label for="parcel">
                  Select the Order Number of the shipment or consignment
                </label>
                <div class="dropdown">
                  <input
                    v-model="searchClaimableFulfillment"
                    placeholder="Select the order number..."
                    ref="trackingNumberDropdownMenuButtonInput"
                    class="
                      form-control-onboarding
                      btn btn-outline btn-options2
                      dropdown-toggle
                      border-btn-options
                      padding-btn-options
                      pl-4
                    "
                    autocomplete="off"
                    type="text"
                    id="trackingNumberDropdownMenuInput"
                    @focus="showOrderNumberDropdownVisibility()"
                    @blur="orderNumberDropdownVisibility = false"
                    :disabled="isParcelSelectionDisabled"
                    style="border-radius: .25rem;"
                    data-test="new-claim-order-number-input"
                  />
                  <div v-if="orderNumberDropdownVisibility" class="dropdown-menu show" ref="trackingNumberDropdownMenu" aria-labelledby="trackingNumberDropdownMenuInput">
                    <a v-if="isLoading">
                      <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                    </a>
                    <a
                      v-else-if="!matchedOrderNumbers || matchedOrderNumbers.length === 0"
                      class="dropdown-item pt-1"
                    >
                      No parcels found
                    </a>
                    <a
                      v-else
                      v-for="orderNumber in matchedOrderNumbers"
                      :key="orderNumber"
                      @mousedown="selectOrderNumber(orderNumber)"
                      class="dropdown-item pt-1"
                      data-test="claim-filtered-order-number"
                    >
                      {{ orderNumber}}
                    </a>
                  </div>
                </div>
              </div>
              <div id="itemsCheckbox" class="form-group text-left mb-8">
                <label for="parcel">
                  <h5>Select the claim items</h5>
                </label>
                <div v-if="!selectedOrderNumber"><p class="message-box-golden text-center">Your items will appear here after selecting an order in Step 2.</p></div>
                <div v-else v-for="(id, index) in Object.keys(fulfillmentItems)" v-bind:key="index">
                  <input v-model="fulfillmentItems[id].checked" type="checkbox" :id="index" :name="id" data-test="claim-fulfillment-item-checkbox">
                    <label :for="id" class="ml-2">
                      <input @input="updateSelectedQuantity(id)"
                        v-model="fulfillmentItems[id].selected_quantity"
                        min="1"
                        :max="fulfillmentItems[id].quantity"
                        type="number"
                        :id="'quantity_' + id"
                        name="quantity"
                        style="height: 25px"
                        :disabled="!fulfillmentItems[id].checked"
                      >
                      {{fulfillmentItems[id].name}}
                    </label>
                  </div>
                </div>

                <div v-if="claimType == 'DAMAGE'" class="reporter-select">
                  <label for="damage_reparable">
                    Can the damage be repaired?
                  </label>
                  <form class="reparable-radio-wrapper">
                    <div>
                      <input type="radio" name="reparable" value="YES" v-model="damage_reparable"/>
                      <label for="yes">Yes</label>
                    </div>
                    <div>
                      <input type="radio" name="reparable" value="NO" v-model="damage_reparable"/>
                      <label for="no">No</label>
                    </div>
                    <div>
                      <input type="radio" name="reparable" value="NOT_SURE" v-model="damage_reparable"/>
                      <label for="notsure">Not sure</label>
                    </div>
                  </form>
                </div>

                <div v-if="claimType == 'DAMAGE'" class="reporter-select">
                  <label for="damage_notes">
                    What’s happening with the damaged item?
                  </label>
                  <textarea
                    v-model="damage_notes"
                    type="text"
                    class="form-control required"
                    id="damage_notes"
                    required
                  />
                </div>

              <div id="originDropdown" class="form-group text-left mb-8">
                <label for="origin">
                  Enter the origin address
                </label>
                <p >Select the country from where your parcel shipped from the dropdown below</p>
                <input
                  v-model="city"
                  type="text"
                  class="form-control required mt-2"
                  id="parcel_city"
                  placeholder="Town/City"
                  required

                  data-test="claim-parcel-city"
                />
                <input
                  v-model="region"
                  type="text"
                  class="form-control required mt-2"
                  id="parcel_region"
                  placeholder="County"
                  required

                  data-test="claim-parcel-country"
                />
                <div class="tw-my-2">
                  <ClaimCountrySelector
                    v-model="selectedCountry"
                    :options="eligibleCountries"
                    :defaultOption="fulfillmentOriginCountry"
                    placeholder="Country"/>
                </div>
              </div>
              <div class="form-group text-left mb-8">
                <label>Provide a description of your claim</label>
                <p v-if="claimType == 'DAMAGE'">
                  Write a description of the damaged product along with how the damage has occurred. Example: “Smashed pottery vase by knocking off table”
                </p>
                <textarea
                  v-model="description"
                  type="text"
                  class="form-control required"
                  id="description"
                  required
                  data-test="claim-parcel-description"
                />
              </div>
              <div class="form-group text-left mb-8">
                <label for="files">
                  <span class="label">
                    <h5>Upload the following documents:</h5>
                    <ul v-if="claimType == 'DAMAGE'" class="document-list">
                      <li>Any messages or emails reporting the damage</li>
                      <li>Pictures of the damaged item(s)</li>
                      <li>If the item is being repaired, a copy of the repair invoice</li>
                    </ul>
                    <ul v-else class="document-list">
                      <li>Any messages or emails reporting the lost item(s)</li>
                      <li>Any messages from the courier about the lost item(s)</li>
                    </ul>
                  </span>
                </label>
                <div>
                  <div
                    class="btn btn-secondary-purple input-button mb-3"
                    style="border-radius: .25rem;">
                    Upload file(s)
                    <input
                      type="file"
                      ref="files"
                      class="input-file"
                      id="files"
                      multiple
                      v-on:change="handleFilesUpload()"

                      data-test="claim-files-upload"
                    />
                  </div>
                  <div class="file-list" data-test="claim-files-list">
                    <div
                      v-for="(file, key) in files"
                      v-bind:key="key"
                      class="single-file"
                    >
                      <span class="remove-file" v-on:click="removeFile(key)"
                        ><i class="fas fa-times"></i
                      ></span>
                      {{ file.name }} <span v-if="file.size / 1024 / 1024 > this.file_size_limit" style="color: red" > File too big. File size limit {{this.file_size_limit}}mb</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mistakes.length" class="alert alert-danger mx-auto col-6" role="alert">
              <ul>
                <li v-if="mistakes.includes('tracking_number')">Parcel not selected</li>
                <li v-if="mistakes.includes('items')">Select one or more item(s)</li>
                <li v-if="mistakes.includes('country')">Origin country not selected</li>
                <li v-if="mistakes.includes('files')">Select one or more file(s)</li>
                <li v-if="mistakes.includes('file_size')">File(s) size is too big! File size limit is {{this.file_size_limit}}mb</li>
                <li v-if="mistakes.includes('invalid_quantity')">Invalid quantity on affected items(s) selection</li>

                <!-- <li v-if="mistakes.includes('invalid_quantity')">Invalid quantity on affected items(s) selection</li>
                <li v-if="mistakes.includes('invalid_quantity')">Invalid quantity on affected items(s) selection</li>
                <li v-if="mistakes.includes('invalid_quantity')">Invalid quantity on affected items(s) selection</li> -->
              </ul>
            </div>
            <p class="col-8 mx-auto mt-6 text-center text-gold-anansi">
              Note: you cannot edit your claim once submitted. If you need
              to amend any information, please cancel the existing claim and
              complete a new manual claim.
            </p>
            <div class="btn-group col-12 mt-6 mx-auto">
              <span
                class="btn btn-secondary-purple col-3 mx-auto submit-claim-button"
                :class="{'disabled': !checkForm }"
                style="border-radius: .25rem;"
                @click="checkSubmitModalOpen"
                :disabled="!checkForm"
              >
                Submit Claim
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="modalShow">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <h1>Are you sure?</h1>
            <p>You are about to create a claim for a damaged order or a missing consignment item. Before creating a manual claim, please make sure that the order information isn't already present in Claims.</p>
          </slot>
        </div>
        <div class="modal-buttons">
          <slot name="footer">
            <button class="modal-go-claims" @click="this.$router.push({ path: '/claims/' })">
              Go to claims
            </button>
            <button class="modal-yes-im-sure" @click="closeModal('create')">
              Yes I'm sure
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="submitModalShow">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body text-left">
            <h1>Claim summary</h1>
            <p data-test="manual-claim-summary">
              You are about to create a <strong v-if="claimType === 'DAMAGE'">Damage</strong><strong v-if="claimType === 'LOSS'">Loss</strong> claim relating to order {{selectedOrderNumber}}
              <span v-if="city && region">, shipped from <strong>{{city}}</strong>, <strong>{{region}}</strong>, <strong>{{selectedOriginCountry}}</strong>.</span>
              <span v-else>. Please define the parcel's origin.</span>
            </p>
            <hr>
            <h2 class="underline">Description</h2>
            <p>
              {{ description }}
            </p>
            <div v-if="claimType === 'DAMAGE' || claimType === 'LOSS' ">
              <h2 class="underline">It was reported by</h2>
              <p>
                {{ firstCharCapital(claimReporter) }}
              </p>
            </div>
            <div v-if="claimType === 'DAMAGE'">
              <h2 class="underline">It's reparable?</h2>
              <p>
                {{ damage_reparable == 'NOT_SURE' ? 'Not sure' : firstCharCapital(damage_reparable) }}
              </p>
              <h2 class="underline">Damage notes</h2>
              <p>
                {{ damage_notes }}
              </p>
            </div>
            <hr>
            <p data-test="manual-claim-summary-TN"><span class="underline font-bold">Tracking number:</span>
              <span v-if="claimable[selectedOrderNumber].tracking_number" class="ml-2">{{claimable[selectedOrderNumber].tracking_number}}</span>
              <span v-else> Not available</span>
            </p>
            <p data-test="manual-claim-summary-shipped-date"><span class="underline font-bold">Shipped date:</span>
              <span v-if="claimable[selectedOrderNumber].shipping_date" class="ml-2"> {{claimable[selectedOrderNumber].shipping_date}}</span>
              <span v-else> Not available due an invalid or missing tracking number</span>
            </p>
            <div v-if="areSelectedItems()">
              <h2 class="underline">Item(s) affected:</h2>
              <ul>
                <li v-for="itemId in getSelectedIds()" v-bind:key=itemId data-test="manual-claim-summary-affected-item">
                  {{fulfillmentItems[itemId].selected_quantity}} x {{fulfillmentItems[itemId].name}}
                </li>
              </ul>
            </div>
          </slot>
        </div>
        <div class="modal-buttons">
          <slot name="footer">
            <button class="cancel-claim-creation" @click="closeModal('submit')">
              Cancel
            </button>
            <button
              class="submit-claim-creation"
              style="border-radius: .25rem;"
            >
              <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-if="submitting" class="sr-only">Loading...</span>
              <span v-else @click="submit">Confirm claim creation</span>
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <MainFooter />
</template>

<script>
import '@/assets/css/file_upload.css'
import ClaimTypeSelect from '../components/claims/ClaimTypeSelect.vue'
import ClaimReporterSelect from '../components/claims/ClaimReporterSelect.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import ManualClaimFormService from '@/services/ManualClaimFormService.js'
import GenerateUploadUrlService from '@/services/GenerateUploadUrlService.js'
import ContractDataService from '@/services/ContractDataService.js'
import { COVERAGE_TYPES } from '@/utils/variables.js'
import _ from 'lodash'
import ClaimCountrySelector from '../components/claims/ClaimCountrySelector.vue'

const _makeClaimMappingComputedProperty = function (propName) {
  return {
    get () {
      if (!this.claimType) return []
      return this.claimMapping[propName]
    },
    set (newValue) {
      this.claimMapping[propName] = newValue
    }
  }
}

export default {
  name: 'ManualClaimForm',
  title: 'Anansi - New Damage Claim',
  components: {
    ClaimTypeSelect,
    ClaimReporterSelect,
    NavigationBar,
    MainFooter,
    ClaimCountrySelector
  },
  watch: {
    claimType: function () {
      this.deleteFormData('claimType')
    },
    searchClaimableFulfillment: function (val) {
      if (this.selectedOrderNumber !== val) {
        this.deleteFormData()
        this.searchClaimableFulfillment = val
        this.searchForClaimableFulfillment(val)
      }
    }
  },
  computed: {
    is_loss_covered () {
      return this.coverage_types.includes(COVERAGE_TYPES.LOSS)
    },
    is_damage_covered () {
      return this.coverage_types.includes(COVERAGE_TYPES.DAMAGE)
    },
    checkForm () {
      if (
        this.selectedOrderNumber &&
        this.selectedCountry &&
        this.city &&
        this.region &&
        this.description &&
        this.files.length > 0 &&
        this.areSelectedItems() &&
        !this.hasOverTheLimitFile() &&
        !this.hasInvalidItemQuantity() &&
        this.claimReporter
      ) {
        if (this.claimType !== 'DAMAGE') return true
        if (this.claimType === 'DAMAGE' && this.damage_notes && this.damage_reparable) return true
        else return false
      } else {
        return false
      }
    },
    isParcelSelectionDisabled () {
      return !this.claimType
    },
    claimable: _makeClaimMappingComputedProperty('claimable'),
    orderNumbers: _makeClaimMappingComputedProperty('orderNumbers'),
    matchedOrderNumbers: _makeClaimMappingComputedProperty('matchedOrderNumbers')
  },
  data () {
    return {
      searchClaimableFulfillment: undefined,
      data: undefined,
      coverage_types: [COVERAGE_TYPES.LOSS, COVERAGE_TYPES.DAMAGE],
      files: [],
      fulfillmentItems: [],
      isInitial: true,
      orderNumber: undefined,
      selectedOrderNumber: undefined,
      originCountry: undefined,
      selectedOriginCountry: undefined,
      valid: false,
      city: undefined,
      region: undefined,
      description: undefined,
      submitting: false,
      mistakes: [],
      claimType: '',
      claimReporter: undefined,
      claimMapping: {
        claimable: [],
        orderNumbers: [],
        matchedOrderNumbers: undefined
      },
      isLoading: false,
      file_size_limit: parseInt(process.env.VUE_APP_CLAIM_FILE_SIZE_LIMIT_MB),
      orderNumberDropdownVisibility: false,
      modalShow: true,
      submitModalShow: false,
      reparable: undefined,
      damage_notes: undefined,
      eligibleCountries: [],
      selectedCountry: undefined,
      fulfillmentOriginCountry: undefined
    }
  },
  beforeMount () {
    ContractDataService.getContractDetails().then(response => {
      if (response.data) this.coverage_types = response.data.coverage_types
    })
  },
  methods: {
    async searchForClaimableFulfillment (str) {
      if (str === '') {
        this.resetClaimMappingData()
        this.orderNumberDropdownVisibility = false
        return
      }
      let claimableFulfillments = {}
      this.isLoading = true
      this.showOrderNumberDropdownVisibility()
      claimableFulfillments = await ManualClaimFormService.getFulfillmentsData(str, this.claimType)
      this.isLoading = false
      if (_.isEmpty(claimableFulfillments.data.claimable)) {
        this.resetClaimMappingData()
        return
      }
      this.claimMapping.claimable = claimableFulfillments.data.claimable
      this.claimMapping.orderNumbers = Object.keys(this.claimMapping.claimable)
      this.claimMapping.orderNumbers.sort()
      this.claimMapping.matchedOrderNumbers = this.claimMapping.orderNumbers
      this.eligibleCountries = claimableFulfillments.data.countries
      this.eligibleCountries.sort()
    },
    resetClaimMappingData () {
      this.claimMapping = {
        claimable: [],
        orderNumbers: [],
        matchedOrderNumbers: undefined
      }
    },
    showOrderNumberDropdownVisibility () {
      if (this.matchedOrderNumbers || this.isLoading) {
        this.orderNumberDropdownVisibility = true
      }
    },
    async uploadFile (url, data) {
      let i = 0
      while (i < 5) {
        const res = await fetch(url, {
          method: 'POST',
          body: data
        })

        if (res.status === 204) {
          return true
        } else {
          i++
        }
      }

      return false
    },
    async submitFilesToS3 () {
      const fulfillmentId = this.claimable[this.selectedOrderNumber].fulfillment_id
      const fileS3Names = []
      const fileInfo = this.getFileInfo()
      return await GenerateUploadUrlService.generateUploadUrl(fulfillmentId, fileInfo).then(async (response) => {
        const filenames = []
        for (let i = 0; i < fileInfo.length; i++) {
          filenames.push(fileInfo[i].filename)
        }

        await Promise.all(filenames.map(async (file) => {
          const fileData = response.data.filter(obj => {
            return obj.filename === file
          })[0]
          const fields = fileData.fields
          const s3Data = new FormData()
          Object.keys(fields).forEach(key => {
            s3Data.append(key, fields[key])
          })

          const index = file.split('_')[0]
          s3Data.append('file', this.files[index])

          const url = fileData.url

          if (await this.uploadFile(url, s3Data)) {
            fileS3Names.push({ filename: file.split('_')[1], object_key: fields.key })
          }
        }))

        if (fileS3Names.length !== filenames.length) {
          return []
        } else {
          return fileS3Names
        }
      }).catch(e => {
        console.log(e)
        return false
      })
    },
    selectOrderNumber (orderNumber) {
      this.fulfillmentItems = {}

      for (let i = 0; i < this.orderNumbers.length; i++) {
        if (orderNumber.toUpperCase() === this.orderNumbers[i].toUpperCase()) {
          orderNumber = this.orderNumbers[i]
        }
      }

      if (this.claimable[orderNumber]) {
        this.selectedOrderNumber = orderNumber
        for (const itemId in this.claimable[orderNumber].fulfillment_items) {
          this.fulfillmentItems[itemId] = this.claimable[orderNumber].fulfillment_items[itemId]
          this.fulfillmentItems[itemId].checked = false
          this.fulfillmentItems[itemId].selected_quantity = 1
        }

        if (this.eligibleCountries.includes(this.claimable[orderNumber].origin_country)) {
          this.selectedCountry = this.claimable[orderNumber].origin_country
        }
      }

      this.searchClaimableFulfillment = this.selectedOrderNumber
      this.fulfillmentOriginCountry = this.claimable[orderNumber].origin_country
    },
    getFileInfo () {
      const fileInfo = []
      let name = ''
      let type = ''
      for (let i = 0; i < this.files.length; i++) {
        name = i + '_' + this.files[i].name
        type = this.files[i].type
        fileInfo.push({ filename: name, mime_type: type })
      }

      return fileInfo
    },
    getLoadedFileNames () {
      const filenames = []
      let name = ''
      for (let i = 0; i < this.files.length; i++) {
        name = i + '_' + this.files[i].name
        filenames.push(name)
      }
      return filenames
    },
    handleFilesUpload () {
      const uploadedFiles = this.$refs.files.files
      for (let i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    },
    areSelectedItems () {
      for (const id in this.fulfillmentItems) {
        if (this.fulfillmentItems[id].checked) {
          return true
        }
      }
      return false
    },
    hasOverTheLimitFile () {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].size / 1024 / 1024 > this.file_size_limit) {
          return true
        }
      }
      return false
    },
    hasInvalidItemQuantity () {
      for (const id in this.fulfillmentItems) {
        if (this.fulfillmentItems[id].checked) {
          if (this.fulfillmentItems[id].selected_quantity === 0 || this.fulfillmentItems[id].selected_quantity > this.fulfillmentItems[id].quantity) {
            return true
          }
        }
      }
      return false
    },
    getSelectedIds () {
      const ids = []
      for (const id in this.fulfillmentItems) {
        if (this.fulfillmentItems[id].checked) {
          ids.push(id)
        }
      }
      return ids
    },
    updateSelectedQuantity (id) {
      if (this.fulfillmentItems[id].selected_quantity > this.fulfillmentItems[id].quantity) {
        this.fulfillmentItems[id].selected_quantity = this.fulfillmentItems[id].quantity
      } else if (this.fulfillmentItems[id].selected_quantity < 1) {
        this.fulfillmentItems[id].selected_quantity = 1
      }
    },
    reportMistakes () {
      const mistakes = []
      if (!this.selectedCountry) {
        mistakes.push('country')
      }
      if (this.files.length === 0) {
        mistakes.push('files')
      }
      if (!this.areSelectedItems()) {
        mistakes.push('items')
      }
      if (this.hasOverTheLimitFile()) {
        mistakes.push('file_size')
      }
      if (this.hasInvalidItemQuantity()) {
        mistakes.push('invalid_quantity')
      }
      this.mistakes = mistakes
    },
    async submit () {
      this.mistakes = []
      if (!this.checkForm) {
        this.reportMistakes()
      } else if (!this.submitting) {
        this.submitting = true
        const fileS3Names = await this.submitFilesToS3()
        if (fileS3Names.length > 0) {
          const attemptItems = []
          for (const id in this.fulfillmentItems) {
            if (this.fulfillmentItems[id].checked) {
              const item = {
                item_id: id,
                quantity: Number(this.fulfillmentItems[id].selected_quantity)
              }
              attemptItems.push(item)
            }
          }

          const obj = {
            fulfillment_id: this.claimable[this.selectedOrderNumber].fulfillment_id,
            fulfillment_origin_region: this.region,
            fulfillment_origin_city: this.city,
            fulfillment_origin_country: this.selectedCountry,
            description: this.description,
            attempt_items: attemptItems,
            media: fileS3Names,
            reporter: this.claimReporter
          }

          obj.damage_reparable = this.damage_reparable
          obj.damage_notes = this.damage_notes
          obj.claim_type = this.claimType

          return ManualClaimFormService.sendData(obj).then(response => {
            this.submitting = false
            alert('Form submitted!')
            this.$router.push({ path: '/claims/' })
          }).catch(e => {
            this.submitting = false
            console.log(e)
            alert('Something went wrong...')
            this.$router.push({ path: '/claims/' })
          })
        } else {
          this.submitting = false
          alert('Something went wrong...')
          console.log('Error uploading files')
        }
      }
    },
    deleteFormData (code = '') {
      this.selectedOrderNumber = undefined
      this.region = ''
      this.city = ''
      this.description = ''
      this.files = []
      this.selectedCountry = undefined
      this.fulfillmentOriginCountry = undefined
      this.eligibleCountries = []

      if (code === 'claimType') {
        this.resetClaimMappingData()
        this.searchClaimableFulfillment = ''
      }
    },
    closeModal (modalName) {
      modalName === 'create' ? this.modalShow = false : this.submitModalShow = false
    },
    firstCharCapital (str) {
      const lowerString = str.toLowerCase()
      const firstCapital = lowerString.charAt(0).toUpperCase()
      return firstCapital + lowerString.slice(1)
    },
    checkSubmitModalOpen () {
      if (this.checkForm) this.submitModalShow = true
    }
  }
}
</script>

<style>
label {
  font-weight: 600;
}
.title-and-select-wrapper {
  display: flex;
}
.notice-banner {
  background-color: skyblue;
  color: white;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  text-align: center;
}

.modal-body {
  margin: 20px 0 0;
  text-align: left !important;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.modal-go-claims, .modal-yes-im-sure, .submit-claim-creation, .cancel-claim-creation {
  padding: 4px 8px;
  text-align: center;
  border: 1px solid white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.33);
  border-radius: .25rem;
  color: white;
}

.modal-go-claims {
  background: #7CA0E8;
}

.cancel-claim-creation {
  background: #E87C7C;
}

.modal-yes-im-sure, .submit-claim-creation {
  background: #89E59D;
  margin-left: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@keyframes beforeselection {
  0% {background: #ddd; border: 1px solid #fafafa;}
  50% {background-color: white; border: 1px solid black;}
  75% {background: #ddd; border: 1px solid #fafafa;}
  100% {background: #ddd; border: 1px solid #fafafa;}
}

.animated-before-selection {
  animation: beforeselection 3s infinite;
}

.reporter-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px !important;
}

.reporter-select select {
  margin-left: 0 !important;
}

.reparable-radio-wrapper {
  display: flex;
  align-items: center;
}

.reparable-radio-wrapper div:not(:first-child) {
  margin-left: 10px;
}

.reparable-radio-wrapper div input {
  margin-right: 4px;
}

.document-list li {
  font-weight: normal;
}

button:disabled {
  cursor: not-allowed;
}

.modal-body h2 {
  font-size: 18px;
  margin-bottom: 0 !important;
}

.underline {
  text-decoration: underline;
}

.font-bold {
  font-weight: bold;
}

.submit-claim-button.disabled {
  cursor: not-allowed;
  background: gray;
  color: white;
}

.submit-claim-button.disabled:hover {
  background: gray !important;
  color: white;
}
</style>
