<script setup>
import { usePolicyholderStore } from '@/stores/policyholder'
import { useOnboardingStore } from '@/stores/onboarding'
import { MIN_MAX_VALUE_INSURANCE_RANGE, MAX_VALUE_INSURANCE_RANGE } from '@/utils/variables'

const policyholderStore = usePolicyholderStore()
const onboardingStore = useOnboardingStore()

const formatInputNumber = async (event, min = false) => {
  onboardingStore.switchValidation('insurance_values_in_range', true)

  if (min && event.target.value > MIN_MAX_VALUE_INSURANCE_RANGE) {
    event.target.value = MIN_MAX_VALUE_INSURANCE_RANGE
  } else if (!min && event.target.value > MAX_VALUE_INSURANCE_RANGE) {
    event.target.value = MAX_VALUE_INSURANCE_RANGE
  }

  if ((min && event.target.value > policyholderStore.max_insurance_value) ||
  (!min && event.target.value < policyholderStore.min_insurance_value)) onboardingStore.switchValidation('insurance_values_in_range', false)

  if (min) policyholderStore.changeInsuranceLimits(Number(event.target.value), policyholderStore.max_insurance_value)
  else policyholderStore.changeInsuranceLimits(policyholderStore.min_insurance_value, Number(event.target.value))
}

</script>
<template>
  <div class="tw-flex tw-flex-col">
    <h4 class="tw-font-bold">Your preferred Insurance</h4>
    <p class="mb-0">
      With Anansi, you select the value range for parcels you want to insure.
    </p>
    <p class="mt-0">
      Any parcel that ships within the values you select will be covered. You have control - change the range at any
      time within the app.
    </p>

    <div class="mt-7 tw-grid tw-grid-cols-2 tw-gap-4">
      <div>
        <h4 class="tw-font-bold mb-0">Choose your minimum</h4>
        <h4 class="tw-font-bold mt-1">From £0 to £50</h4>

        <span class="tw-mr-1 tw-font-bold">£</span>
        <input class="tw-mr-3 tw-w-1/2 tw-border tw-rounded tw-px-1.5 tw-py-1 tw-drop-shadow-lg"
          :class="onboardingStore.currentStep.showError ? 'tw-border-red-500 tw-border-2' : ''" type="number" min="0"
          :value="policyholderStore.min_insurance_value" oninput="validity.valid||(value='');"
          @input="(event) => formatInputNumber(event, true) ">
      </div>
      <div>
        <h4 class="tw-font-bold mb-0">Choose your maximum</h4>
        <h4 class="tw-font-bold mt-1">From £0 to £25,000</h4>

        <span class="tw-mr-1 tw-font-bold">£</span>
        <input class="tw-border tw-rounded tw-w-1/2 tw-px-1.5 tw-py-1 tw-drop-shadow-lg remove-arrow"
          :class="onboardingStore.currentStep.showError ? 'tw-border-red-500 tw-border-2' : ''" type="number" min="0"
          :value="policyholderStore.max_insurance_value" oninput="validity.valid||(value='');"
          @input="formatInputNumber">
      </div>
    </div>
    <div v-if="onboardingStore.currentStep.showError">
      <p class="tw-mt-2 tw-text-red-500 tw-text-xs tw-font-bold tw-ml-4">Minimum value cannot be greater than maximum</p>
    </div>
  </div>
</template>
