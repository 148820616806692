<script setup>
import OnboardingStep1CanConfigureInsurance from '@/components/onboarding/steps/OnboardingStep1CanConfigureInsurance.vue'
import OnboardingStep1CannotConfigureInsurance from '@/components/onboarding/steps/OnboardingStep1CannotConfigureInsurance.vue'
import { usePolicyholderStore } from '@/stores/policyholder'
import { COVERAGE_TYPES } from '@/utils/variables'

const policyholderStore = usePolicyholderStore()

const includeLossCoverage = policyholderStore.contract.coverage_types.includes(COVERAGE_TYPES.LOSS)
</script>
<template>
  <div>
    <h4 class="tw-font-bold">Details</h4>
    <p class="mb-0">
      UK to UK and UK to international deliveries:
    </p>
    <ul class="ml-3">
      <li>Cover for {{ policyholderStore.contract.sanitized_coverage_types }}</li>
      <li>24/7 shipment tracking</li>
      <li v-if="includeLossCoverage">Automated Loss Claims</li>
    </ul>
    <h4 class="tw-font-bold">Our Pricing</h4>
    <ul class="ml-3">
      <li>Premium payments: Monthly invoices or direct debit</li>
      <li>UK to UK: {{ policyholderStore.contract.sanitized_domestic_premium}}% of parcel retail value + Insurance
        Premium Tax (IPT)</li>
      <li>UK to international: {{ policyholderStore.contract.sanitized_international_premium}}% of parcel retail value</li>
    </ul>
    <OnboardingStep1CanConfigureInsurance v-if="policyholderStore.can_configure_insurance" />
    <OnboardingStep1CannotConfigureInsurance v-else />
  </div>
</template>

<style>
ul {
  list-style-type: disc;
  list-style-position: inside;
}
</style>
