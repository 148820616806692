<template>
  <NavigationBar v-bind="{ claims: true }" />
  <ClaimsPaymentModal :key="showModalKey" v-if="claimsBankAccountsModal" />
  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col">
      <div class="">
        <div class="col-12 card shadow-light p-5 table-style">
          <div v-if="showErrorMessage && $route.query.errorMessage" class="error-box alert-danger">
            <div class="error-box-msg">{{errorMessageMap[$route.query.errorMessage]}}</div>
            <div class="close" @click="showErrorMessage = false">
              <span>&times;</span>
            </div>
          </div>
          <div class="col-12 mb-5">
            <h1 class="d-inline align-middle mr-6">Claims</h1>
          </div>
          <div class="col-12 mb-5">
            <p class="mb-0">Claims are created automatically when an individual shipment, or consignment, is lost.</p>
            <p class="mb-0">
              However, if an order arrives damaged or an item is missing from a consignment,
              you can always <router-link :to="{ name: 'ManualClaimForm' }" data-test="create-manual-claim-button">create a manual claim;</router-link>
            </p>
          </div>
          <div class="input-group input-group-lg" style="margin-bottom: 24px">
            <div class="dropdown">
              <button
                class="
                  btn btn-outline btn-options
                  dropdown-toggle
                  border-btn-options
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-sort text-purple-anansi"></i> Sort By:
                {{ searchParameters.descriptionOrderByText }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <span class="dropdown-item text-muted">SORT BY:</span>
                <a
                  @click="orderBy('declaration_date', undefined)"
                  class="dropdown-item"
                  :class="
                    searchParameters.order_by === 'declaration_date'
                      ? 'active'
                      : ''
                  "
                  >Policy Issue Date</a
                >
                <a
                  @click="orderBy('amount', undefined)"
                  class="dropdown-item"
                  :class="
                    searchParameters.order_by === 'amount' ? 'active' : ''
                  "
                  >Amount</a
                >
                <a
                  @click="orderBy('type', undefined)"
                  class="dropdown-item"
                  :class="searchParameters.order_by === 'type' ? 'active' : ''"
                  >Type</a
                >
                <a
                  @click="orderBy('status', undefined)"
                  class="dropdown-item"
                  :class="
                    searchParameters.order_by === 'status' ? 'active' : ''
                  "
                  >Status</a
                >

                <hr />
                <span class="dropdown-item text-muted">ORDER:</span>
                <a
                  @click="orderBy(undefined, 'asc')"
                  class="dropdown-item"
                  :class="searchParameters.order === 'asc' ? 'active' : ''"
                  >Ascending</a
                >
                <a
                  @click="orderBy(undefined, 'desc')"
                  class="dropdown-item"
                  :class="searchParameters.order === 'desc' ? 'active' : ''"
                  >Descending</a
                >
              </div>
            </div>

            <!--
            <button
              @click="filters = !filters"
              class="
                btn btn-outline btn-options
                dropdown-toggle
                border-btn-options
                ml-5
              "
              type="button"
            >
              <i class="fa fa-filter text-purple-anansi" aria-hidden="true"></i>
              Filters
            </button>
            <div class="input-group-prepend border ml-5">
              <span class="input-group-text border-0 pr-1">
                <i class="fas fa-search text-purple-anansi"></i>
              </span>
              <input
                v-model="searchParameters.search_text"
                type="text"
                style="min-width: 360px"
                class="form-control border-0 px-1"
                aria-label="Search your orders by any keyword"
                placeholder="Search by date, amount, type or status"
              />
            </div>
            <button
              @click="searchBox"
              class="navbar-btn btn btn-sm btn-secondary ml-2"
            >
              Search
            </button>
            -->
          </div>

          <!--
          <template v-if="filters">
            <div class="row mx-0 text-left align-items-left">
              <div class="col-md-12 text-center">
                <hr class="w-100 mt-5 mb-5" />
              </div>
            </div>
            <div class="row mx-0 text-left align-items-left">
              <div class="col-md-5">
                <span class="h4">Claims creation date</span>
                <hr />
              </div>
              <div class="col-md-4">
                <span class="h4">Amount between ranges</span>
                <hr />
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-md-2">
                Claims created after:
                <datepicker
                  v-model="searchParameters.created_at_after"
                  :upperLimit="searchParameters.created_at_before"
                  :clearable="true"
                />
              </div>
              <div class="col-md-3">
                Claims created before:
                <Datepicker
                  v-model="searchParameters.created_at_before"
                  :lowerLimit="searchParameters.created_at_after"
                  :clearable="true"
                />
              </div>
              <div class="col-md-2">
                Amount above:
                <input
                  type="number"
                  v-model="searchParameters.amount_above_inclusive"
                />
              </div>
              <div class="col-md-2">
                Amount below:
                <input
                  type="number"
                  v-model="searchParameters.amount_below_inclusive"
                />
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-md-12 text-center">
                <hr class="w-100 mt-5 mb-5" />
              </div>
              <div class="col-md">
                Claim Type:
                <form class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="DAMAGE"
                      v-model="searchParameters.type"
                      true-value="DAMAGE"
                      false-value=""
                    />
                    <label
                      class="custom-control-label align-middle"
                      for="DAMAGE"
                      >Damage</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="LOSS"
                      v-model="searchParameters.type"
                      true-value="LOSS"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="LOSS"
                      >Loss</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="DELAY"
                      v-model="searchParameters.type"
                      true-value="DELAY"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="DELAY"
                      >Delay</label
                    >
                  </div>
                </form>
              </div>
              <div class="col-md">
                Claim status:
                <form class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="OPEN"
                      v-model="searchParameters.status"
                      true-value="1"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="OPEN"
                      >Open</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="PAID"
                      v-model="searchParameters.status"
                      true-value="2"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="PAID"
                      >Paid</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="PAYMENT_PENDING"
                      v-model="searchParameters.status"
                      true-value="3"
                      false-value=""
                    />
                    <label
                      class="custom-control-label align-middle"
                      for="inTransit"
                      >Payment Pending</label
                    >
                  </div>
                </form>
              </div>
              <div class="col-md">
                Claim Attempt status:
                <form class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="PENDING"
                      v-model="searchParameters.attempt_status"
                      true-value="PENDING"
                      false-value=""
                    />
                    <label
                      class="custom-control-label align-middle"
                      for="PENDING"
                      >Pending</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="APPROVED"
                      v-model="searchParameters.attempt_status"
                      true-value="APPROVED"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="PAID"
                      >Approved</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="DENIED"
                      v-model="searchParameters.attempt_status"
                      true-value="DENIED"
                      false-value=""
                    />
                    <label
                      class="custom-control-label align-middle"
                      for="inTransit"
                      >Denied</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="RESUBMIT"
                      v-model="searchParameters.attempt_status"
                      true-value="RESUBMIT"
                      false-value=""
                    />
                    <label
                      class="custom-control-label align-middle"
                      for="RESUBMIT"
                      >Resubmit</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="ERROR"
                      v-model="searchParameters.attempt_status"
                      true-value="ERROR"
                      false-value=""
                    />
                    <label class="custom-control-label align-middle" for="ERROR"
                      >Error</label
                    >
                  </div>
                </form>
              </div>

              <div class="col-md-2">
                <button
                  @click="updateTable"
                  class="navbar-btn btn btn-sm btn-secondary ml-2"
                >
                  Apply Filters
                </button>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <hr class="w-100 mt-2" />
            </div>
          </template>
          -->

          <!-- TABLE -->
          <div class="bootstrap-table bootstrap4">
            <div class="fixed-table-toolbar">
              <div class="bs-bars float-left"></div>
            </div>

            <div class="fixed-table-container" style="padding-bottom: 0px">
              <div class="fixed-table-header" style="display: none">
                <table></table>
              </div>
              <div class="fixed-table-body" data-test="claims-table">
                <table
                  data-toggle="table"
                  data-toolbar="#toolbar"
                  class="
                    table
                    b-table
                    table-striped
                    b-table-stacked-md
                    font-size-sm
                    table-bordered
                  "
                  data-pagination="true"
                  data-pagination-h-align="left"
                  data-filter-control="true"
                  data-filter-data="var:selectTypes"
                >
                  <thead class="thead-light">
                    <tr>
                      <th class="text-center" style="" data-field="4">
                        <div class="th-inner sortable both">Order Number</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="0">
                        <div class="th-inner sortable both">Policy Ref.</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="1">
                        <div class="th-inner sortable both">
                          Policy Issue Date
                        </div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="2">
                        <div class="th-inner sortable both">Claim Amount</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="3">
                        <div class="th-inner sortable both">Type</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="5">
                        <div class="th-inner sortable both">
                          Tracking Number
                        </div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="6">
                        <div class="th-inner sortable both">Status</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="7">
                        <div class="th-inner sortable both">Details</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="claims && claims.length">
                    <tr v-for="(claim, index) in claims" v-bind:key="index" data-test="claim-row">
                      <td style="">
                        <a
                          v-if="claim.url_to_order"
                          v-bind:href=claim.url_to_order
                          rel="noopener"
                          target="_blank"
                          class=""
                        >
                          #{{claim.order_number}}
                        </a>
                        <p v-else>#{{claim.order_number}}</p>
                      </td>
                      <td style="">{{ claim.contract_number }}</td>
                      <td style="">{{ claim.declaration_date }}</td>
                      <td v-if="claim.amount" style="">£{{ claim.amount }}</td>
                      <td v-else style="">Pending</td>
                      <td class="text-center" style="">{{ claim.type }}</td>
                      <td style="">{{ claim.tracking_number ? claim.tracking_number : "-" }}</td>
                      <td class="text-center align-middle" style="">
                        <ClaimStatusBadges
                          v-bind="{
                          status: claim.status,
                          }"
                        />
                      </td>
                      <td
                        v-if="!claim.is_claim_attempt"
                        class="text-center align-middle"
                      >
                        <router-link
                          :to="{
                            name: 'DetailClaims',
                            params: { id: claim.id },
                          }"
                        >
                          <a class="a-link" v-bind:href="claim.id"
                            >See Details</a
                          >
                        </router-link>
                      </td>
                      <td v-else class="text-center align-middle">
                        <router-link
                          :to="{
                            name: 'DetailDamageClaims',
                            params: { id: claim.id },
                          }"
                        >
                          <a class="a-link" v-bind:href="claim.id"
                            >See Details</a
                          >
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8">
                        <p class="resource-tables-empty-box mt-4 text-center">
                          It's empty for now, but don't worry. Your automated
                          claims, and any manual ones that you create, will soon
                          be listed here.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- PAGINATION -->
              <ul
                role="menubar"
                class="pagination b-pagination pagination-sm"
                :key="searchParameters.page"
              >
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.firstElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.firstElement"
                    role="menuitem"
                    class="page-link"
                    >«</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(pagination.firstPage)"
                  >
                    «
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.previusElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.previusElement"
                    role="menuitem"
                    class="page-link"
                    >‹</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(searchParameters.page - 1)"
                  >
                    ‹
                  </button>
                </li>
                <li
                  v-for="page in pagination.pages"
                  :key="page.page"
                  role="presentation"
                  class="page-item"
                  :class="page.active ? 'active' : ''"
                >
                  <button
                    role="menuitemradio"
                    type="button"
                    tabindex="0"
                    class="page-link"
                    @click="pageSelection(page.page)"
                  >
                    {{ page.page }}
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.nextElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.nextElement"
                    role="menuitem"
                    class="page-link"
                    >›</span
                    >
                    <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(searchParameters.page + 1)"
                    data-test="claim-next-page"
                  >
                    ›
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.lastElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.lastElement"
                    role="menuitem"
                    class="page-link"
                    >»</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(pagination.lastPage)"
                  >
                    »
                  </button>
                </li>
                <li>
                  <div class="d-flex align-items-center h-100 ml-4">
                    <div class="box">Claims per page:</div>
                    <div class="dropdown ml-1">
                      <button
                        class="
                          btn btn-outline btn-options
                          dropdown-toggle
                          border-btn-options
                          padding-btn-options
                        "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ searchParameters.limit }}
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          @click="limitElementsByPage(10)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 10 ? 'active' : ''"
                          >10</a
                        >
                        <a
                          @click="limitElementsByPage(25)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 25 ? 'active' : ''"
                          >25</a
                        >
                        <a
                          @click="limitElementsByPage(50)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 50 ? 'active' : ''"
                          >50</a
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- END-PAGINATION -->
              <button
                v-if="bankAccount == null"
                @click="openClaimsBankAccounts()"
                class="navbar-btn btn btn-sm btn-secondary-purple mt-0 py-0"
                style="height: 40px"
                data-test="claims-setup-bank-account-button"
              >
                Set up a bank account for claim payments
              </button>
              <button
                v-else
                @click="openClaimsBankAccounts()"
                class="navbar-btn btn btn-sm btn-secondary-purple mt-0 py-0"
                style="height: 40px"
              >
                Bank account details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import ClaimsDataService from '@/services/ClaimsDataService.js'
import ClaimsPaymentDataService from '@/services/ClaimsPaymentDataService.js'
import ClaimsPaymentModal from '@/components/claims/ClaimsPaymentModal.vue'
import paginationMixin from '@/mixins/paginationMixin.js'
import ClaimStatusBadges from '@/components/claims/ClaimStatusBadges.vue'

// import Datepicker from 'vue3-datepicker'

export default {
  name: 'ClaimsView',
  title: 'Anansi - Claims',
  mixins: [paginationMixin],
  components: {
    NavigationBar,
    MainFooter,
    ClaimsPaymentModal,
    ClaimStatusBadges
    // Datepicker
  },
  // Status: Possible values: OPEN, READY_FOR_PAYMENT, PAYMENT_PENDING, CLOSED_PAID, CLOSED_UNPAID
  data () {
    return {
      claims: undefined,
      claimsBankAccountsModal: false,
      showModalKey: 1,
      bankAccount: null,
      filters: false,
      showErrorMessage: true,
      errorMessageMap: {
        noEligibleFulfillmentForClaim: 'You do not currently have any parcels that are eligible for a claim.'
      }
    }
  },
  beforeMount () {
    const getClaims = ClaimsDataService.getClaims(
      this.searchParameters.page,
      this.searchParameters.limit
    )
      .then((response) => {
        this.claims = response.data.claims
        this.searchParameters.page = response.data.page
        this.searchParameters.limit = response.data.limit
        this.searchParameters.total_items = response.data.total_items
        this.generatePagination()
      })
    const getBankAccount = ClaimsPaymentDataService.getBankAccounts()
      .then((response) => {
        if (response.data != null && response.data.items.length > 0) {
          this.bankAccount = response.data.items[0]
        } else {
          this.bankAccount = null
        }
      })
    return getClaims && getBankAccount
  },
  methods: {
    updateTable () {
      ClaimsDataService.getClaims(
        this.searchParameters.page,
        this.searchParameters.limit
      )
        .then((response) => {
          this.claims = response.data.claims
          this.showDamage = process.env.VUE_APP_DAMAGE
          this.searchParameters.page = response.data.page
          this.searchParameters.limit = response.data.limit
          this.searchParameters.total_items = response.data.total_items
          this.generatePagination()
        })
    },
    openClaimsBankAccounts () {
      this.showModalKey += 1
      this.claimsBankAccountsModal = true
    },
    orderBy (
      orderBy = this.searchParameters.order_by,
      order = this.searchParameters.order
    ) {
      this.searchParameters.order =
        order === undefined ? this.searchParameters.order : order
      this.searchParameters.order_by =
        orderBy === undefined ? this.searchParameters.order_by : orderBy

      switch (orderBy) {
        // number
        case 'amount':
          this.claims.sort((a, b) =>
            order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
          )
          break

        // date string
        case 'declaration_date':
          this.claims.sort(function (a, b) {
            a = a[orderBy].toString().replace('-', '')
            b = b[orderBy].toString().replace('-', '')
            return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
          })
          break

        // string
        default:
          this.claims.sort((a, b) =>
            order === 'asc'
              ? a[orderBy].localeCompare(b[orderBy])
              : b[orderBy].localeCompare(a[orderBy])
          )
          break
      }
    }
  }
}
</script>

<style>
.error-box {
  padding: 10px;
  margin-bottom: 12px;
  margin-left: 14px;
  border-radius: 6px;
  display: flex;
}

.error-box-msg {
  flex-grow: 1;
}

</style>
