import { defineStore } from 'pinia'
import { setPersistentCookie } from '@/utils/cookies.js'

export const useMainStore = defineStore('mainStore', {
  state: () => ({
    isAuthenticated: false,
    shop: '',
    token: '',
    user_email: '',
    onBoarding: false,
    tables: {
      limit: 10,
      page: 1
    }
  }),
  actions: {
    /**
     * Initialize the store and set the authentication token from the cookies if it is set
     */
    initializeStore () {
      if (localStorage.getItem('user_email')) {
        this.user_email = localStorage.getItem('user_email')
        this.isAuthenticated = true
      } else {
        this.user_email = ''
        this.isAuthenticated = false
      }
      if (localStorage.getItem('onBoarding')) {
        this.onBoarding = localStorage.getItem('onBoarding')
      }
    },

    setRefreshJwtError (value) {
      this.refresh_jwt_error = value
    },

    /**
     * Store the authentication token in the cookies
     */
    setAuthInfo (data) {
      setPersistentCookie('csrftoken', data.csrftoken)
      // TODO: REMOVE THIS WHEN MOVING TO AWS
      setPersistentCookie('access_token', data.access_token)
      localStorage.setItem('user_email', data.user_email)
      localStorage.setItem('isAuthenticated', true)
      this.user_email = data.user_email
      this.isAuthenticated = true
    },

    /**
     * Set state of onBoarding to true
     * The user completed the onBoarding process and will not be show again
     */
    setCompletedOnboarding () {
      localStorage.setItem('onBoarding', true)
      this.onBoarding = true
    },

    /**
     * Remove state of onBoarding
     */
    removeCompletedOnboarding () {
      localStorage.removeItem('onBoarding')
      this.onBoarding = false
    },

    /**
     * Logout the user by remove token, isAuthenticated, tables, onBoarding from the cookies
     */
    resetLocalStorage () {
      localStorage.removeItem('user_email')
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('tables')
      localStorage.removeItem('onBoarding')
      this.user_email = ''
      this.isAuthenticated = false
      this.onBoarding = false
    }
  }
})
