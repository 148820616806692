/* eslint-disable camelcase */
import { http } from '../http-common'

class FilterByCourierList {
  /*
    FilterByCourierList is in charge of managing exclusion or addition of couriers
  */

  // Search courier given a name
  searchCourier (name = undefined) {
    return http.get(`/couriers/?name=${name}`)
  }

  // Get excluded couriers
  getExcludedCouriers () {
    return http.get('/excluded_couriers/')
  }

  // Add excluded couriers
  addExcludedCouriers (couriers_to_exclude_ids, couriers_to_include_ids) {
    return http.post('/excluded_couriers/',
      { couriers_to_exclude_ids, couriers_to_include_ids })
  }
}

export default new FilterByCourierList()
