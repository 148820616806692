export default {
  data () {
    return {
      searchParameters: {
        page: 1,
        limit: 10,
        total_items: 10,
        order: 'asc',
        order_by: 'declaration_date'
      },
      pagination: {
        pages: [
          {
            page: 1,
            active: true
          }
        ],
        activePage: 1,
        lastPage: 1,
        firstPage: 1,
        firstElement: false,
        previusElement: false,
        nextElement: false,
        lastElement: false,
        previusDots: false,
        nextDots: false
      }
    }
  },
  methods: {
    pageSelection (page) {
      this.searchParameters.page = page
      return this.updateTable()
    },
    limitElementsByPage (limit) {
      this.searchParameters.page = 1
      this.searchParameters.limit = limit
      return this.updateTable()
    },
    generatePagination () {
      this.pagination.firstPage = 1
      this.pagination.lastPage = Math.ceil(
        parseFloat(this.searchParameters.total_items) /
          parseFloat(this.searchParameters.limit)
      )
      if (this.searchParameters.page > 1) {
        this.pagination.firstElement = true
      } else {
        this.pagination.firstElement = false
      }

      if (this.searchParameters.page - 1 >= 1) {
        this.pagination.previusElement = true
      } else {
        this.pagination.previusElement = false
      }

      if (this.searchParameters.page + 1 <= this.pagination.lastPage) {
        this.pagination.nextElement = true
      } else {
        this.pagination.nextElement = false
      }

      if (this.searchParameters.page < this.pagination.lastPage) {
        this.pagination.lastElement = true
      } else {
        this.pagination.lastElement = false
      }

      if (this.searchParameters.page - 2 > 1) {
        this.pagination.previusDots = true
      } else {
        this.pagination.previusDots = false
      }

      if (this.searchParameters.page + 2 < this.pagination.lastPage) {
        this.pagination.nextDots = true
      } else {
        this.pagination.nextDots = false
      }

      this.pagination.pages = []
      for (
        let i = this.searchParameters.page - 1;
        i <= this.searchParameters.page + 1;
        i++
      ) {
        if (i < 1 || i > this.pagination.lastPage) {
          continue
        }
        let activatedAux = false
        if (i === this.searchParameters.page) {
          activatedAux = true
        }
        this.pagination.pages.push({ page: i, active: activatedAux })
      }
    }
  }
}
