<script setup>
import { computed } from 'vue'
import { useOnboardingStore } from '@/stores/onboarding'

import HeaderInsurancePricingDetails from './dynamic-headers/HeaderInsurancePricingDetails.vue'
import HeaderProvideBusinessDetails from './dynamic-headers/HeaderProvideBusinessDetails.vue'
import HeaderReviewPolicy from './dynamic-headers/HeaderReviewPolicy.vue'
import HeaderCompletedOnboarding from './dynamic-headers/HeaderCompletedOnboarding.vue'

const onboardingStore = useOnboardingStore()

const renderedHeader = computed(() => {
  if (onboardingStore.isOnboardingCompleted) return HeaderCompletedOnboarding

  switch (onboardingStore.currentStepIndex) {
    case 1:
      return HeaderInsurancePricingDetails
    case 2:
      return HeaderProvideBusinessDetails
    case 3:
      return HeaderReviewPolicy
  }

  return {}
})
</script>

<template>
  <div class="onboarding-header-wrapper tw-relative">
    <component :is="renderedHeader" class="tw-relative tw-p-2"/>
  </div>
</template>

<style scoped>
.onboarding-header-wrapper::before {
  content: '';
  position: absolute;
  background: #90f;
  width: 3px;
  top: 0;
  bottom: 0;
  left: -10px;
}
</style>
