<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Auth from '@/services/auth.js'
import { setPersistentCookie } from '@/utils/cookies.js'

const router = useRouter()
const route = useRoute()
const partner = ref('')
const email = ref('')
const password = ref('')
const partners = ref([])
const submitting = ref(false)
const apiPartner = ref(false)
const isInitialized = ref(false)

async function submit () {
  submitting.value = true
  setPersistentCookie('user_source', 'email')
  return Auth.signup(email.value, password.value, partner.value.id).then(response => {
    submitting.value = false
    router.push({ path: '/auth/' })
  }).catch(e => {
    if (e.response.status === 403) {
      submitting.value = false
      alert('User already exists, please log in')
    } else if (e.response.status === 400 && e.response.data.message === 'email_not_valid') {
      submitting.value = false
      alert('Email address not valid')
    } else if (e.response.status === 400 && e.response.data.message === 'weak_password') {
      submitting.value = false
      alert('Password must have a length between 8 and 28 characters')
    } else if (e.response.status === 401) {
      submitting.value = false
      router.go()
    } else if (e.response.status === 500) {
      submitting.value = false
      alert('Something went wrong, please try again later')
    }
  })
}

onMounted(async () => {
  const code = route.query.code

  if (code) {
    await Auth.validatePartnerCode(code).then(response => {
      partners.value = response.data
      partner.value = partners.value[0]
      apiPartner.value = true
    }).catch(e => {
      router.push({ name: 'SignUp' })
    })
  } else {
    await Auth.getAvailablePartners().then(response => {
      partners.value = response.data.partners
    }).catch(e => {
      router.push({ name: 'SignUp' })
    })
  }
  isInitialized.value = true
})
</script>

<template>

  <div v-if="!isInitialized">
    <div class="modal-backdrop fade show"></div>
    <div class="modal d-flex justify-content-center align-items-center">
      <div class="spinner-border tw-rounded-full text-secondary" role="status" style="width: 3rem; height: 3rem;">
      <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
  <div v-else class="tw-flex tw-flex-col tw-gap-4 tw-w-80 tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-mb-8 tw-pt-24">
      <img src="@/assets/img/logo/anansi-logo.svg" alt="Anansi Logo" class="tw-w-48" />
    </div>
    <form class="tw-flex tw-flex-col tw-gap-2" id="login-with-shop-form" @submit.prevent="submit">
      <div v-if="apiPartner" class="mb-5">
        <label>Signup for partner <b style="font-size: 20px">{{ partners[0].name }}</b></label>
      </div>
      <div v-else>
        <select v-model="partner" class="form-control required tw-w-full tw-text-gray-500" required  data-test="signup-choose-your-partner-select">
          <option value="" selected disabled>Choose your partner...</option>
          <option v-for="partner in partners" v-bind:key="partner.id" :value="partner">{{partner.name}}</option>
        </select>
      </div>
      <input v-model="email" class="form-control required" required type="email" id="email" name="email" placeholder="Email" data-test="signup-email-input"/>
      <input v-model="password" class="form-control required" required type="password" id="email" name="password" placeholder="Password" data-test="signup-password-input"/>
      <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login w-100 mt-4" type="submit" :disabled="submitting" data-test="signup-page-submit-button">
        <span v-if="submitting" class="spinner-border tw-rounded-full spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="submitting" class="sr-only">Loading...</span>
        <span v-else>Sign Up</span>
      </button>
      <h5 class="tw-text-center tw-text-xs tw-mt-6">If you already have an account, <router-link to="/login" class="login-signup-link">log in here.</router-link></h5>
    </form>
  </div>
</template>
