<template>
  <NavigationBar v-bind="{ invoices: true }" />
  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col">
      <InvoiceOverview/>
      <div class="">
        <div class="col-12 card shadow-light p-5 table-style">
          <h1 data-test="invoices-page-title">Invoices</h1>
          <div class="col-12 mb-5" v-if="directDebitActive === true">
            <p class="mb-0">
                If you ever need to cancel the Direct Debit,
                <button @click="openModal()" class="link-style-button" data-backdrop="false" id="deactivateDirectDebitLink" data-test="invoices-cancel-direct-debit">click here</button>.
              </p>
          </div>
          <!-- TABLE -->
          <div class="bootstrap-table bootstrap4">
            <div class="fixed-table-toolbar">
              <div class="bs-bars float-left"></div>
            </div>

            <div class="fixed-table-container" style="padding-bottom: 0px">
              <div class="fixed-table-header" style="display: none">
                <table></table>
              </div>
              <div class="fixed-table-body">
                <table
                  data-toggle="table"
                  data-toolbar="#toolbar"
                  class="
                    table
                    b-table
                    table-striped
                    b-table-stacked-md
                    font-size-sm
                    table-bordered
                  "
                  data-pagination="true"
                  data-pagination-parts="['pageSize']"
                  data-pagination-h-align="left"
                  data-filter-control="true"
                  data-filter-data="var:selectTypes"
                  data-test="invoices-table"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="" data-field="0">
                        <div class="th-inner sortable both">Invoice ID</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="1">
                        <div class="th-inner sortable both">Premium</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="2">
                        <div class="th-inner sortable both">Period Start</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th style="" data-field="3">
                        <div class="th-inner sortable both">Period End</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="4">
                        <div class="th-inner sortable both">Status</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th
                        v-if="showDownloadPDF"
                        class="text-center"
                        style=""
                        data-field="5"
                      >
                        <div class="th-inner">Download PDF</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                      <th class="text-center" style="" data-field="6">
                        <div class="th-inner">Payment</div>
                        <div class="fht-cell">
                          <div class="no-filter-control"></div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="invoices && invoices.length">
                    <tr
                      v-for="invoice in invoices"
                      v-bind:key="invoice.invoice_id"
                      data-test="invoices-table-row"
                    >
                      <td class="align-middle">
                        <router-link
                          :to="{
                            name: 'DetailInvoices',
                            params: { invoice_id: invoice.invoice_id },
                          }"
                        >
                          <a class="a-link" v-bind:href="invoice.invoice_id"
                            ># {{ invoice.invoice_id }}</a
                          >
                        </router-link>
                      </td>
                      <td class="align-middle">£{{ invoice.subtotal }}</td>
                      <td class="align-middle">{{ invoice.period_start }}</td>
                      <td class="align-middle">{{ invoice.period_end }}</td>
                      <td class="text-center align-middle" style="">
                        <InvoiceStatusBadges
                          v-bind="{
                          status: invoice.status,
                          }"
                        />
                      </td>
                      <td v-if="showDownloadPDF" class="text-center" style="" data-test="invoices-download-pdf-col">
                        <a
                          :href="invoice.invoice_pdf"
                          target="_blank"
                          class="my-0 a-link"
                          data-test="invoices-download-button"
                          >Download</a
                        >
                      </td>
                      <td class="align-middle text-center" style="" data-test="invoices-ready-for-payment-button-col">
                        <button
                          v-if="invoice.status === 'READY_FOR_PAYMENT' && !!invoice.payment_url"
                          @click="openPaymentWindow(invoice.payment_url, 'invoicePaymentPopup', this.updateTable)"
                          class="
                            navbar-btn
                            btn btn-sm btn-secondary-purple
                            mt-0
                            py-0
                          "
                          style="height: 30px"
                        >
                          Pay Invoice
                        </button>
                        <button
                          disabled
                          v-else-if="(invoice.status === 'READY_FOR_PAYMENT' || invoice.status === 'PAYMENT_STARTED' || invoice.status === 'PAYMENT_PENDING')  && !invoice.payment_url && !!invoice.direct_debit_payment_datetime"
                          data-toggle="tooltip"
                          data-placement="right"
                          :title="'Your next invoice is scheduled to be automatically paid via direct debit on ' + invoice.direct_debit_payment_datetime + '.'"
                          class="
                            navbar-btn
                            btn btn-sm btn-secondary-purple
                            mt-0
                            py-0
                          "
                          style="height: 30px"
                        >
                          Pay Invoice
                        </button>
                        <button
                          disabled
                          v-else
                          class="
                            navbar-btn
                            btn btn-sm btn-secondary-purple
                            mt-0
                            py-0
                          "
                          style="height: 30px"
                        >
                          Pay Invoice
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <p class="resource-tables-empty-box mt-4 text-center">
                          Your monthly invoices will be listed here.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot style="display: none">
                    <tr></tr>
                  </tfoot>
                </table>
              </div>
              <div class="fixed-table-footer">
                <table>
                  <thead>
                    <tr></tr>
                  </thead>
                </table>
              </div>
              <!-- PAGINATION -->
              <ul
                role="menubar"
                class="pagination b-pagination pagination-sm"
                :key="searchParameters.page"
              >
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.firstElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.firstElement"
                    role="menuitem"
                    class="page-link"
                    >«</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(pagination.firstPage)"
                  >
                    «
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.previusElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.previusElement"
                    role="menuitem"
                    class="page-link"
                    >‹</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(searchParameters.page - 1)"
                  >
                    ‹
                  </button>
                </li>
                <li
                  v-for="page in pagination.pages"
                  :key="page.page"
                  role="presentation"
                  class="page-item"
                  :class="page.active ? 'active' : ''"
                >
                  <button
                    role="menuitemradio"
                    type="button"
                    tabindex="0"
                    class="page-link"
                    @click="pageSelection(page.page)"
                  >
                    {{ page.page }}
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.nextElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.nextElement"
                    role="menuitem"
                    class="page-link"
                    >›</span
                    >
                    <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(searchParameters.page + 1)"
                    data-test="invoices-next-page"
                  >
                    ›
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="pagination.lastElement ? '' : 'disabled'"
                >
                  <span
                    v-if="!pagination.lastElement"
                    role="menuitem"
                    class="page-link"
                    >»</span
                  >
                  <button
                    v-else
                    role="menuitem"
                    type="button"
                    tabindex="-1"
                    class="page-link"
                    @click="pageSelection(pagination.lastPage)"
                  >
                    »
                  </button>
                </li>
                <li>
                  <div class="d-flex align-items-center h-100 ml-4">
                    <div class="box">Invoices per page:</div>
                    <div class="dropdown ml-1">
                      <button
                        class="
                          btn btn-outline btn-options
                          dropdown-toggle
                          border-btn-options
                          padding-btn-options
                        "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ searchParameters.limit }}
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          @click="limitElementsByPage(10)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 10 ? 'active' : ''"
                          >10</a
                        >
                        <a
                          @click="limitElementsByPage(25)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 25 ? 'active' : ''"
                          >25</a
                        >
                        <a
                          @click="limitElementsByPage(50)"
                          class="dropdown-item"
                          :class="searchParameters.limit === 50 ? 'active' : ''"
                          >50</a
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- END-PAGINATION -->
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-no-backdrop fade" data-backdrop="false" role="dialog" id="confirmDeactivateDirectDebit" aria-hidden="true" ref="confirmDeactivateDirectDebit">
    <div class="modal-dialog modal-dialog-centered no-before" style="max-height: fit-content;" role="document">
      <div class="modal-content">
        <div v-if="!showDeactivateSuccess">
          <div class="modal-header" style="display:flex; justify-content:center; align-items: center; border-bottom: 0">
            <h5 class="modal-title">Cancel your Direct Debit?</h5>
          </div>
          <div class="modal-footer" style="display:flex; justify-content:center; align-items: center; border-top: 0">
            <button v-if="!isProcessing" type="button" class="btn btn-secondary-purple" style="margin-right:64px;" @click="closeModal()" data-test="invoices-cancel-direct-debit-no">No</button>
            <button v-if="!isProcessing" @click="cancelDirectDebit()" type="button" class="btn btn-secondary-red" style="" data-test="invoices-cancel-direct-debit-yes">Yes</button>

            <div v-if="isProcessing" class="spinner-border text-secondary" role="status" style="width: 3rem; height: 3rem;">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </div>

        <div v-if="showDeactivateSuccess" data-test="deactivated-direct-debit-modal">
          <div class="modal-header" style="display:flex; justify-content:center; align-items: center; border-bottom: 0">
            <h5 class="modal-title" >Direct Debit was successfully cancelled</h5>
          </div>
          <div style="margin-top: 8px; padding: 0px 16px; display:flex; justify-content:center; align-items: center; border-bottom: 0">
            <p>You can set it up again upon receipt of your next invoice.</p>
          </div>
          <div class="modal-footer" style="display:flex; justify-content:center; align-items: center; border-top: 0">
            <button type="button" class="btn btn-secondary-purple" data-test="close-deactivated-direct-debit-modal" @click="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" id="backdrop" style="display: none;" @click="closeModal()"></div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import InvoicesDataService from '@/services/InvoicesDataService.js'
import paginationMixin from '@/mixins/paginationMixin.js'
import paymentPopupMixin from '@/mixins/paymentPopupMixin.js'
import InvoiceStatusBadges from '@/components/invoices/InvoiceStatusBadges.vue'
import InvoiceOverview from '@/components/invoices/InvoiceOverview.vue'
import PolicyholderDataService from '@/services/PolicyholderDataService.js'

export default {
  name: 'InvoiceView',
  title: 'Anansi - Invoices',
  mixins: [
    paginationMixin,
    paymentPopupMixin
  ],
  components: {
    NavigationBar,
    MainFooter,
    InvoiceStatusBadges,
    InvoiceOverview
  },
  data () {
    return {
      invoices: undefined,
      last_invoice_id: undefined,
      invoice_id: undefined,
      showDownloadPDF: false,
      directDebitActive: undefined,
      showDeactivateSuccess: false,
      showCancelDirectDebitModal: false,
      isProcessing: false
    }
  },

  async beforeMount () {
    await this.updateTable()
    await this.updateDirectDebit().then(() => {
      if (this.showCancelDirectDebitModal && this.directDebitActive) {
        this.openModal()
      }
    })
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.name === 'CancelDirectDebit') {
        vm.showCancelDirectDebitModal = true
      }
    })
  },

  methods: {
    updateTable () {
      return InvoicesDataService.getInvoices(
        this.searchParameters.page,
        this.searchParameters.limit
      ).then((response) => {
        this.invoices = response.data.items
        this.last_invoice_id = response.data.last_invoice_id
        this.searchParameters.page = response.data.page
        this.searchParameters.limit = response.data.limit
        this.searchParameters.total_items = response.data.total_items
        this.generatePagination()
        for (let i = 0; i < this.invoices.length; i += 1) {
          const currentInvoice = this.invoices[i]
          // Status: Possible values: OPEN, READY_FOR_PAYMENT, PAYMENT_PENDING, CLOSED_PAID, CLOSED_UNPAID
          if (
            currentInvoice.invoice_pdf != null &&
            currentInvoice.invoice_pdf !== undefined
          ) {
            this.showDownloadPDF = true
          }
          currentInvoice.paid = true
          if (
            currentInvoice.status === 'READY_FOR_PAYMENT' ||
            currentInvoice.status === 'CLOSED_UNPAID'
          ) {
            currentInvoice.paid = false
          }
        }
      })
    },

    updateDirectDebit () {
      return PolicyholderDataService.getPolicyholder().then((response) => {
        this.directDebitActive = response.is_direct_debit_active
      })
    },

    cancelDirectDebit () {
      this.isProcessing = true
      PolicyholderDataService.cancelDirectDebit().then((isCancelled) => {
        if (isCancelled === undefined) {
          this.showDeactivateSuccess = false
        } else {
          this.directDebitActive = !isCancelled
          this.showDeactivateSuccess = isCancelled
          this.updateTable()
        }
        this.isProcessing = false
      })
    },

    openModal () {
      document.getElementById('backdrop').style.display = 'block'
      document.getElementById('confirmDeactivateDirectDebit').style.display = 'block'
      document.getElementById('confirmDeactivateDirectDebit').classList.add('show')
    },

    closeModal () {
      if (!this.isProcessing) {
        document.getElementById('backdrop').style.display = 'none'
        document.getElementById('confirmDeactivateDirectDebit').style.display = 'none'
        document.getElementById('confirmDeactivateDirectDebit').classList.remove('show')
      }
    }
  }
}
</script>
