<template>
<!-- CURVE
 ================================================== -->
<div class="position-relative bg-gray-200 mt-10">
    <div class="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<footer class="footer pt-8 w-100 bg-gray-200">
    <!-- CONTACT INFO
================================================== -->

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 text-center">

                    <!-- Heading -->
                    <h2 class=" mb-6 mb-lg-8">
                        Can't find the answer you need?
                    </h2>

                </div>
            </div> <!-- / .row -->
            <div class="row mb-7">
                <div class="col-12 col-md-6 text-center ">

                    <!-- Heading -->
                    <h6 class="text-uppercase text-gold-anansi mb-1">
                        Email us
                    </h6>

                    <!-- Link -->
                    <a href="mailto:hello@withanansi.com" class="h4 text-black .a-link" target="_blank">
                        hello@withanansi.com
                    </a>
                </div>
                <div class="col-12 col-md-6 text-center border-left-md border-gray-300">

                    <!-- Heading -->
                    <h6 class="text-uppercase text-gold-anansi mb-1 ">
                        Message us
                    </h6>

                    <!-- Link -->
                    <div class="mb-5 mb-md-0">
                             <!-- Link -->
                        <a href="https://withanansi.com/contact/" class="h4 text-black a-link" target="_blank">
                           withanansi.com/contact/
                        </a>
                    </div>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->

    <div class="container border-top border-white">
        <div class="row pb-5">
            <div class="col-12 text-muted">
                <p class="text-gray-600 small text-reset text-center mt-5">
                    Anansi Technology Limited (registered in England No. 11420090) is an Appointed Representative of <a target="_blank" href="https://resolutionunderwriting.com/">Resolution Underwriting Partnership Limited</a>,
                    who are authorised and regulated by the Financial Conduct Authority
                    (FRN 308113) in respect of general insurance business and is registered
                    in England No.05104119. The registered office of Resolution Underwriting Partnership Limited is Number One, 1 Vicarage Lane, Stratford, London, E15 4HF.
                    <br>
                    Copyright © 2021 Anansi Technology Limited
                </p>
            </div>

        </div>
    </div>
</footer>
</template>

<script>
export default {
  name: 'MainFooter',
  props: {
  }
}
</script>
