<script setup>
import MainPanelHeader from './MainPanelHeader.vue'
import MainPanelFooter from './MainPanelFooter.vue'
import { useOnboardingStore } from '@/stores/onboarding'

const onboardingStore = useOnboardingStore()

</script>

<template>
  <div class="tw-p-12 tw-flex tw-justify-between tw-flex-col tw-bg-white tw-w-full tw-items-start">
    <MainPanelHeader />
    <component :is="{...onboardingStore.currentStepRender}"/>
    <MainPanelFooter/>
  </div>
</template>
