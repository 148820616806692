/* eslint-disable camelcase */
import { http } from '../http-common'

class DetailClaimService {
  /*
    DetailClaimService is in charge of doing the API request to ISS for the claim detail view
  */

  // Returns invoice details
  getClaimDetail (id) {
    return http.get('/claim_detail/' + id + '/', {
    })
  }
}

export default new DetailClaimService()
