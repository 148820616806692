<template>
  <NavigationBar v-bind="{ claims: true }" />
  <ClaimInfo v-if="ready" v-bind="obj"/>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import DetailClaimService from '@/services/DetailClaimService.js'
import ClaimInfo from '@/components/claims/ClaimInfo.vue'

export default {
  name: 'DetailClaims',
  title: 'Anansi - Claim Details',
  components: {
    NavigationBar,
    MainFooter,
    ClaimInfo
  },
  data () {
    return {
      obj: {},
      ready: false
    }
  },
  beforeMount () {
    return this.loadInvoiceDetail()
  },
  props: ['id'],
  methods: {
    loadInvoiceDetail () {
      return DetailClaimService.getClaimDetail(this.id).then(response => {
        let paymentStatus = ''
        if (response.data.paid) {
          paymentStatus = 'Paid'
        } else {
          paymentStatus = 'Not Paid'
        }

        this.obj = {
          claimId: this.id,
          claimRef: response.data.claim_ref,
          policyRef: response.data.policy_ref,
          shippingTrackingNumber: response.data.tracking_number,
          items: response.data.items,
          dateShipping: response.data.shipped_date,
          dateClaim: response.data.claim_date,
          deliveredDate: response.data.delivered_date,
          expectedDeliveryDate: response.data.expected_delivery_date,
          type: response.data.type,
          status: response.data.status,
          invoiceId: response.data.invoice_id,
          paymentStatus,
          media: [],
          changelog: [],
          orderNumber: response.data.order_number
        }
        this.ready = true
      })
    }
  }
}
</script>
