<script setup>
import { useOnboardingStore } from '@/stores/onboarding'

const onboardingStore = useOnboardingStore()

</script>

<template>
  <div class="tw-col-span-2">
    <component v-if="onboardingStore.currentFooterRender"  :is="{...onboardingStore.currentFooterRender}" />
  </div>
</template>
