<template>
    <span class="chip mr-3 mb-3 primary" style="white-space: nowrap; display: inline-block;">
        {{text}}
        <i @click="$emit('onClose', element)" data-test='chip-remove' class="fa fa-times-circle ml-1" style="cursor: pointer;"/>
    </span>
</template>

<script>

export default {
  name: 'ChipCommon',
  props: {
    element: Object,
    text: String,
    closable: { type: Boolean, default: true }
  }
}
</script>
