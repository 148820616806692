/* eslint-disable camelcase */
import { http } from '../http-common'

class PolicyholderDataService {
  getEmptyPolicyholder () {
    return {
      first_name: undefined,
      last_name: undefined,
      business_id: undefined,
      email: undefined,
      currency: undefined,
      min_insurance_value: undefined,
      max_insurance_value: undefined,
      contract_effective_date: undefined,
      contract_end_date: undefined,
      stripe_customer_id: undefined,
      source_partner: undefined,
      is_partner_merchant: undefined,
      completed_onboarding: undefined,
      company: {
        company_name: undefined,
        full_address: undefined,
        owner: undefined,
        company_status: undefined,
        company_status_detail: undefined,
        company_number: undefined,
        external_registration_number: undefined,
        jurisdiction: undefined,
        type: undefined,
        subtype: undefined,
        has_insolvency_history: undefined,
        date_of_creation: undefined,
        date_of_cessation: undefined,
        address: {
          address_line_1: undefined,
          address_line_2: undefined,
          care_of: undefined,
          country: undefined,
          locality: undefined,
          po_box: undefined,
          postal_code: undefined,
          premises: undefined,
          region: undefined
        }
      },
      hasCompanyData: false, // flag signaling that company data was returned by ISS
      is_direct_debit_active: undefined
    }
  }

  getPolicyholder () {
    return http.get('/policyholder/').then((response) => {
      const policyholder = response.data

      const result = this.getEmptyPolicyholder()
      result.first_name = policyholder.first_name
      result.last_name = policyholder.last_name
      result.business_id = policyholder.business_id
      result.email = policyholder.email
      result.currency = policyholder.currency
      result.min_insurance_value = policyholder.min_insurance_value
      result.max_insurance_value = policyholder.max_insurance_value
      result.contract_effective_date = policyholder.contract_effective_date
      result.contract_end_date = policyholder.contract_end_date
      result.stripe_customer_id = policyholder.stripe_customer_id
      result.source_partner = policyholder.source_partner
      result.policy_reference = policyholder.policy_reference
      result.is_partner_merchant = policyholder.is_partner_merchant
      result.is_direct_debit_active = policyholder.is_direct_debit_active
      result.is_api_user = policyholder.is_api_user
      result.can_configure_insurance = policyholder.can_configure_insurance
      result.completed_onboarding = policyholder.completed_onboarding
      result.contract = policyholder.contract

      if (Object.entries(policyholder.company).length !== 0) {
        const company = policyholder.company
        const companyAddress = company.address
        result.company = {
          company_name: company.company_name,
          full_address: company.full_address,
          owner: company.owner,
          company_status: company.company_status,
          company_status_detail: company.company_status_detail,
          company_number: company.company_number,
          external_registration_number: company.external_registration_number,
          jurisdiction: company.jurisdiction,
          type: company.type,
          subtype: company.subtype,
          has_insolvency_history: company.has_insolvency_history,
          date_of_creation: company.date_of_creation,
          date_of_cessation: company.date_of_cessation,
          address: {
            address_line_1: companyAddress.address_line_1,
            address_line_2: companyAddress.address_line_2,
            care_of: companyAddress.care_of,
            country: companyAddress.country,
            locality: companyAddress.locality,
            po_box: companyAddress.po_box,
            postal_code: companyAddress.postal_code,
            premises: companyAddress.premises,
            region: companyAddress.region
          }
        }
        result.hasCompanyData = true
      }

      return result
    }).catch((error) => {
      if (error.status === 403) {
        return undefined
      }
    })
  }

  cancelDirectDebit () {
    return http.post('/direct_debit/deactivate/').then((response) => {
      return response.status === 200
    }).catch(() => {
      alert('Failed to deactivate direct debit, please try again later')
      return undefined
    })
  }
}

export default new PolicyholderDataService()
