<template>
  <select
    class="select-claim px-0 btn btn-outline btn-options2 dropdown-toggle border-btn-options padding-btn-options pl-4 ml-4"
    style="border-radius: .25rem;"
    :value="modelValue"
    @change="$emit('update:modelValue', $event.target.value)"
    data-test="select-claim-type"
  >
    <option :value="modelValue" disabled selected>Select the claim type <span>⏷</span></option>
    <option v-if="has_loss_claims" value="LOSS">Lost</option>
    <option v-if="has_damage_claims" value="DAMAGE">Damaged</option>
    <option v-if="!has_loss_claims && !has_damage_claims && !has_delay_claims" disabled>No claim types available</option>
  </select>
</template>

<script>

const LOSS = 'LOSS'
const DAMAGE = 'DAMAGE'
const DELAY = 'DELAY'

export default {
  props: {
    has_loss_claims: Boolean,
    has_damage_claims: Boolean,
    has_delay_claims: Boolean,
    modelValue: String
  },
  data () {
    return {
      content: this.value
    }
  },
  mounted () {
    if (this.has_loss_claims && !this.has_damage_claims && !this.has_delay_claims) this.content = LOSS
    if (!this.has_loss_claims && this.has_damage_claims && !this.has_delay_claims) this.content = DAMAGE
    if (!this.has_loss_claims && !this.has_damage_claims && this.has_delay_claims) this.content = DELAY
  }
}

</script>

<style>
select {
  /* Hide the select's arrow for Firefox and Chrome */
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}
</style>
