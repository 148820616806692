<script>
import LoginCard from '@/components/auth/LoginCard.vue'
import FooterAuth from '@/components/auth/Footer.vue'

export default {
  name: 'LogIn',
  components: {
    LoginCard, FooterAuth
  }
}
</script>

<template>
  <section class="tw-h-screen tw-flex-col tw-flex tw-items-center tw-justify-end">
    <LoginCard/>
    <FooterAuth/>
  </section>
</template>
