<script setup>

</script>

<template>
  <div>
    <p class="main-copy mb-0">Welcome aboard! Your account has been <span class="onboarding-highlight-span">successfully
        created.</span></p>
  </div>
</template>
