<script setup>
import {
  SelectValue, SelectContent, SelectTrigger,
  SelectItem, SelectRoot, SelectItemText,
  SelectViewport, SelectPortal
} from 'radix-vue'
import { computed } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    default: null
  },
  defaultOption: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  }
})

const emptyOptionsList = computed(() => {
  return props.options.length === 0
})
</script>

<template>
  <SelectRoot :disabled="emptyOptionsList">
    <SelectTrigger
      :class="[emptyOptionsList ? 'tw-bg-gray-200 tw-text-gray-100' : '', 'tw-inline-flex tw-items-center tw-justify-between tw-rounded tw-px-3.5 tw-text-base tw-leading-none tw-h-12 tw-w-full tw-gap-1 tw-bg-white tw-border tw-text-red']"
    >
      <SelectValue :placeholder="placeholder" class="tw-ml-1"/>
    </SelectTrigger>
    <SelectPortal>
      <SelectContent
        class="tw-bg-white tw-rounded"
        position="popper"
        side="bottom"
      >
        <SelectViewport class="tw-p-1 tw-max-h-[min(var(--radix-select-content-available-height),240px)] tw-w-[--radix-select-trigger-width]
                              tw-border">
            <SelectItem
              v-for="(option, index) in options"
              :key="index"
              class="tw-text-base tw-leading-none tw-rounded tw-flex tw-items-center
                     tw-h-6 tw-pr-9 tw-pl-6 tw-relative tw-select-none"
              :value="option"
            >
              <SelectItemText>
                {{ option }}
              </SelectItemText>
            </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>
