/* eslint-disable camelcase */
import { http } from '../http-common'

class ClaimsDataService {
  /*
    ClaimsDataService is in charge of doing the API request to ISS for the Claims view
  */

  // Returns all claims for a shop.
  getClaims (page = 1, limit = 10) {
    return http.get('/claims/', {
      params: {
        page,
        limit
      }
    })
  }
}

export default new ClaimsDataService()
