<script setup>
import { computed, ref } from 'vue'
import { ComboboxRoot, ComboboxInput, ComboboxEmpty, ComboboxViewport, ComboboxGroup, ComboboxItem, ComboboxContent } from 'radix-vue'
import { usePolicyholderStore } from '@/stores/policyholder'
import _ from 'lodash'

const props = defineProps({
  showError: {
    type: Boolean
  }
})

const policyholderStore = usePolicyholderStore()
const emit = defineEmits(['select-company'])

const searchText = ref('')
const companyList = ref([])

const searchCompany = _.debounce(async (companyName) => {
  try {
    const response = await policyholderStore.searchCompany(companyName)
    companyList.value = response.data.items
  } catch (err) {
    console.error(err)
  }
}, 100)

const selectCompany = (company) => {
  return emit('select-company', company)
}

const showErrorMessage = computed(() => props.showError)

const inputClass = computed(() => {
  let inputClassText = 'tw-w-full tw-py-2 tw-px-3 tw-h-12 tw-border tw-rounded tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline tw-drop-shadow-lg tw-rounded '
  if (showErrorMessage.value) inputClassText += ' tw-border-red-500 tw-border-2'

  return inputClassText
})

const labelClass = computed(() => {
  let labelClassText = 'tw-block tw-text-sm tw-font-bold tw-mb-2 '
  if (showErrorMessage.value) labelClassText += 'tw-text-red-500 '

  return labelClassText
})
</script>
<template>
  <div>
    <label
      :class="labelClass"
      for="business_name_search"
    >
      Search the UK Companies House register for your business
    </label>
    <ComboboxRoot
      v-model="searchText"
      class="tw-relative custom"
      @update:searchTerm="searchCompany"
    >
      <ComboboxInput
        :class="inputClass"
        class="company-input tw-w-full tw-py-2 tw-px-3 tw-h-12 tw-border tw-rounded tw-leading-tight tw-drop-shadow-lg tw-rounded"
        id="business_name_search"
        placeholder="Business Name"
      />
      <ComboboxContent
        class="tw-z-30 tw-w-full tw-absolute tw-mt-1 tw-py-2 tw-min-w-[100px] tw-bg-white tw-max-h-60 tw-h-auto tw-rounded tw-shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
      >
        <ComboboxViewport class="tw-w-full">
          <ComboboxEmpty
            class="tw-text-m tw-font-medium tw-text-center py-2"
          >
            No results found
          </ComboboxEmpty>
          <ComboboxGroup>
            <ComboboxItem
              v-for="company in companyList" :key="company.company_number" :value="company.company_name" @select="() => selectCompany(company)"
              class="tw-py-4 tw-flex tw-items-center tw-h-[25px] tw-pr-[35px] tw-pl-[25px] hover:tw-bg-gray-200 data-[highlighted]:tw-bg-gray-200 tw-duration-300 tw-ease-in-out tw-transition"
            />
          </ComboboxGroup>
        </ComboboxViewport>
      </ComboboxContent>
    </ComboboxRoot>
  </div>
</template>
