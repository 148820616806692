<script setup>
import { usePolicyholderStore } from '@/stores/policyholder'
import { useOnboardingStore } from '@/stores/onboarding'

const policyholderStore = usePolicyholderStore()
const onboardingStore = useOnboardingStore()

const toogleCheck = (field, event) => {
  const value = event.target.checked
  if (typeof (onboardingStore.currentStep.validations[field]) === 'boolean') onboardingStore.switchValidation(field, (!!value))
  policyholderStore.updateField(field, value)
}
</script>

<template>
  <div class="tw-flex tw-flex-col" >
    <div class="tw-flex tw-items-center">
      <input class="tw-cursor-pointer tw-accent-anansi-purple" type="checkbox" id="uk-company" :checked="policyholderStore.uk_registered_company"
        @click="(val) => toogleCheck('uk_registered_company', val)">
      <label class="tw-m-0 tw-ml-1 tw-text-xs"
        :class="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['uk_registered_company'] ? 'tw-text-red-500 tw-font-bold' : 'tw-font-normal'"
        for="uk-company">The policyholder is a UK registered company</label>
    </div>
    <div class="tw-flex tw-items-center tw-mt-1">
      <input class="tw-cursor-pointer tw-accent-anansi-purple" type="checkbox" id="valid-information" :checked="policyholderStore.is_valid_information"
        @click="(val) => toogleCheck('is_valid_information', val)">
      <label class="tw-m-0 tw-ml-1  tw-text-xs"
        :class="onboardingStore.currentStep.showError && !onboardingStore.currentStep.validations['is_valid_information'] ? 'tw-text-red-500 tw-font-bold' : 'tw-font-normal'"
        for="valid-information">I confirm that our business has provided valid information</label>
    </div>
  </div>
</template>
