<script>
export default {
  name: 'FooterAuth',
  props: {
  }
}
</script>

<template>
  <footer class="tw-w-full">
    <hr>
    <section class="tw-max-w-4xl tw-mx-auto">
      <p class="tw-text-gray-300 tw-text-center small-font">
      Anansi Technology Limited (registered in England No. 11420090) is an Appointed Representative of <a target="_blank" href="https://resolutionunderwriting.com/">Resolution Underwriting Partnership Limited</a>,
      who are authorised and regulated by the Financial Conduct Authority
      (FRN 308113) in respect of general insurance business and is registered
      in England No.05104119. The registered office of Resolution Underwriting Partnership Limited is Number One, 1 Vicarage Lane, Stratford, London, E15 4HF.
      Copyright © 2021 Anansi Technology Limited
    </p>
    </section>
  </footer>
</template>

<style scoped>
.small-font {
  font-size: 9px;
}
</style>
